

import { 
  get_high_low,
} from "./DisplayItems";

import {

  // getHours,
  // getMinutes,
  format as DTformat
} from "date-fns";


import {

  datetimeFormats,
  offOnOptions,
  DisableEnableOptions,
  spaModeStates,
  pump_states_t,
  solar_states_t,
  pump_triggers_t,
  heater_display_state_t,
  solar_type_t,
  thermistor_t,
  appliance_status_t,
  spaSizeOptions, 
  poolSizeOptions,
  deviceOptionsSelect,
  spaBlowerTimeOutOptions,
  // dashTabItems,
} from "../constants";

import {
  // readAllMessage,
  // registersInit,
  regType,
  REGS,

} from "../registers";

export const padDigits = (value, padding) => {
  return value.toString().padStart(padding, '0');
}

export const temperatureValueToString = (value, unit) => {
  return (value / 2.0).toFixed(1).concat(" ", unit).trim();
}

export const formatSensorOffset = (value, unit) => {
  return temperatureValueToString(value - 15, unit);
}
export const formatTemperature = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = temperatureValueToString(value, unit)
  }
  return str;
}


export const formatPH = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = (value / 10).toFixed(1).trim();
  }
  return str;
}




export const formatORP = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = (value * 10).toFixed(1).trim();
  }
  return str;
}


export const formatTime = (value) => {

  let hl = get_high_low(value)
  if (hl.high < 24 && hl.low < 60) {
    return padDigits(hl.high, 2) + ":" + padDigits(hl.low, 2);
  } else {
    return "--:--";
  }
}

export const formatDuration = (value, unit) => {

  let hl = get_high_low(value)
  if (hl.high <= 24) {
    return (hl.high + " " + unit).trim();
  } else {
    return "--";
  }
}
//const endpoint = "wss://a254daig9zo2wn-ats.iot.amazonaws.com/mqtt";
export const formatHex = (value) => {
  let hex_value = (value).toString(16)
  return ("0x" + padDigits(hex_value, 4));
}

export const format = (regData, rawData) => {
  const address = regData.address;
  const value = rawData[address];
  let str = value.toString().concat(" ", regData.unit).trim();

  if (regData.type === regType.temperature) {
    str = formatTemperature(value, regData.unit);
  } else if (regData.type === regType.time) {
    str = formatTime(value);
  } else if (regData.type === regType.duration) {
    str = formatDuration(value, regData.unit);
  }
  else if (regData.type === regType.off_on)
  {
    str = ( value === 0)? offOnOptions[0].label:  offOnOptions[1].label;
  } 
  else if (regData.type === regType.disable_enable)
  {
    str = ( value === 0)? DisableEnableOptions[0].label:  DisableEnableOptions[1].label;
  }
  else if (regData.type === regType.pump_speed)
  {
    str =  "Speed: " + ( value +1);
  }
  else if (regData.type === regType.lookup)
  {
    str = "None"
    let lookup =  regData.lookup_table.find(element => element.value === value)
    if ( lookup !== undefined)
    {
      str = lookup.label
    }
    console.log("lookup Array for ",regData.address, value, str)
  }
   
  else { // other
    var hl = 0;
    var builds = 0;
    switch (address) {

      case REGS.SPA_MODE:
        if (value < spaModeStates.length) {
          str = spaModeStates[value];
        }
        break;
      case REGS.PH_CURR:
      case REGS.PH_SET_POINT:
      case REGS.PH_LEVEL:
      case REGS.PH_MEAS:

        let pH=value/10;
        str = pH;
        break;
      case REGS.ORP_CURR:
      case REGS.ORP_SET_POINT:
      case REGS.ORP_LEVEL:
      case REGS.ORP_MEAS:
        let Orp = value * 10;
        str = Orp;
        break;
      case REGS.SET_MODEL:
      case REGS.MODEL:
        let model_value = value;
        deviceOptionsSelect.forEach((element)=>
        {
          if ( model_value === element.value)
          {
            str = element.label;
          }
        }
        )

        break;

      case REGS.HARDWARE_VERSION:
        hl = get_high_low(value)
        str = "V" + hl.high + "." + hl.low;
        break;
      case REGS.FIRMWARE_VERSION:
        hl = get_high_low(value)
        str = hl.high + "." + padDigits(hl.low, 2);
        if (rawData.hasOwnProperty(REGS.FIRMWARE_BUILDS)) {
          builds = get_high_low(rawData[REGS.FIRMWARE_BUILDS]);
          str = str + "B" + builds.high; // ((rawData[REGISTERS.FIRMWARE_BUILDS] & 0xFF00) >> 8);
        }
        //  console.log("Firmware Version", address, "=>", str);
        break;
      case REGS.EXPANSION_PLUS_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXPANSION_PLUS_BUILD)) {
            builds = get_high_low(rawData[REGS.EXPANSION_PLUS_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;

      case REGS.EXP1_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXP1_BUILD)) {
            builds = get_high_low(rawData[REGS.EXP1_BUILD]);
            str = str + "B" + builds.high;
          }
        }

        break;
      case REGS.EXP2_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXP2_BUILD)) {
            builds = get_high_low(rawData[REGS.EXP2_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;

      case REGS.FAULT_FLAGS:
        str = formatHex(value)
        if (rawData.hasOwnProperty(REGS.FAULT_FLAGS_2)) {
          str = str + " " + (formatHex(rawData[REGS.EXP2_BUILD] & 0xFF00));
        }
        console.log("Faults", address, "=>", str);

        break;
      case REGS.IOCTRL_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.IOCTRL_BUILD)) {
            builds = get_high_low(rawData[REGS.IOCTRL_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;


      case REGS.POOL_SET_TEMPERATURE:
        let Temperature = value / 2;
        str = Temperature;
        break;
      case REGS.SPA_SET_TEMPERATURE:
        let STemperature = value / 2;
        str = STemperature;
        break;


      case REGS.PUMP_STATE:
        str = "None"
        let pump_state = get_high_low(value)
        str = pump_state.high + " : " + pump_states_t[pump_state.high]
        console.log("pump_state", pump_state.high, str)
        if (pump_state.high === pump_states_t["RUNNING"]) {
          str += " @ Speed " + (pump_state.low + 1)
        }
        break;
      case REGS.POOL_SIZE:
        if ( value <= poolSizeOptions.length)
        {
          str = poolSizeOptions[value].label
        }
        break;
        case REGS.SPA_SIZE:
        if ( value <= spaSizeOptions.length)
        {
          str = spaSizeOptions[value].label
        }
        break;

      case REGS.THERMISTOR_1_TYPE:
        str = value + ":" + thermistor_t[value]
        console.log("Ther1 type State", value, str)
        break;
      case REGS.THERMISTOR_2_TYPE:
        str = value + ":" + thermistor_t[value]
        console.log("Ther2 type State", value, str)
        break;
      case REGS.THERMISTOR_3_TYPE:
        str = value + ":" + thermistor_t[value]
        console.log("Ther3 type State", value, str)
        break;

      case REGS.THERMISTOR_1_OFFSET:
      case REGS.THERMISTOR_2_OFFSET:
      case REGS.THERMISTOR_3_OFFSET:
        str = formatSensorOffset(value, regData.unit);
        break;
      case REGS.HEATER_STATE:
        str = "None"
        str = value + ":" + heater_display_state_t[value]
        console.log("heater_state", value, str)
        break;
      case REGS.HEATER_2_STATE:
        str = "None"
        str = value + ":" + heater_display_state_t[value]
        console.log("heater_ 2 state", value, str)
        break;


      case REGS.SOCKET_1_STATUS:
      case REGS.SOCKET_2_STATUS:
      case REGS.SOCKET_3_STATUS:
      case REGS.SOCKET_4_STATUS:
      case REGS.SOCKET_5_STATUS:
      case REGS.SOCKET_6_STATUS:
      case REGS.SOCKET_7_STATUS:
      case REGS.SOCKET_8_STATUS:
      case REGS.VALVE_1_STATUS:
      case REGS.VALVE_2_STATUS:
      case REGS.VALVE_3_STATUS:
      case REGS.VALVE_4_STATUS:
      case REGS.VF_CONTACT_1_STATUS:
      case REGS.VF_CONTACT_2_STATUS:
        // let offset = address-REGISTERS.SOCKET_1_STATUS;
        if (value <= 3) {
          str = appliance_status_t[value];
        }
        break;


      case REGS.SOLAR_STATE:
        let solar_state = get_high_low(value)
        str = solar_state.low + ":" + solar_states_t[solar_state.low]
        console.log("solar State", solar_state.low, str)
        break;
      case REGS.PSW_REG_BLOWER_TIMEOUT_ENABLED:
        if ( value <= spaBlowerTimeOutOptions.length)
        {
          str = spaBlowerTimeOutOptions[value].label
        }
      break;

      case REGS.PUMP_TRIGGER:
        str = "";
        if (value === 0) {
          str = "None";
        }
        else {
          for (let index = 0; index < 16; index++) {
            const bit_field = (1 << index)
            if ((value & bit_field) === bit_field) {
              str += pump_triggers_t[bit_field] + "|";
              console.log("pump trigger", str, value, bit_field)
            }
          }
        }
        // console.log ( "pump trigger",  str, value, )
        break;
      case REGS.SOLAR_TYPE:

        str = value + ":" + solar_type_t[value]
        console.log("solar Type", value, str)
        break;

      case REGS.RTC_YEAR:
        const rtcRegs = [REGS.RTC_MONTH, REGS.RTC_DAY, REGS.RTC_HOUR, REGS.RTC_MIN];//, REGISTERS.RTC_SEC ];
        if (rtcRegs.every(v => rawData.hasOwnProperty(v))) {
          const dt = new Date(rawData[REGS.RTC_YEAR], rawData[REGS.RTC_MONTH] - 1, rawData[REGS.RTC_DAY], rawData[REGS.RTC_HOUR], rawData[REGS.RTC_MIN], 0); // , rawData[REGISTERS.RTC_SEC]
          str = DTformat(dt, datetimeFormats.mid);
          console.log("Date Time", dt, str)
        }
        else {
          console.log("Date Time not every ", str)
        }

        break;

      case REGS.APPLIANCE1_NAME_START:
      case REGS.APPLIANCE2_NAME_START:
      case REGS.APPLIANCE3_NAME_START:
      case REGS.APPLIANCE4_NAME_START:
      case REGS.APPLIANCE5_NAME_START:
      case REGS.APPLIANCE6_NAME_START:
      case REGS.APPLIANCE7_NAME_START:
      case REGS.APPLIANCE8_NAME_START:
      case REGS.APPLIANCE9_NAME_START:
      case REGS.APPLIANCE10_NAME_START:
      case REGS.APPLIANCE11_NAME_START:
      case REGS.APPLIANCE12_NAME_START:
      case REGS.APPLIANCE13_NAME_START:
      case REGS.APPLIANCE14_NAME_START:
      case REGS.APPLIANCE15_NAME_START:
      case REGS.APPLIANCE16_NAME_START:
      case REGS.APPLIANCE17_NAME_START:
      case REGS.APPLIANCE18_NAME_START:
      case REGS.APPLIANCE19_NAME_START:
      case REGS.DEVICE_NAME_START:
      case REGS.POOL_NAME_START:
      case REGS.SPA_NAME_START:
        
        const appNameRegisters = [address,address+1,address+2,address+3,address+4,address+5,address+6,address+7];
       
       
        if ( appNameRegisters.every(v => rawData.hasOwnProperty(v) ) )
        {
          var app_name = get_high_low(value);
          str = String.fromCharCode(app_name.high) + String.fromCharCode(app_name.low);

          for (let index = 1; index < 8; ++index) 
          {
            let offset = address+index;
            if (rawData.hasOwnProperty(offset)) {
              app_name = get_high_low(rawData[offset]);
              str = str + String.fromCharCode(app_name.high) + String.fromCharCode(app_name.low);
            }
            
          }
        }

        console.log("App Name ", str)
        break;
      case REGS.SOLAR_WINTER_NEXT_MINS:
        var minutes_till_next = value;
        let days = Math.floor( minutes_till_next / (24*60))

        minutes_till_next = value%(24*60);
        let hours = Math.floor( minutes_till_next /60);
        minutes_till_next = minutes_till_next%(60);
        let mins = minutes_till_next;
        str = String( days + " days " + hours + " hours "+ mins + " mins")

      break;

      default:
        // console.log("No custom formatting for register", address, "=>", str);
        break;
    }
  }
  return str;
}


// export const handleReceivedData = (topic, data) => {
//   const { value } = data;
//   const topicFromData =  data.value[Object.getOwnPropertySymbols(data.value)[0]] //data.value.topic;
//   const topfromData2 = data.value.topicSymbol
//   console.log("data Rec", data, value, topicFromData, topfromData2 );
  
//   if ( (topicFromData === topic) || (topfromData2 === topic) ){
//     const { messageId, modbusReg, modbusVal } = value;
//     console.log("received message", messageId, modbusReg, modbusVal);

//     const rawData = {};
//     if ((messageId === "read") && (modbusReg === 1) && (modbusVal.length % 2 === 0)) {
//       for (let i = 0; i < modbusVal.length; i += 2) {
//         const address = modbusVal[i];
//         const value = modbusVal[i + 1];
//         rawData[address] = value;
//       }
//     } else {
//       for (let i = 0; i < modbusVal.length; i++) {
//         const address = modbusReg + i;
//         const value = modbusVal[i];
//         rawData[address] = value;
//       }
//     }

//     for (let i = 0; i < registerData.length; i++) {
//       const address = registerData[i].address;
//       if (address in rawData) {
//         const fmtVal = format(registerData[i], rawData);
//         setRegisterData(prevState => {
//           const newState = prevState.map(obj => {
//             if (obj.address === address) {
//               return { ...obj, value: rawData[address], display: fmtVal };
//             }
//             return obj;
//           });
//           return newState;
//         });
//       }
//     }
//   } else {
//     console.log("topic mismatch, expected", topic, "received", topicFromData);
//   }
// }





// export const RegisterHandler = (props) => {

//   const [macAddress, setMacAddress] = useState("");
//   const [subscription, setSubscription] = useState(null);
//   const [connectionState, setConnectionState] = useState({
//     text: "Disconnected",
//     ready: false
//   });
  
//   const [refresh, setRefresh] = useState(false);
//   const [registerData, setRegisterData] = useState(registersInit);
//   const [selectedTab, setSelectedTab] = useState(dashTabItems.systemInfo);


//   const handleBack = () => {
//     console.log("HandleBack");
//     if (connectionState.ready) {
//       console.log("HandleBack CSR");
//       if (subscription != null) {
//         console.log("HandleBack sub not null");
//         subscription.unsubscribe();
//       }
//       setSubscription(null);
//     }
//     props.onBackPressed();
//   }
//   // const updateSelectedTab = (value) => {
//   //   setSelectedTab(value);
  
//   //   switch (value) {
//   //     case dashTabItems.control:
//   //       break;
//   //     case dashTabItems.heating:
      
//   //       break;
//   //     case dashTabItems.solar:
       
//   //       break;
//   //     case dashTabItems.pump:
       
//   //       break;
//   //     case dashTabItems.chemical:
//   //       break;
//   //     default:
//   //       break;
//   //   }
//   // }
  

//   // let initialDeviceNames = [
//   //   "","",""
//   // ];
  
//   // const [deviceNames, setDeviceName] = useState(
//   //   initialDeviceNames
//   // );
  
//   // const SetTheDeviceName = (thename, the_index) => {
    
//   //     const nextNames = deviceNames.map((c, i) => {
//   //       if (i === the_index) {
//   //         return thename;
//   //       } else {
//   //         // The rest haven't changed
//   //         return c;
//   //       }
//   //     });
//   //     setDeviceName(nextNames);
//   // }


//   // const [deviceNames, setName] = useState(deviceNamesArray)
//   // const pubTopic = "dontek" + macAddr + "/cmd/psw";
//   // const subTopic = "dontek" + macAddr + "/status/psw";
//   // console.log(pubTopic, subTopic);
//   //var cognitoIdentityId = {};


//   const getRegisterData = (address) => (
//     registerData.find(item => item.address === address)
//   )

  

  
//   const handleRegisterUpdate = (register, value) => {
//     const registerValue = parseInt(value, 10);
//     if (registerValue !== register.value) {
//       console.log(register, "=>", registerValue);
//       writeRegister(register.address, registerValue);
//     }
//   }

//   const handleNameRegisterUpdate = (register, value) =>
//   {
//     console.log( "handle name =>",  value);

//     var name_idx = 0;
//     var address_to_send = register.address;

//     for (let itr = 0; itr < 8; itr++) 
//     {
//       let registerValue = ( value.charCodeAt(name_idx) << 8 ) | ( value.charCodeAt(name_idx+1));

//       writeRegister(address_to_send, registerValue);
//       console.log(address_to_send, "=>", registerValue, name_idx);
//       name_idx = name_idx + 2;
//       address_to_send ++;
//     }
//     writeReadAll();
//   }

//   const handlePrivateRegisterUpdate = (register, value) => {
//     const registerValue = parseInt(value, 10);
//     if (registerValue !== register.value) {
//       console.log(register, "=>", registerValue);
//       writePasswordRegister(register.address, registerValue);
//     }
//   }


//   const handleTimesUpdate = (register, value) => {
//     let hours = getHours(value);
//     let minutes = getMinutes(value);
//     let timeValue = ((hours << 8) | minutes);
//     console.log("Date_range_times", value, timeValue, hours, minutes);

//     if (timeValue !== register.value) {
//       console.log("times registerupdate", register, "=>", timeValue);
//       writeRegister(register.address, timeValue);
//     }
//   }

//   async function writeReadAll() {
//     const topic = "dontek" + macAddress + "/cmd/psw"
//     await PubSub.publish(topic, readAllMessage);
//     console.log("readAll =>", topic);
//   }

//   async function writeRegister(address, value) {
//     const topic = "dontek" + macAddress + "/cmd/psw"
//     const message = { messageId: "write", modbusReg: address, modbusVal: [value] };
//     await PubSub.publish(topic, message);
//     console.log(topic, "=>", message);
//   }

//   async function writePasswordRegister(address, value) {
//     const topic = "dontek" + macAddress + "/cmd/psw"
//     let password = "_d0nt3k2K19"
//     const message = { messageId: "write", modbusReg: address, modbusVal: [value], valueString: password };
//     await PubSub.publish(topic, message);
//     console.log(topic, "=>", message);
//   }

//   useEffect(() => {

//     if ( props.selectedDevice !== undefined)
//     {
//       const macAddr = getMacAddress(props.selectedDevice.uniqueDeviceId);
//       setMacAddress(macAddr);
//       console.log("Got Mac Address", macAddr)
//     }
//     else
//     {
//       console.log("NO MAc Address")
//       setMacAddress("246f28db53ac");
//     }
//   }, [props.selectedDevice]);

  
//   useEffect(() => {
//     if (macAddress.length !== 0) {
//       const topic = "dontek" + macAddress + "/status/psw";
//       const sub = PubSub.subscribe(topic).subscribe({
//         next: data => handleReceivedData(topic, data),
//         error: error => console.log("subscribe error", error),
//         close: () => console.log("subscribe close")
//       });

//       setSubscription(sub);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [macAddress]);

//   useEffect(() => {
//     Hub.listen('pubsub', (data) => {
//       const { payload } = data;
//       console.log("pubSub:", payload);
//       if (payload.event === "ConnectionStateChange") {
//         const text = payload.data.connectionState;
//         const ready = (text === "Connected");
//         setConnectionState({ text, ready });
//       }
//     })
//   }, []);
  

//   useEffect(() => {
//     async function doReadAll() {
//       const topic = "dontek" + macAddress + "/cmd/psw"
//       await PubSub.publish(topic, readAllMessage);
//       console.log("readAll =>", topic);
//     }
//     if (connectionState.ready || refresh) {
//       doReadAll();
//     }
//   }, [connectionState.ready, refresh, macAddress]);

// }

