import React, { useState, useEffect } from "react";
import AppLayout from '@cloudscape-design/components/app-layout';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { paramsConfig, paramsNames, pumpStatecolours, pumpStateStrings, applianceStatecolours } from "./params";
import {
  API,
  Hub
} from 'aws-amplify';

import {
  Heading,
  Image,
  Text,
  useTheme,
  View,
  Authenticator,
} from "@aws-amplify/ui-react";

import {
  get_UID_from_db,
  get_short_UID_from_db,
  // fetchLogData
} from "./components/Common"

import {
  // addHours,
  compareAsc,
  differenceInDays,
  endOfDay,
  endOfToday,
  formatISO9075,
  lightFormat,
  startOfDay,
  startOfYesterday,
  // subHours,
} from "date-fns";

import DateRangePicker from "rsuite/DateRangePicker";
import Progress from "rsuite/Progress";
// import { TrendsView } from "./components/TrendsView";
// import { DashboardView } from "./components/DashboardView";
import { DeviceView } from "./components/DeviceView";
// import { AppliancesView } from "./components/AppliancesView";
import { HomeView } from "./components/HomeView2";
import {
  // Badge,
  // Button,
  Card,
  // Col,
  Grid,
  // Flex,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
 
  // Text as TextTremor,

  // Toggle,
  // ToggleItem,

} from "@tremor/react";

import "@aws-amplify/ui-react/styles.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";

import { listDevices, listLogData } from "./graphql/queries";
//import { updateDevice } from "./graphql/mutations";


import banner_w from "./banner_w.png";
import banner from "./banner.png";


import {
  //MdOutlineEdit,
  MdCheckCircleOutline,
  MdErrorOutline,
  MdSensorsOff,
  //MdRadioButtonUnchecked,
  // MdOutlineHome,
 
 
} from "react-icons/md";
import {
  accessLevels,
  datetimeFormats,
  colours,
  // tabItems,
  faultView,
  // coloursPrime
  statusItems,
  displayViews,
} from "./constants";

// import { DeviceList } from "./components/DeviceList";


// import {
//   Sidenav, Nav,
//   // Toggle as Rtoggle
// } from 'rsuite';
// import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
// import MagicIcon from '@rsuite/icons/legacy/Magic';
// import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
// import { tab } from "@testing-library/user-event/dist/tab";

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

const profileActions = [
  // { type: 'button', id: 'profile', text: 'Profile' },
  // { type: 'button', id: 'preferences', text: 'Preferences' },
  // { type: 'button', id: 'security', text: 'Security' },
  // {
  //   type: 'menu-dropdown',
  //   id: 'support-group',
  //   text: 'Support',
  //   items: [
  //     {
  //       id: 'documentation',
  //       text: 'Documentation',
  //       href: '#',
  //       external: true,
  //       externalIconAriaLabel: ' (opens in new tab)',
  //     },
  //     { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
  //     { id: 'support', text: 'Customer support' },
  //   ],
  // },
  { type: 'button', id: 'signout', text: 'Sign out' },
];




const timer = (ms) => (new Promise(res => setTimeout(res, ms)));



const authComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View backgroundColor={tokens.colors.white} textAlign="center" padding={tokens.space.medium}>
        <Image src={banner} alt="Logo" />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    const year = new Date().getFullYear();
    return (
      <View backgroundColor={tokens.colors.black} textAlign="center" padding={tokens.space.medium}>
        <Text color={tokens.colors.white} fontSize="0.7em">
          &copy; {year} Dontek Electronics Pty Ltd
        </Text>
      </View>
    );
  },
  ResetPassword: {
    Header() {
      return (
        <Heading level={5}>Reset Password</Heading>
      )
    }
  }
};



const SelectOneDevice = ({ devices, selected, onSelectedChanged }) => {
  return (
    <Select
      defaultValue={selected}
      onValueChange={(value) => onSelectedChanged(value)}
      placeholder="Select controller"
      className="max-w-none" >
      {devices.map((device) => (
        <SelectItem
          key={device.uniqueDeviceId}
          value={device.uniqueDeviceId} >
            {device.userName}</SelectItem>
      ))}
    </Select>
  );
}

const SelectManyDevices = ({ devices, selected, onSelectedChanged }) => {
  return (
    <MultiSelect
      defaultValue={selected}
      onValueChange={(value) => onSelectedChanged(value)}
      placeholder="Select controller(s)"
      className="max-w-none" >
      {devices.map((device) => (
        <MultiSelectItem
          key={device.uniqueDeviceId}
          value={device.uniqueDeviceId}
          text={device.userName} />
      ))}
    </MultiSelect>
  );
}



const SelectDateRange = ({ range, disabledDate, onChange }) => {
  return (
    <DateRangePicker
      value={range}
      onChange={(value) => onChange(value)}
      onOk={(value) => onChange(value)}
      character=" &#8211; "
      cleanable={false}
      shouldDisableDate={disabledDate}
      // disabledDate={disabledDate}
      editable={false}
      format={datetimeFormats.picker}
      isoWeek
      showOneCalendar
    />
  );
}

function App() {

  const [userGroups, setUserGroups] = useState([]);
  const [accessLevel, setAccessLevel] = useState(accessLevels.guest);
  const [devices, setDevices] = useState([]);
  const [params, setParams] = useState(paramsConfig);
  const [loading, setLoading] = useState(false);
  const [logFaults, setLogFaults] = useState([]);
  const [progress, setProgress] = useState(0);
  const [logDevice, setLogDevice] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedView, setSelectedView] = useState(0);
  const [selectedFaultView, setSelectedFaultView] = useState(0);
  const [selectedLatestFaults, setSelectedLatestFaults] = useState([]);
  const [selectedHistoryFaults, setSelectedHistoryFaults] = useState([]);

  const [selectedDevices, setSelectedDevices] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState([startOfYesterday(), endOfToday()]);
  const [selectedParams, setSelectedParams] = useState([paramsConfig[0].text]);
  const [latestFaults, setLatestFaults] = useState([]);
  const [historyFaults, setHistoryFaults] = useState([]);
  const [deviceNotes, setNotes] = useState([]);
  const [showDevice, setShowDevice] = useState(false);
  let phSelection = true;
  let ORPSelection = true;
  
  //const [deviceToEdit, setDeviceToEdit] = useState({});
  //const [editDeviceName, setEditDeviceName] = useState(false);
  const [configuredAppliances, setConfiguredAppliance] = useState('');
  const isApplianceConfigured = (id_to_check) => {

    var result = false;
    if (configuredAppliances.includes(id_to_check)) {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  };

  const isDeviceSelected = (device) => (
    (selectedDevices.length === 0) || (selectedDevices.includes(device.uniqueDeviceId))
  );


  const getAppliances_with_id = (appliance_array, id_to_check) => {

    let return_appliances = appliance_array.filter(appliance_array => appliance_array.id === id_to_check)

    if (return_appliances.length !== 0) {
      return return_appliances;
    }
    else {
      return null;
    }
  };


  

  const getTrackerdataForAppliances_with_id = (appliance_array, id_to_check) => {
    let apppliance_data = getAppliances_with_id( appliance_array, id_to_check)

    if ( apppliance_data === null)
    {
      console.log ( "No Data for app: ", id_to_check);
    }
    else
    {
      var applianceTrackerBlocks = []
      var trackerBlock = {}
      var offset = 0;

      apppliance_data.forEach ((item)=>
      {
        trackerBlock = {}
        trackerBlock.key = String(offset);
        trackerBlock.color = item.colour;
        trackerBlock.tooltip = item.DisplayString;
        
        offset++;
        applianceTrackerBlocks.push ( trackerBlock);
      });
      console.log ( "Appliance items:", applianceTrackerBlocks);
    }
    return applianceTrackerBlocks;
  }






  // const handleEditDeviceName = (device) => {
  //   setDeviceToEdit(device);
  //   setEditDeviceName(true);
  // }

  // const handleUpdateDeviceName = (result) => {
  //   setEditDeviceName(false);
  //   if (result !== null) {
  //     const { device, name } = result;
  //     if (device.userName !== name) {
  //       updateDeviceName(device.uniqueDeviceId, name);
  //     }
  //   }
  // }


  //const [pswRecords, setPSWData] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [logData, setLogData] = useState([]);
  const [pumpData, setPumpData] = useState([]);
  const [applianceDataArray, setApplianceData] = useState([]);
  const [applianceTrackerDataArray, setApplianceTrackerData] = useState([]);
  const [poolData, setPoolData] = useState([]);
  const [roofData, setRoofData] = useState([]);
  const [waterData, setWaterData] = useState([]);
  const [poolLabelsData, setPoolLabelData] = useState([]);
  const [ORPPHLabelsData, setORPPHLabels] = useState([]);
  const [ORPData, setORPData] = useState([]);
  const [PHData, setPHData] = useState([]);
  const [ORPRTData, setORPRTData] = useState([]);
  const [PHRTData, setPHRTData] = useState([]);

  const refreshData = () => {
    if ( selectedDevice === null || selectedDevice === undefined )
    {
      console.log("No controller defined ");
    }
    else if (selectedDevice.length === 0 )
    { 
      console.log("No controller selected");
    } else {
      fetchLogData(selectedDevice, selectedDateRange);
    }
  }



  const is_loading_or_no_device = () => {
    var result = false

    if (loading) {
      result = true;
    }
    if (result === false && selectedDevice.length === 0) {
      //   console.log("no device")
      result = true;
    }
    return result;
  };

  const refreshDataByDevice = (device) => {
    setSelectedDevice(device);
    setSelectedDevices([device]);
    fetchLogData(device, selectedDateRange);
  }

  const refreshDataByDate = (range) => {
    setSelectedDateRange(range);
    if (selectedDevice.length === 0) {
      console.log("No controller selected");
    } else {
      fetchLogData(selectedDevice, range);
    }
  }

  // const updateSelectedTab = (value) => {
  //   setSelectedTab(value);
  //   if (value === 2 || value === 3) {
  //     if ((selectedDevice.length !== 0) && (selectedDevice !== logDevice)) {
  //       refreshData();
  //     }
  //   }
  // }

  

 


  const updateSelectedDevices = (value) => {
    setSelectedDevices(value);
    if (value.length === 0) {
      setSelectedDevice('');
    } else if (value.length === 1) {
      setSelectedDevice(value[0]);
    }
  }

  async function fetchDevices() {
    console.log("Fetching Devices: groups", userGroups);
    let infoItems = [];
    let eventItems = [];
    let faultItems = [];
    let noteItems = [];
    let nextToken = null;
    
    let accLvl = accessLevels.guest;
    if(userGroups.includes('Admin')) {
      if(!userGroups.includes('guest')) {
        accLvl = accessLevels.super;
      }
    } 
    // else if(userGroups.includes('reclaim')) {
    //   if(!userGroups.includes('guest')) {
    //     accLvl = accessLevels.admin;
    //   }
    // }

    if(accLvl === accessLevels.guest) {
      // setLogParams(paramsGuest);
      console.log("Guest")
      // setSelectedTab(tabItems.trends);
    }

    setAccessLevel(accLvl);

    
    setDevices([]);
    setLatestFaults([]);

    do {
      try {
        const infoFromAPI = await API.graphql({
          query: listDevices,
          variables: {
            recordType: "info",
            nextToken
          }
        });
        infoItems = infoItems.concat(infoFromAPI.data.listDevices.items);
        nextToken = infoFromAPI.data.listDevices.nextToken;
  
      } catch(error) {
        console.log("fetchDevices.info:", error);
        await timer(1000);
      }
    } while(nextToken != null);
    console.log("Fetching Devices info", infoItems);
    do {
      try {
        const eventFromAPI = await API.graphql({
          query: listDevices,
          variables: {
            recordType: "event",
            nextToken
          }
        });
        eventItems = eventItems.concat(eventFromAPI.data.listDevices.items);
        nextToken = eventFromAPI.data.listDevices.nextToken;
      } catch(error) {
        console.log("fetchDevices.event:", error);
        await timer(1000);
      }
    } while(nextToken != null);
    console.log("Fetching Devices event", eventItems);
    do {
      try {
        const faultFromAPI = await API.graphql({
          query: listDevices,
          variables: {
            recordType: "fault",
            nextToken
          }
        });
        faultItems = faultItems.concat(faultFromAPI.data.listDevices.items);
        nextToken = faultFromAPI.data.listDevices.nextToken;
      } catch(error) {
        console.log("fetchDevices.fault:", error);
        await timer(1000);
      }
    } while(nextToken != null);

    do {
      try {
        const eventFromAPI = await API.graphql({
          query: listDevices,
          variables: {
            recordType: "notes",
            nextToken
          }
        });
        noteItems = noteItems.concat(eventFromAPI.data.listDevices.items);
        nextToken = eventFromAPI.data.listDevices.nextToken;
      } catch(error) {
        console.log("fetchDevices.notes:", error);
        await timer(1000);
      }
    } while(nextToken != null);
    console.log("Fetching Devices Notes", noteItems);


    const allDevices = [];
    const allFaults = [];
    const allNotes = [];
    for (let i = 0; i < infoItems.length; i++) {
      const tempDevice = {};
      tempDevice.shortDeviceId = get_short_UID_from_db( infoItems[i].recordID );
      tempDevice.uniqueDeviceId = get_UID_from_db( infoItems[i].recordID ) 
      tempDevice.userName = infoItems[i].userName;
      if (infoItems[i].modelType === undefined || infoItems[i].modelType === null)
      {
        tempDevice.modelType = "Unknown";
      }
      else
      {
        tempDevice.modelType = infoItems[i].modelType;
      }
      
      let evtItm = eventItems.filter(item => get_short_UID_from_db(item.recordID) === tempDevice.shortDeviceId );
      if (evtItm === undefined || evtItm.length === 0) {
        console.log("Event record not found for", tempDevice.uniqueDeviceId);
      } else {
        let date = new Date(evtItm[0].updatedAt);
        tempDevice.connStatDate = lightFormat(date, datetimeFormats.long);
        if (evtItm[0].connectionStatus === undefined || evtItm[0].connectionStatus === null)
        {
          tempDevice.connStatDesc = "Undefined"
        }
        else
        {
          tempDevice.connStatDesc = evtItm[0].connectionStatus.toUpperCase();
        }

        if (tempDevice.connStatDesc === "ONLINE") {
          tempDevice.connStatColor = colours.good;
          tempDevice.connStatIcon = MdCheckCircleOutline;
        } else {
          tempDevice.connStatColor = colours.warning;
          tempDevice.connStatIcon = MdSensorsOff;
        }

        let fltItm = faultItems.filter(item => get_short_UID_from_db(item.recordID) === tempDevice.shortDeviceId);
        if (fltItm === undefined || fltItm.length === 0) {
          tempDevice.ctrlStatDate = tempDevice.connStatDate;
          tempDevice.ctrlStatDesc = "OK";
          tempDevice.faults = ["NIL"];
        } else {
          date = new Date(fltItm[0].datetime)
          tempDevice.ctrlStatDate = lightFormat(date, datetimeFormats.long);
          tempDevice.ctrlStatDesc = fltItm[0].controllerStatus.toUpperCase()
          tempDevice.faults = fltItm[0].faults;
        }

        let noteItm = noteItems.filter(item => get_short_UID_from_db(item.recordID) === tempDevice.shortDeviceId);
        if (noteItm === undefined || noteItm.length === 0) 
        {

        }
        else
        {
          console.log("Found notes ", noteItm, "for COntroller:", tempDevice.shortDeviceId);
          tempDevice.notes =  noteItm;
        }

        if (tempDevice.ctrlStatDesc === "OK") {
          tempDevice.ctrlStatColor = colours.good;
          tempDevice.ctrlStatIcon = MdCheckCircleOutline;
        } else {
          tempDevice.ctrlStatColor = colours.error;
          tempDevice.ctrlStatIcon = MdErrorOutline;
        }

        for (let j = 0; j < tempDevice.faults.length; j++) {
          if (!allFaults.includes(tempDevice.faults[j])) {
            allFaults.push(tempDevice.faults[j]);
          }
        }
        allDevices.push(tempDevice);
      }
    }

    allFaults.sort();
    setLatestFaults(allFaults);
    setDevices(allDevices);
  }

  async function fetchLogData(deviceId, dateRange) {
    setLogData([]);
    setLoading(true)
    setProgress(0);
    setPumpData([])
    setPoolData([])
    setRoofData([])
    setApplianceData([])
    
    const datDt0 = startOfDay(dateRange[0]);
    const datDt1 = endOfDay(dateRange[1]);
  
    console.log("Fetch Log Data started", deviceId , "From:",  datDt0 , "To:", datDt1);

    const tm0 = formatISO9075(datDt0).replace(' ', 'T');
    const tm1 = formatISO9075(datDt1).replace(' ', 'T');
    const totalPerDay = (deviceId === "10255320252790919") ? 1500 : 300
    const progressMax = (totalPerDay * differenceInDays(datDt1, datDt0));
    let progress = 0;
    let nextToken = null;
    let allItems = [];
  
    phSelection = false;
    ORPSelection = false;
  
    do {
      try {
        const apiData = await API.graphql(
          {
            query: listLogData,
            variables:
            {
              uniqueDeviceId: deviceId,
              datetime: {
                between: [tm0, tm1]
              },
              nextToken
            }
          }
        );
        allItems = allItems.concat(apiData.data.listLogData.items);
        nextToken = apiData.data.listLogData.nextToken;
        progress = Math.floor(allItems.length * 100 / progressMax);
        setProgress(progress);
      }
      catch (error) {
        console.log("listLogData error:", error);
        timer(500);
      }
    }
    while (nextToken != null);
  
    let tmpLogData = [];
    let tmpPumpData = [];
    let tmpApplianceData = [];
    let tmpParams = [].concat(paramsConfig[0]);
    let tmpLogFaults = [];
    let tmpHistFlts = [];
    let tmpAppliancesConfigured = []
    let dataSetPool = []
    let dataSetRoof = []
    let dataSetWater = []
    let dataSetLabels = []
  
    let dataSetPHRT = []
    let dataSetORPRT = []
    let dataSetPH = []
    let dataSetORP = []
    let dataSetLabelsPHORP = []
  
    for (let i = 0, n = allItems.length; i < n; i++) {
      let tmpItm = allItems[i];
      if (tmpItm !== null) {
        let date = new Date(tmpItm.datetime);
        const datInRange = (compareAsc(date, datDt0) >= 0) && (compareAsc(date, datDt1) <= 0);
  
  
        let item = {};
        item.dateDisplay = lightFormat(date, datetimeFormats.short);
        item["Date/Time"] = lightFormat(date, datetimeFormats.long);
        let pump_item = {};
  
  
        if (tmpItm.faults != null) {
  
          if (datInRange) {
            for (let j = 0; j < tmpItm.faults.length; j++) {
              if (!tmpHistFlts.includes(tmpItm.faults[j])) {
                tmpHistFlts.push(tmpItm.faults[j]);
              }
            }
            item.Faults = tmpItm.faults;
            tmpLogFaults.push(item);
          }
        }
        else {
  
          let hasData = false;
          let valinRange = true;
          let hasPumpData = false;
          // var hasApplianceData = false;
  
          let hasPoolRoofOrWaterData = false;
          let Water = null;
          let Pool = null;
          let Roof = null;
  
  
          let hasPhOrORPData = false;
          let ORP_data = null;
          let PH_data = null;
          let ORP_RT_data = null;
          let PH_RT_data = null;
  
          for (let j = 0; j < paramsConfig.length; j++) {
            const paramName = paramsConfig[j].name;
            const paramSearch = paramsConfig[j].search;
            const paramSelectable = paramsConfig[j].selectable;
  
            let val = tmpItm[paramName];
  
            if (val !== null && paramSearch === true) {
              let itemDone = false;
  
              switch (paramName) {
                case paramsNames.ROOF:
                  {
                    hasPoolRoofOrWaterData = true;
                    if (val >= 120) {
                      valinRange = false;
                      val = 0;
                    }
                    Roof = val;
                    break;
                  }
                case paramsNames.WATER:
                  {
                    hasPoolRoofOrWaterData = true;
                    if (val >= 120) {
                      valinRange = false;
                      val = 0;
                    }
                    Water = val;
                    break;
                  }
                case paramsNames.POOL:
                  {
                    hasPoolRoofOrWaterData = true;
                    if (val >= 120) {
                      valinRange = false;
                      val = 0;
                    }
                    Pool = val;
                    break;
                  }
                case paramsNames.APP1:
                case paramsNames.APP2:
                case paramsNames.APP3:
                case paramsNames.APP4:
                case paramsNames.APP5:
                case paramsNames.APP6:
                case paramsNames.APP7:
                case paramsNames.APP8:
                case paramsNames.APP9:
                case paramsNames.APP10:
                case paramsNames.APP11:
                case paramsNames.APP12:
                case paramsNames.APP13:
                  {
                    var applianceItem = {};
                    var applianceConfigured = false;
                    //console.log( "App State",paramName ,val , item, tmpItm)
                    let appliancestring = lightFormat(date, datetimeFormats.short);
                    switch (val) {
                      case 0: appliancestring += (" OFF"); applianceConfigured = true; break; //'STARTUP',
                      case 1: appliancestring += (" ON"); applianceConfigured = true; break;  //  'IDLE',
                      case 2: appliancestring += (" Not In Use "); break;  //  'POWER P',
                      default: appliancestring += ("???" + val); break;  //  'POWER P',
                    }
                    applianceItem.id = paramsConfig[j].id;
                    applianceItem.DisplayString = appliancestring
                    applianceItem.name = paramsConfig[j].text;
                    applianceItem.colour = applianceStatecolours[val];
                    // hasApplianceData = true;
                    itemDone = true;
                    tmpApplianceData.push(applianceItem);
  
                    if (applianceConfigured) {
                      if (!tmpAppliancesConfigured.find(e => e === paramsConfig[j].id)) {
                        tmpAppliancesConfigured.push(paramsConfig[j].id);
                      }
                    }
                    break;
                  }
  
                case paramsNames.PUMP:
                  {
                    let pump_speed = (val & 0xFF) + 1
                    let pump_state = (val >> 8)
                    let pump_string = lightFormat(date, datetimeFormats.short);
                    switch (pump_state) {
                      case 0: pump_string += (" " + pumpStateStrings[pump_state]); break; //'STARTUP',
                      case 1: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'IDLE',
                      case 2: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER P',
                      case 3: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWERED',
                      case 4: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'WAIT',
                      case 5: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'PRIME', 
                      case 6: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed); break;  //  'SET SPEED',
                      case 7: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed); break;  //  'SPD POLL',
                      case 8: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed); break;  //  'CHK SPD',
                      case 9: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'SET ON',
                      case 10: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'CHK ON P',
                      case 11: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'CHK ON',
                      case 12: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed); break;  //  'RUNNING',
                      case 13: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'RUN ON',
                      case 14: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'PWR DWN',
                      case 15: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'V OFF P', 
                      case 16: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'VER OFF',
                      case 17: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'FAULT',
                      case 18: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'PRIME OFF',
                      case 19: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'RUN ON', 
                      case 20: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER OFF',
                      case 21: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER ON'
                      default: pump_string += (" UnKnown");
                    }
  
                    pump_item.DisplayString = pump_string;
                    pump_item.PumpState = pumpStateStrings[pump_state];
                    pump_item.PumpSpeed = pump_speed;
  
                    pump_item.PumpCol = pumpStatecolours[pump_state];
  
                    // console.log( "pump State" , pump_state , "pump Speed", pump_speed , "Item: ", item)
                    itemDone = true;
                    hasPumpData = true;
                    break;
                  }
                case paramsNames.ORP:
                  {
                    hasPhOrORPData = true;
                    if (val >= 2500) {
                      val = 800;
                    }
                    ORP_data = val;
                    break;
                  }
  
                case paramsNames.PH:
                  {
                    hasPhOrORPData = true;
                    if (val >= 25) {
                      val = 0;
                    }
                    PH_data = val;
                    console.log("PH", val)
                    break;
                  }
                case paramsNames.PHRT:
                  {
                    hasPhOrORPData = true;
                    if (val >= 100) {
                      val = val / 100;
                    }
                    PH_RT_data = val;
                    break;
                  }
  
                case paramsNames.ORPRT:
                  {
                    hasPhOrORPData = true;
                    if (val >= 2500) {
                      val = 2500;
                    }
                    ORP_RT_data = val;
                    break;
                  }
                default: break;
              }
              // console.log("paramName", paramName)
  
  
              if (datInRange && valinRange) {
                hasData = true;
  
                if (paramName === params.PH || paramName === params.PHRT) {
                  phSelection = true;
  
                }
                if (paramName === params.ORP || paramName === params.ORPRT) {
                  ORPSelection = true;
                }
              }
  
              if (itemDone === false) {
                item[paramsConfig[j].text] = val;
              }
  
              if (paramSelectable) {
                if (!tmpParams.find(e => e.name === paramName)) {
                  tmpParams.push(paramsConfig[j]);
                }
              }
  
            }
            else {
              if (paramSelectable) {
                if (!tmpParams.find(e => e.name === paramName)) {
                  tmpParams.push(paramsConfig[j]);
                }
              }
            }
          }
          if (hasData) {
            // console.log("item:", item)
            tmpLogData.push(item);
          }
  
  
  
          if (hasPhOrORPData) {
            dataSetLabelsPHORP.push(item.dateDisplay)
            if (ORP_data != null) {
              dataSetORP.push(ORP_data)
            }
  
            if (PH_data != null) {
              dataSetPH.push(PH_data)
            }
  
            if (ORP_RT_data != null) {
              dataSetORPRT.push(ORP_RT_data)
            }
  
            if (PH_RT_data != null) {
              dataSetPHRT.push(PH_RT_data)
            }
  
          }
  
          if (hasPoolRoofOrWaterData) {
            dataSetLabels.push(item.dateDisplay)
            if (Roof !== null) {
              dataSetRoof.push(Roof)
            }
  
            if (Water !== null) {
              dataSetWater.push(Water)
            }
  
            if (Pool !== null) {
              dataSetPool.push(Pool)
            }
          }
  
          if (hasPumpData) {
            tmpPumpData.push(pump_item);
          }
        }
      }
    }
    console.log("appliances:", tmpAppliancesConfigured);
    setConfiguredAppliance(tmpAppliancesConfigured);
    setPumpData(tmpPumpData);
    setLogDevice(deviceId);
    setLogData(tmpLogData);
    setParams(tmpParams);
    setPoolData(dataSetPool)
    setPoolLabelData(dataSetLabels)
    setRoofData(dataSetRoof)
    setWaterData(dataSetWater)
  
    setORPPHLabels(dataSetLabelsPHORP)
    setORPData(dataSetORP)
    setPHData(dataSetPH)
    setORPRTData(dataSetORPRT)
    setPHRTData(dataSetPHRT)
  
    // if( dataSetPool.length !== 0)
    // {
    //   console.log ( "pool labels", dataSetLabels)
    //   console.log ( "pool Data", dataSetPool)
    //  // let dataSet = "type: 'line',\n label: " + dataSetPoolLabels +'\n data: [ ' + dataSetPool + "]"
    //  // console.log ( dataSet);
  
  
    //   console.log ( "pool labels", poolLabelsData)
    //   console.log ( "pool Data", poolData)
    // }
    // console.log("Appliance Item:", tmpApplianceData);
  
    setApplianceData(tmpApplianceData);
    var temptrackerdata = [];
    for ( let itr = 0; itr < 13; itr++)
    {
      let curr_app = getTrackerdataForAppliances_with_id(applianceDataArray, itr);
      temptrackerdata.push(curr_app);
    }
    setApplianceTrackerData(temptrackerdata);
    
  
    let tmpSelected = [paramsConfig[0].text];
    if (selectedParams.length !== 0) {
      for (let i = 0; i < tmpParams.length; i++) {
        if (selectedParams.includes(tmpParams[i].text) && !tmpSelected.includes(tmpParams[i].text)) {
          tmpSelected.push(tmpParams[i].text);
  
        }
      }
    }
    setSelectedParams(tmpSelected);
    setLoading(false)
    setLogFaults(tmpLogFaults);
    setHistoryFaults(tmpHistFlts);
    console.log("Fetch Log Data Ending");
  }
  
  const handleBackFromDevice = () => {
    setShowDevice(false);
    setSelectedDevice('');
  }
 

  // async function createPSWData(event) 
  // {
  //   event.preventDefault();
  //   const form = new FormData(event.target);
  //   const data = 
  //   {
  //     uniqueDeviceId: form.get("uniqueDeviceId"),
  //     datetime: form.get("datetime"),
  //     //image: image.name,
  //   };
  // //if (!!data.image) await Storage.put(data.name, image);
  //   await API.graphql({
  //     query: createPSWMutation,
  //     variables: { input: data },
  //   });

  //   fetchPSWRecords();
  //   event.target.reset();
  // }

  // async function deletePSWData({ uniqueDeviceId, datetime }) 
  // {
  //   const newPSWDatas = pswRecords.filter((pswdata) => pswdata.uniqueDeviceId !== uniqueDeviceId);
  //   setPSWData(newPSWDatas);
  //   await API.graphql({
  //     query: deletePSWMutation,
  //     variables: { input: { uniqueDeviceId } },
  //   });
  // }



  // async function updateDeviceName(deviceId, name) {
  //   await API.graphql({
  //     query: updateDevice,
  //     variables: {
  //       input: {
  //         recordType: "info",
  //         uniqueDeviceId: deviceId,
  //         userName: name
  //       }
  //     }
  //   });
  //   fetchDevices();
  // }


  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        try {
          const { signInUserSession } = payload.data;
          const groups = signInUserSession.accessToken.payload["cognito:groups"];
          if (groups !== undefined && groups !== null && groups.length > 0) {
            setUserGroups(groups);
          } else {
            console.log("signInUserSession:", signInUserSession)
          }
        } catch (error) {
          console.log("signIn:", error);
        }
      } else if (payload.event === 'signOut') {

        setAccessLevel(accessLevels.guest);
        setDevices([]);
        setLogDevice('');
        setLogData([]);
        // setLogPowerHourly([]);
        //   setLogPowerDaily([]);
        setLogFaults([]);
        setLatestFaults([]);
        setHistoryFaults([]);
        // setSelectedTab(tabItems.home);
        setNotes([]);
        //  setSelectedStatus(statusItems.all);
        // setSelectedTab(tabItems.home);
        setSelectedView(displayViews.grid);
        setSelectedStatus(statusItems.all);
        setSelectedDevice('');
        setSelectedDevices([]);
        setSelectedDateRange([startOfYesterday(), endOfToday()]);
        setSelectedParams([paramsConfig[0].text]);
        // setSelectedPowerDisplay(powerDisplay.hourly);
        setSelectedFaultView(faultView.current);
      }
    });
  }, []);

  useEffect(() => {
    fetchDevices();
  }, []);

  // const [expanded, setExpanded] = React.useState(true);
  // const [activeKey, setActiveKey] = React.useState('1');


  // let time = new Date().getTime();
  // const setActivityTime = (e) => {
  //   time = new Date().getTime();
  // }
  // document.body.addEventListener("mousemove", setActivityTime);
  // document.body.addEventListener("keypress", setActivityTime);

  // const refresh = () => {
  //   if (new Date().getTime() - time >= 60000) {
  //     window.location.reload(true);
  //   } else {
  //     setTimeout(refresh, 10000);
  //   }
  // }

  // setTimeout(refresh, 10000);

  // const [activeHref, setActiveHref] = React.useState(
  //   "#/Home"
  // );
  // const [searchValue, setSearchValue] = useState('');

  const handleViewDevice = (device) =>
  {
    console.log ( "View Device", device)
    setSelectedDevice(device);
    setShowDevice(true)
    // setShowDashboard(true);
    // setSelectedTab(tabItems.device);
  }
  // const navItems = [

  //   { type: "link", text: "Home", icon: { MdOutlineHome },  href: "#/Home" },
  //   { type: "link", text: "Trends", href: "#/Trends" },
  //   { type: "link", text: "Appliances", href: "#/Appliances" },
  //   { type: "link", text: "Faults", href: "#/faults" },
  //   { type: "link", text: "Control", href: "#/Control" },
  //   {
  //     type: 'section',
  //     text: 'Manage',
  //     items: [
  //       { type: 'link', text: 'Pages', href: '#/pages' },
  //       { type: 'link', text: 'Users', href: '#/users' },
  //     ],
  //   },
  //   {
  //     type: 'section',
  //     text: 'Set up',
  //     items: [
  //       { type: 'link', text: 'Database', href: '#/database' },
  //       { type: 'link', text: 'Authentication', href: '#/authentication' },
  //       { type: 'link', text: 'Analytics', href: '#/analytics' },
  //       { type: 'link', text: 'Predictions', href: '#/predictions' },
  //       { type: 'link', text: 'Interactions', href: '#/interactions' },
  //       { type: 'link', text: 'Notifications', href: '#/notifications' },
  //     ],
  //   },
  // ];

  const Content = ({ signOut, user }) => {
    return (
    //   <Card
    //   decoration="bottom"
    //   decorationColor={colours.cardDecoration}
    //  >
      <Grid 
        numItems={1}
        className="gap-2 sm:p-4"
      >
      {
        (showDevice === false) &&
        <HomeView
            devices={devices}
            userGroups={userGroups}
            accessLevel={accessLevel}
            selectedDevices={selectedDevices}
            selectedStatus={selectedStatus}
            selectedView={selectedView}
            onSelectedDevicesChanged={updateSelectedDevices}
            onSelectedStatusChanged={setSelectedStatus}
            onSelectedViewChanged={setSelectedView}
            onRefresh={fetchDevices}
            // onViewTrends={handleViewTrends}
            // onViewFaults={handleViewFaults}
            // onViewDashboard={handleViewDashboard}
            onViewDevice={handleViewDevice}
        />
      }
      {
          (showDevice === true) &&
          <DeviceView
            device={devices.find((e) => e.uniqueDeviceId === selectedDevice)}
            selectedDevice={selectedDevice}
            selectedDevices={selectedDevices}
            selectedDateRange={selectedDateRange}
            // device={selectedDevice}
            devices={devices}
            logData={logData}
            params={params}
            pumpData={pumpData}
            pool={poolData}
            water={waterData}
            roof={roofData}
            poolLabels={poolLabelsData}
            applianceDataArray={applianceDataArray}

            latestFaults={latestFaults}
            ORPPHLabels={ORPPHLabelsData}
            ORP={ORPData}
            PH={PHData}
            ORPRT={ORPRTData}
            PHRT={PHRTData}
            selectedParams={selectedParams}
           
            phSelection={phSelection}
            ORPSelection={ORPSelection}
            loading={loading}
            progress={progress}
            onSelectedDeviceChanged={refreshDataByDevice}
            onSelectedDateRangeChanged={refreshDataByDate}
            onSelectedParamsChanged={setSelectedParams}
            onSelectedDevicesChanged={updateSelectedDevices}
            onSelectedStatusChanged={setSelectedStatus}
            onSelectedViewChanged={setSelectedView}
            onRefresh={refreshData}
            isLoadingOrNoDevice={is_loading_or_no_device()}
            onSetSelectedFaultView={setSelectedFaultView}
            onSetSelectedHistoryFaults={setSelectedHistoryFaults}
            onsetSelectedLatestFaults={setLatestFaults}
            onFetchDevices={fetchDevices}
            onRefreshData={refreshData}
            applianceTrackerDataArray={applianceTrackerDataArray}
            // onViewDevice={onViewDevice}
            // onViewFaults={handleViewFaults}
            // onViewDashboard={handleViewDashboard}
            setSelectedDevice={setSelectedDevice}
            onBackPressed={handleBackFromDevice}
            selectedLatestFaults ={selectedLatestFaults}
            selectedHistoryFaults= {selectedHistoryFaults}
            selectedFaultView={selectedFaultView}
            isApplianceConfigured={isApplianceConfigured}
            getAppliances_with_id={getTrackerdataForAppliances_with_id}

          />
        }
      
      
      </Grid>
      // </Card>

      // <Card
      //   decoration="bottom"
      //   decorationColor={colours.cardDecoration}
      //  >
      //   <Grid
      //     numItems={1}
      //     className="gap-2"
      //   >
      //       <Col numColSpan={1}>

      //         {
      //           (showDashboard === false) &&
      //           <TabList
      //             defaultValue={selectedTab}
      //             onValueChange={(value) => updateSelectedTab(value)}
      //             color="sky"
      //             key={selectedTab}
      //             className="mt-3">

      //             <Tab value={tabItems.home} text="Home" id="id_tabHome" icon={MdOutlineHome} />
      //             <Tab value={tabItems.trends} text="Trends" id="id_tabTrends" icon={MdOutlineInsertChart} />
      //             <Tab value={tabItems.appliances} text="Appliances" id="id_tabAppliances" icon={MdOutlineInsertChart} />
      //             <Tab value={tabItems.faults} text="Faults" id="id_tabFaults" icon={MdOutlineError} />
      //             <Tab value={tabItems.dashboard} text="Control" id="id_tabDashBoard" onSelect={handleViewDashboard} icon={MdOutlineError} />
      //             <Tab value={tabItems.device} text="Devce" id="id_tabDevice" onSelect={handleViewDevice} icon={MdOutlineError} />
                
      //           </TabList>
      //         }
      //         {

      //           (showDashboard === false) && (selectedTab === tabItems.home) &&
      //           <HomeView
      //             devices={devices}
      //             userGroups={userGroups}
      //             accessLevel={accessLevel}
      //             selectedDevices={selectedDevices}
      //             selectedStatus={selectedStatus}
      //             selectedView={selectedView}
      //             onSelectedDevicesChanged={updateSelectedDevices}
      //             onSelectedStatusChanged={setSelectedStatus}
      //             onSelectedViewChanged={setSelectedView}
      //             onRefresh={fetchDevices}
      //             onViewTrends={handleViewTrends}
      //             onViewFaults={handleViewFaults}
      //             onViewDashboard={handleViewDashboard}
      //             onViewDevice={handleViewDevice}
      //           />
      //         }
      //         {
      //           (showDashboard === false) && (selectedTab === tabItems.trends) &&
      //           <TrendsView
      //             devices={devices}
      //             logData={logData}
      //             params={params}
      //             pumpData={pumpData}
      //             pool={poolData}
      //             water={waterData}
      //             roof={roofData}
      //             poolLabels={poolLabelsData}
      //             applianceDataArray={applianceDataArray}


      //             ORPPHLabels={ORPPHLabelsData}
      //             ORP={ORPData}
      //             PH={PHData}
      //             ORPRT={ORPRTData}
      //             PHRT={PHRTData}
      //             selectedDevice={selectedDevice}
      //             selectedDateRange={selectedDateRange}
      //             selectedParams={selectedParams}
      //             phSelection={phSelection}
      //             ORPSelection={ORPSelection}
      //             loading={loading}
      //             progress={progress}
      //             onSelectedDeviceChanged={refreshDataByDevice}
      //             onSelectedDateRangeChanged={refreshDataByDate}
      //             onSelectedParamsChanged={setSelectedParams}
      //             onRefresh={refreshData}
      //             isLoadingOrNoDevice={is_loading_or_no_device()}
                

      //             onViewFaults={handleViewFaults}
      //             onViewDashboard={handleViewDashboard}
      //           />
      //         }
      //          {
      //           (selectedTab === tabItems.dashboard) &&
      //           <DashboardView
      //             selectedDevice={devices.find((e) => e.uniqueDeviceId === selectedDevice)}
      //             onBackPressed={handleBackFromDashboard}
      //           />
      //         }

      //         {
      //           (selectedTab === tabItems.dashboard) &&
      //           <DashboardView
      //             selectedDevice={devices.find((e) => e.uniqueDeviceId === selectedDevice)}
      //             onBackPressed={handleBackFromDashboard}
      //           />
      //         }
      //       </Col>
      //     </Grid>
      // </Card>
    )
  };

  return (
    <Authenticator
      components={authComponents}
      hideSignUp={true}
      variation="modal"
    >
      {({ signOut, user }) =>
      (
        <main className="bg-neutral-100 p-2 sm:p-4">
        
        <TopNavigation
          i18nStrings={i18nStrings}
          identity={{
            href: '',
            title: '',
            logo: { src: banner_w, alt: 'Dontek App logo' },
          }}
          // search={
          //   <Input
          //     ariaLabel="Input field"
          //     clearAriaLabel="Clear"
          //     value={searchValue}
          //     type="search"
          //     placeholder="Search"
          //     onChange={({ detail }) => setSearchValue(detail.value)}
          //   />
          // }
          utilities={[
            // {
            //   type: 'button',
            //   iconName: 'notification',
            //   ariaLabel: 'Notifications',
            //   badge: true,
            //   disableUtilityCollapse: true,
            // },
            // { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },
            {
              type: 'menu-dropdown',
              text: "Welcome " + user.attributes.email,
              description: 'customer@example.com',
              iconName: 'user-profile',
              items: profileActions,
            },
            {
              type: 'button',
              text: "Sign Out",
              // iconName: 'notification',
              ariaLabel: 'Notifications',
              badge: false,
              disableUtilityCollapse: true,
              onClick: signOut
            },
          ]}
        />

          <AppLayout
            stickyNotifications
            toolsHide={true}
            headerSelector="#header"
            disableContentPaddings={true}
            ariaLabels={{ navigationClose: 'close' }}
            // navigation={<SideNavigation activeHref="#/pages" items={navItems} />}
            navigationHide = {true}
            // breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
            contentType="table"
            content={<Content signOut={signOut} user={user} />}
          // notifications={<Notifications />}
          />
        </main>
      )
      }

    </Authenticator>
  );
}

export default App;



