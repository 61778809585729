import React, { useState } from "react";

import {
  AreaChart,
  Button,
  Card,
  Grid,
  Flex,
  Text as TextTremor,
  Title,
  TabGroup,
  TabList,
  Tab,
} from "@tremor/react";

import {
  // CsvLink,
  ProgressBar,
  SelectDateRange,
  SelectOneDevice
} from "./Common";

import { paramsColors } from "../params";

import {
  colours,
  // coloursHex, 
  // datetimeFormats 
} from "../constants";
import {
  GiBubbles as IconBubbles,
  GiChemicalDrop as IconChemical,

} from "react-icons/gi";

export const TrendsView = props => {


  function ChooseGraph() {
    const [setlectedValue, setSelectedValue] = useState('relative');
    return (

      props.isLoadingOrNoDevice ? (<div></div>) : (
        <div className="space-y-2 ">
          <Card>
            <Flex>
              <div>
                <Title> {setlectedValue === 'ph' ? "pH Readings" : "ORP Readings"} </Title>
                
              </div>
              <TabGroup onIndexChange={(value) => setSelectedValue(value)} defaultIndex={0} >
                <TabList variant="solid"
                  color="zinc"
                  
                >
                  <Tab
                    icon={IconBubbles}
                    value="orp">
                      ORP Readings
                  </Tab>
                  <Tab
                    icon={IconChemical}
                    value="ph">pH readings
                  </Tab>
                </TabList>
              </TabGroup>
            </Flex>

            {
              (props.phSelection === false) ? (
                <Flex alignItems="center" className="space-x-2">
                  <TextTremor color={colours.warning}>No pH to Display</TextTremor>
                </Flex>
              ) : (
                (props.logData.length === 0) ? (
                  <TextTremor color={colours.warning}>No data found</TextTremor>
                ) : (
                
                  
                    <AreaChart
                      data={props.logData}
                      index="dateDisplay"
                      categories={setlectedValue === 'ph' ? ["pH", "pH Curr"] : ["ORP (mV)", "ORP Curr (mV)"]}
                      colors={paramsColors}
                      showAnimation={true}
                      showLegend={true}
                      startEndOnly={true}
                      className="mt-6 h-60"
                     
                    />
                )
              )
            }
          </Card>
        </div>


      )
    );
  }

  let time = new Date().getTime();
  const setActivityTime = (e) => {
    time = new Date().getTime();
  }
  document.body.addEventListener("mousemove", setActivityTime);
  document.body.addEventListener("keypress", setActivityTime);

  const refresh = () => {
    if (new Date().getTime() - time >= 10*60000) {
      (props.onRefresh())
   //   window.location.reload(true);
    } else {
      setTimeout(refresh, 10000);
    }
  }

  setTimeout(refresh, 10000);



  return (
    <div className="mt-3">
      <Grid
        numItems={3}
        numItemsLg={3}
        className="gap-2"
      >
        <SelectOneDevice
          devices={props.devices}
          selected={props.selectedDevice}
          onSelectedChanged={props.onSelectedDeviceChanged}
        />
        <Flex
          justifyContent="start"
          alignItems="center"
          className="space-x-2"
        >
          <SelectDateRange
            range={props.selectedDateRange}
            maxDays={30}
            //disabledDate={combine(allowedMaxDays(7), afterToday())}
            onChange={props.onSelectedDateRangeChanged}
          />
          <Button
            color={colours.default}
            size="sm"
            onClick={props.onRefresh}
            disabled={(props.selectedDevice.length === 0) || props.loading}>Refresh</Button>
        </Flex>


      </Grid>
      <div className="mt-3">
      {
        (props.loading ? (
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <ProgressBar percentage={props.progress} />
          </Card>
        ) : (props.selectedDevice.length === 0 ? (
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <TextTremor color={colours.warning}>Select a controller</TextTremor>
          </Card>
        ) : (""
          
        )
        )
        )
      }
      <div>
        {ChooseGraph()}
      </div>
      {
        props.isLoadingOrNoDevice ? (<div></div>) : (

          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            {
              (props.pool.length === 0) ? (
                <TextTremor color={colours.warning}>No data found</TextTremor>
              ) : (
                <div>
                  <AreaChart
                    data={props.logData}
                    index="dateDisplay"
                    categories={["Pool (\u00B0C)", "Roof (\u00B0C)"]}
                    colors={paramsColors}
                    showAnimation={true}
                    showLegend={true}
                    startEndOnly={true}
                    className="mt-6 h-80"
                   />
                </div>
              )
            }
          </Card>
        )

      }
    </div>
    </div>
  )
};