/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      recordType
      recordID
      userName
      userID
      datetime
      connectionStatus
      controllerStatus
      faults
      macAddress
      customMac
      customUniqueDeviceId
      createdAt
      updatedAt
      modelType
      notes
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      recordType
      recordID
      userName
      userID
      datetime
      connectionStatus
      controllerStatus
      faults
      macAddress
      customMac
      customUniqueDeviceId
      createdAt
      updatedAt
      modelType
      notes
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      recordType
      recordID
      userName
      userID
      datetime
      connectionStatus
      controllerStatus
      faults
      macAddress
      customMac
      customUniqueDeviceId
      createdAt
      updatedAt
      modelType
      notes
      __typename
    }
  }
`;
export const createLogData = /* GraphQL */ `
  mutation CreateLogData(
    $input: CreateLogDataInput!
    $condition: ModelLogDataConditionInput
  ) {
    createLogData(input: $input, condition: $condition) {
      uniqueDeviceId
      datetime
      RoofTemp
      WaterTemp
      caseTemp
      createdAt
      PoolTemp
      updatedAt
      ORP
      PH
      ORP_RT
      PH_RT
      faults
      pumpState
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      heaterState
      heater2State
      __typename
    }
  }
`;
export const updateLogData = /* GraphQL */ `
  mutation UpdateLogData(
    $input: UpdateLogDataInput!
    $condition: ModelLogDataConditionInput
  ) {
    updateLogData(input: $input, condition: $condition) {
      uniqueDeviceId
      datetime
      RoofTemp
      WaterTemp
      caseTemp
      createdAt
      PoolTemp
      updatedAt
      ORP
      PH
      ORP_RT
      PH_RT
      faults
      pumpState
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      heaterState
      heater2State
      __typename
    }
  }
`;
export const deleteLogData = /* GraphQL */ `
  mutation DeleteLogData(
    $input: DeleteLogDataInput!
    $condition: ModelLogDataConditionInput
  ) {
    deleteLogData(input: $input, condition: $condition) {
      uniqueDeviceId
      datetime
      RoofTemp
      WaterTemp
      caseTemp
      createdAt
      PoolTemp
      updatedAt
      ORP
      PH
      ORP_RT
      PH_RT
      faults
      pumpState
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      heaterState
      heater2State
      __typename
    }
  }
`;
