import React from "react";

import {

  Badge,
  Button,
  Card,
  Grid,
  Flex,
  MultiSelect,
  MultiSelectItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text as TextTremor,

  TabList,
  TabGroup,
  Tab,

} from "@tremor/react";

import CsvLink from "./CsvLink";

import {

  ProgressBar,
  SelectDateRange,
  SelectOneDevice
} from "./Common";
import DateRangePicker from "rsuite/DateRangePicker";


import {
  colours,
  datetimeFormats 
} from "../constants";


import {
  MdCheckCircleOutline,
  MdErrorOutline,

} from "react-icons/md";

export const HistoryView = props => {

  const refreshFaultView = () => {
    if (props.selectedFaultView === 0) {
      props.onFetchDevices();
    } else {
      props.onRefreshData();
    }
  }
  
  const isLatestFaultSelected = (device) => (
    (props.selectedLatestFaults.length === 0) || props.selectedLatestFaults.some(fault => device.faults.includes(fault))
  );

  const isHistoryFaultSelected = (record) => (
    (props.selectedHistoryFaults.length === 0) || props.selectedHistoryFaults.some(fault => record.Faults.includes(fault))
  );

  const isDeviceSelected = (device) => (
    (props.selectedDevices.length === 0) || (props.selectedDevices.includes(device.uniqueDeviceId))
  );

  const { combine, allowedMaxDays, afterToday } = DateRangePicker;

  const sortFaults = (a, b) => {
    if (a.ctrlStatDesc === b.ctrlStatDesc) {
      if (a.faults.length === b.faults.length) {
        return a.ctrlStatDate > b.ctrlStatDate ? -1 : 1;
      } else {
        return a.faults.length > b.faults.length ? -1 : 1;
      }
    } else {
      return a.ctrlStatDesc === "FAULT" ? -1 : 1;
    }
  }
  
  return (
    <div className="mt-3">
      <Flex alignItems="center" className="space-x-2">
        <TabGroup 
          defaultIndex={props.selectedFaultView}
          onIndexChange={(value) => props.onSetSelectedFaultView(value)}
          color="sky" >
            <TabList>
          <Tab value={0} text="Current" />
          <Tab value={1} text="History" />
          </TabList>
        </TabGroup>
        <div>
          <Button
            
            color="sky"
            size="sm"
            onClick={refreshFaultView}
            disabled={props.selectedDevice.length === 0 || props.loading}>
              Refresh 
          </Button>
        </div>
      </Flex>
      {props.selectedFaultView === 0 ? (
        <>
          <Grid
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={2}
            
            className="gap-2"
          >
     
            <MultiSelect
              defaultValue={props.selectedLatestFaults}
              onValueChange={(value) => props.onsetSelectedLatestFaults(value)}
              placeholder="Select fault(s)"
              className="max-w-none" >
              {props.latestFaults.map((fault) => (
                <MultiSelectItem key={fault} value={fault} text={fault} />
              ))}
            </MultiSelect>
          </Grid>
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Date/Time</TableHeaderCell>
                  <TableHeaderCell>Fault(s)</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.devices
                  .filter((device) => isDeviceSelected(device))
                  .filter((device) => isLatestFaultSelected(device))
                  .sort((a, b) => sortFaults(a, b))
                  .map((device) => (
                    <TableRow key={device.uniqueDeviceId}>
                      <TableCell>{device.uniqueDeviceId}</TableCell>
                      <TableCell>{device.userName}</TableCell>
                      <TableCell>{device.ctrlStatDate}</TableCell>
                      <TableCell>
                        <Badge
                          
                          icon={device.ctrlStatIcon}
                          color={device.ctrlStatColor}
                        >
                        {device.faults.join(", ")}
                        </Badge>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Card>
        </>
      ) : (
        <>
          <Grid
            numItemsSm={1}
            numItemsLg={3}
            className="gap-2"
          >
            <SelectOneDevice
              devices={props.devices}
              selected={props.selectedDevice}
              onSelectedChanged={props.onSelectedDeviceChanged}
            />
            <SelectDateRange
              range={props.selectedDateRange}
              disabled={combine(allowedMaxDays(7), afterToday())}
              onChange={props.onSelectedDateRangeChanged}
            />
            <MultiSelect
              defaultValue={props.selectedHistoryFaults}
              onValueChange={(value) => props.onSetSelectedHistoryFaults(value)}
              placeholder="Select fault(s)"
              className="max-w-none" >
              {props.historyFaults.map((fault) => (
                <MultiSelectItem key={fault} value={fault} text={fault} />
              ))}
            </MultiSelect>
          </Grid>
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3"
          >
            {(props.selectedDevice.length === 0) ? (
              <TextTremor color={colours.warning}>Select a controller</TextTremor>
            ) : (
              props.loading ? (
                <ProgressBar percentage={props.progress} />
              ) : (
                (props.logFaults.length === 0) ? (
                  <TextTremor color={colours.good}>No faults found</TextTremor>
                ) : (
                  <div>
                    <Flex justifyContent="end" alignItems="center" className="space-x-2">
                      <CsvLink
                        text="Download"
                        data={props.logFaults}
                        headers={["Date/Time", "Faults"]}
                        device={props.selectedDevice}
                        itemType="faults"
                        format={datetimeFormats.long}
                      />
                    </Flex>
                    <Table className="mt-1">
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Date/Time</TableHeaderCell>
                          <TableHeaderCell>Fault(s)</TableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.logFaults
                          .filter((record) => isHistoryFaultSelected(record))
                          .map((record) => (
                            <TableRow key={record["Date/Time"]}>
                              <TableCell>{record["Date/Time"]}</TableCell>
                              <TableCell>
                                {record.Faults.length === 1 && record.Faults[0] === "NIL" ? (
                                  <Badge
                                    icon={MdCheckCircleOutline}
                                    color={colours.good}
                                  >{record.Faults[0]}
                                  </Badge>
                                ) : (
                                  <Badge
                                    icon={MdErrorOutline}
                                    color={colours.error}
                                  >{record.Faults.join(", ")}
                                  </Badge>

                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                )
              )
            )
            }
          </Card>
        </>
      )}
    </div>
  )
    
  };

  