import {
  Text,
  Flex,
 
  Card,
  Grid,
  Bold,

} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayButtonItem,
  DisplayToggleItem,
    DisplaySliderItem,
    DisplaySelectPickerItem,
  DisplaySocketStatusItem,
} from "./DisplayItems";

import {
 
  
  MdPool as IconPool,
  MdHotTub as IconSpa,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";

import {
  GiBubbles as IconBubbles,
  GiChemicalDrop as IconChemical,
  
  GiDrippingTube as IconAcidTube,
  
} from "react-icons/gi";

import { 
  format,
  formatDuration,
  formatHex,
  formatTemperature, 
  formatORP,
  formatPH,
  formatSensorOffset,
  formatTime,
  padDigits,
  // temperatureValueToString,

} from "./RegistersHandler";


import {
  colours,
  
  offOnOptions,
//   DisableEnableOptions,
//  solarSensorLocations,
//   solarTypeOptions,
DisableEnableOptions,
spaSizeOptions, 
  poolSizeOptions,
} from "../constants";

import {

  REGS,

} from "../registers";

export const ChemicalView = props => {
    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        
        <Card
          decoration="bottom"
          decorationColor={colours.cardDecoration}
          className="mt-3">
          <div className="max-w-none mt-2 space-y-4 text-left">
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-2" >
              <Text color={colours.default}>Chemical</Text>
            </Flex>

            <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={2}
              className="gap-4"
            >
              <DisplayItem register={props.onGetRegisterData(REGS.PH_CURR)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_CURR)} icon={IconBubbles} />

              <DisplayItem register={props.onGetRegisterData(REGS.PH_LEVEL)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_LEVEL)} icon={IconBubbles} />
              <DisplayItem register={props.onGetRegisterData(REGS.PH_MEAS)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_MEAS)} icon={IconBubbles} />
              
              <DisplayToggleItem register={props.onGetRegisterData(REGS.PH_SENSOR_CONNECTED)} icon={IconChemical} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayToggleItem register={props.onGetRegisterData(REGS.ORP_SENSOR_CONNECTED)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplaySliderItem register={props.onGetRegisterData(REGS.PH_SET_POINT)} icon={IconChemical} min={50} max={90} step={2} onFormat={formatPH} onChange={props.handleRegisterUpdate} />

              <DisplaySliderItem register={props.onGetRegisterData(REGS.ORP_SET_POINT)} icon={IconBubbles} min={50} max={90} step={2} onFormat={formatORP} onChange={props.handleRegisterUpdate} />
              <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.POOL_SIZE)} icon={IconPool} options={poolSizeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.SPA_SIZE)} icon={IconSpa} options={spaSizeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayItem register={props.onGetRegisterData(REGS.ACID_PUMP_LAST_RUN_TIME)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.CHLORINE_PUMP_LAST_RUN_TIME)} icon={IconBubbles} />

              <DisplayToggleItem register={props.onGetRegisterData(REGS.OVERRIDE_SENSOR_SETTLING)} icon={IconChemical} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplaySliderItem register={props.onGetRegisterData(REGS.PH_SENSOR_OFFSET)} icon={IconChemical} min={-30} max={30} step={2} onFormat={formatPH} onChange={props.handleRegisterUpdate} />

              <DisplaySliderItem register={props.onGetRegisterData(REGS.ORP_SENSOR_OFFSET)} icon={IconBubbles} min={-30} max={30} step={2} onFormat={formatORP} onChange={props.handleRegisterUpdate} />
              <DisplayItem register={props.onGetRegisterData(REGS.DOSING_OPTION)} icon={IconBubbles} />
              <DisplayButtonItem register={props.onGetRegisterData(REGS.PRIME_DOSING_PUMP)} iconOff={IconAcidTube} iconOn={IconAcidTube} onChange={props.handleRegisterUpdate} />
            </Grid>

          </div>
        </Card>
      </Grid>
    )
}