


import {
  heaterOffOnOptions, 
  offOnOptions,
  heatingPumpTypes,
  solarSensorLocations,
  pumpModelOptionsSelect
} from "./constants";

export const readAllMessage = {
  messageId: "read",
  modbusReg: 1,
  modbusVal: [1]
};



export const REGS =
{
  ALL								:     1,
  DEVICE_ID:              0x0010,
  HARDWARE_VERSION:       0x0011,
  FIRMWARE_VERSION:       0x0012,
  FIRMWARE_BUILDS:        0x0013,
  IOCTRL_VERSION:         0x0014,
  IOCTRL_BUILD:           0x0015,
  EXP1_VERSION:           0x0016,
  EXP1_BUILD:             0x0017,
  EXP2_VERSION:           0x0018,
  EXP2_BUILD:             0x0019,
  EXPANSION_PLUS_VERSION: 0x001A,
  EXPANSION_PLUS_BUILD:   0x001B,

  FAULT_FLAGS:            0x0030,
  FAULT_FLAGS_2:          0x0031,
  AMBIENT_TEMPERATURE:    0x0032,
  MODEL:                  0x0033,
  ROOF_TEMPERATURE:           54,
  POOL_TEMPERATURE:           55,
  ROOF_TEMP_LAST_PUMP_RUN:    56,
  POOL_TEMP_LAST_PUMP_RUN:    57,

  TOTAL_HOURS:            0x0040,

  SOLAR_STATE: 0x004B,
  SOLAR_INIT_TEMP: 0x004C,

  INPUTS_STATUS: 0x004D,
  OUTPUTS_STATUS: 0x004E,
  THERMISTOR_1: 0x004F,
  THERMISTOR_2: 0x0050,

  HEATER_STATE: 0x0051,

  SOLAR_WINTER_NEXT_MINS: 0x0052,

  PUMP_STATE: 92,
  PUMP_TRIGGER: 93,
  PUMP_LAST_RUN_TIME: 94,

  // 	 WIFI_SSID_START 					= 0x0064: 1,
  // 	 WIFI_SSID_END                		= ( WIFI_SSID_START+15): 1,		// (SSID max length = 32 bytes => 16 registers)

  // 	 WIFI_PASSWORD_START: 1,
  // 	 WIFI_PASSWORD_END					= ( WIFI_PASSWORD_START+31): 1,	// (Password max length = 64 bytes => 32 registers)

  // 	 WIFI_STATUS						= 0x0094: 1,
  // 	 WIFI_RSSI: 1,
  // 	 WIFI_CHANNEL: 1,

  // 	 AWS_CERTID_START: 1,
  // 	 AWS_CERTID_END						= ( AWS_CERTID_START+2): 1,		// (5bytes value -> 40bits => 3 registers)

  // 	 FWUPDATE_ERROR: 1,

  // 	 EXPANSION_PLUS_FWUPDATE_ERROR: 1,
  // 	 EXPANSION_PLUS_WIFI_RSSI: 1,

  SOCKET_1_STATUS: 0x00A0,
  SOCKET_2_STATUS: 0x00A1,
  SOCKET_3_STATUS: 0x00A2,
  SOCKET_4_STATUS: 0x00A3,
  SOCKET_5_STATUS: 0x00A4,
  SOCKET_6_STATUS: 0x00A5,
  SOCKET_7_STATUS: 0x00A6,
  SOCKET_8_STATUS: 0x00A7,
  VALVE_1_STATUS: 0x00A8,
  VALVE_2_STATUS: 0x00A9,
  VALVE_3_STATUS: 0x00AA,
  VALVE_4_STATUS: 0x00AB,
  VF_CONTACT_1_STATUS: 0x00AC,

  PH_MEAS: 0x00AD,
  ORP_MEAS: 0x00AE,

  ACID_PUMP_LAST_RUN_TIME: 0x00AF,
  CHLORINE_PUMP_LAST_RUN_TIME: 0x00B0,

  THERMISTOR_3: 177,

  ACID_DOSING_STATUS: 178,
  CHLORINE_DOSING_STATUS: 179,

  PH_LEVEL: 180,
  ORP_LEVEL: 181,

  PH_CURR: 182,
  ORP_CURR: 183,

  HEATER_2_STATE: 184,
  VF_CONTACT_2_STATUS: 185,
  WATER_TEMPERATURE: 186,
  SPA_ON_TIMER: 188,
  // 	 Last								=  SPA_ON_TIMER: 1,

  // 	 MAX								= 0x0100: 1,

  // 	/* Start new write-enable register range */
  // 	 RECLAIM_BASE						= 40960: 1,	// 0xA000
  // 	 RECLAIM_First						=  RECLAIM_BASE: 1,
  // 	/* Start new write-enable register range */
  LOGGING_INTERVAL					: 40993,
  LOGGING_REMOTE					: 65290,

  // 	 RECLAIM_Last						=  LOGGING_REMOTE: 1,

  // 	PSW_REG_WRITE2_RECLAIM_MAX						= 45055: 1,	// 0xAFFF

  // 	/* Start new write-enable register range */
  // 	 BASE2								= 0xE000: 1,
  // 	 ENABLE2_First						=  BASE2: 1,

  APPLIANCE1_NAME_START	:0xE000,
  APPLIANCE1_NAME_END	  :0xE007,
  APPLIANCE2_NAME_START	:0xE008,
  APPLIANCE2_NAME_END	  :0xE00F,
  APPLIANCE3_NAME_START	:0xE010,
  APPLIANCE3_NAME_END	  :0xE017,
  APPLIANCE4_NAME_START	:0xE018,
  APPLIANCE4_NAME_END	  :0xE01F,
  APPLIANCE5_NAME_START	:0xE020,
  APPLIANCE5_NAME_END	  :0xE027,
  APPLIANCE6_NAME_START	:0xE028,
  APPLIANCE6_NAME_END	  :0xE02F,
  APPLIANCE7_NAME_START	:0xE030,
  APPLIANCE7_NAME_END	  :0xE037,

  APPLIANCE8_NAME_START	:0xE038,
  APPLIANCE8_NAME_END	  :0xE03f,
  APPLIANCE9_NAME_START	:0xE040,
  APPLIANCE9_NAME_END	  :0xE047,
  APPLIANCE10_NAME_START	:0xE048,
  APPLIANCE10_NAME_END	  :0xE04F,
  APPLIANCE11_NAME_START	:0xE050,
  APPLIANCE11_NAME_END	  :0xE057,
  APPLIANCE12_NAME_START	:0xE058,
  APPLIANCE12_NAME_END	  :0xE05F,
  APPLIANCE13_NAME_START	:0xE060,
  APPLIANCE13_NAME_END	  :0xE067,

  SOCKET_1_LINK_INDEX: 0xE068,
  SOCKET_2_LINK_INDEX: 0xE069,
  SOCKET_3_LINK_INDEX: 0xE06A,
  SOCKET_4_LINK_INDEX: 0xE06B,
  SOCKET_5_LINK_INDEX: 0xE06C,
  SOCKET_6_LINK_INDEX: 0xE06D,
  SOCKET_7_LINK_INDEX: 0xE06E,
  SOCKET_8_LINK_INDEX: 0xE06F,
  VALVE_1_LINK_INDEX: 0xE070,
  VALVE_2_LINK_INDEX: 0xE071,
  VALVE_3_LINK_INDEX: 0xE072,
  VALVE_4_LINK_INDEX: 0xE073,
  VFCONTACT_1_LINK_INDEX: 0xE074,

  WIFI_BLE_ENABLE:      0xE075,

  APPLIANCE14_NAME_START	:0xE076,
  APPLIANCE14_NAME_END	  :0xE07D,
  APPLIANCE15_NAME_START	:0xE07E,
  APPLIANCE15_NAME_END	  :0xE085,
  APPLIANCE16_NAME_START	:0xE086,
  APPLIANCE16_NAME_END	  :0xE08D,
  APPLIANCE17_NAME_START	:0xE08E,
  APPLIANCE17_NAME_END	  :0xE095,
  APPLIANCE18_NAME_START	:0xE096,
  APPLIANCE18_NAME_END	  :0xE09D,
  APPLIANCE19_NAME_START	:0xE09E,
  APPLIANCE19_NAME_END	  :0xE0A5,

  VFCONTACT_2_TYPE: 57510,
  // 	 EXP_1_TYPE: 57511,
  // 	 EXP_2_TYPE: 57512,
  // 	 EXP_3_TYPE: 57513,
  // 	 EXP_4_TYPE: 57514,
  // 	 EXP_5_TYPE: 57515,
  // 	 EXP_6_TYPE: 57516,

  VFCONTACT_2_MODE: 57517,
  // 	 EXP_1_MODE: 57518,
  // 	 EXP_2_MODE: 57519,
  // 	 EXP_3_MODE: 57520,
  // 	 EXP_4_MODE: 57521,
  // 	 EXP_5_MODE: 57522,
  // 	 EXP_6_MODE: 57523,

  VFCONTACT_2_SETTING: 57524,
  // 	 EXP_1_SETTING: 57525,
  // 	 EXP_2_SETTING: 57526,
  // 	 EXP_3_SETTING: 57527,
  // 	 EXP_4_SETTING: 57528,
  // 	 EXP_5_SETTING: 57529,
  // 	 EXP_6_SETTING: 57530,

  VFCONTACT_2_TIME_ENABLE: 57531,
  // 	 EXP_1_TIME_ENABLE: 57532,
  // 	 EXP_2_TIME_ENABLE: 57533,
  // 	 EXP_3_TIME_ENABLE: 57534,
  // 	 EXP_4_TIME_ENABLE: 57535,
  // 	 EXP_5_TIME_ENABLE: 57536,
  // 	 EXP_6_TIME_ENABLE: 57537,

  VFCONTACT_2_TIME_1_START_TIME: 57538,
  // 	 EXP_1_TIME_1_START_TIME: 57539,
  // 	 EXP_2_TIME_1_START_TIME: 57540,
  // 	 EXP_3_TIME_1_START_TIME: 57541,
  // 	 EXP_4_TIME_1_START_TIME: 57542,
  // 	 EXP_5_TIME_1_START_TIME: 57543,
  // 	 EXP_6_TIME_1_START_TIME: 57544,

  VFCONTACT_2_TIME_1_STOP_TIME: 57545,
  // 	 EXP_1_TIME_1_STOP_TIME: 57546,
  // 	 EXP_2_TIME_1_STOP_TIME: 57547,
  // 	 EXP_3_TIME_1_STOP_TIME: 57548,
  // 	 EXP_4_TIME_1_STOP_TIME: 57549,
  // 	 EXP_5_TIME_1_STOP_TIME: 57550,
  // 	 EXP_6_TIME_1_STOP_TIME: 57551,
  VFCONTACT_2_TIME_2_START_TIME: 57552,
  // 	 EXP_1_TIME_2_START_TIME: 57553,
  // 	 EXP_2_TIME_2_START_TIME: 57554,
  // 	 EXP_3_TIME_2_START_TIME: 57555,
  // 	 EXP_4_TIME_2_START_TIME: 57556,
  // 	 EXP_5_TIME_2_START_TIME: 57557,
  // 	 EXP_6_TIME_2_START_TIME: 57558,
  VFCONTACT_2_TIME_2_STOP_TIME: 57559,
  // 	 EXP_1_TIME_2_STOP_TIME: 57560,
  // 	 EXP_2_TIME_2_STOP_TIME: 57561,
  // 	 EXP_3_TIME_2_STOP_TIME: 57562,
  // 	 EXP_4_TIME_2_STOP_TIME: 57563,
  // 	 EXP_5_TIME_2_STOP_TIME: 57564,
  // 	 EXP_6_TIME_2_STOP_TIME: 57565,

  HEAT_PUMP_2_ENABLE: 57566,
  GAS_HEATER_2_ENABLE: 57567,
  GAS_HEATER_2_COOL_DOWN_TIME: 57568,
  CHILL_2_FUNCTION: 57569,
  HEAT_DEMAND_SANITISER_2_ENABLE: 57570,
  HEAT_2_SPEED: 57571,
  HEATER_2_SOLAR_PRIORITY: 57572,
  HEATER_2_ROOFTEMP_CUTOFF: 57573,
  HEATER_2_PUMP_TYPE: 57574,
  POOL_2_SET_TEMPERATURE: 57575,
  SPA_2_SET_TEMPERATURE: 57576,
  RUN_TILL_HEATED_2_FUNC: 57577,
  HEATER_2_TRACKING_ENABLE: 57578,
  HEATER_2_TRACKING_OFFSET: 57579,
  THERMISTOR_3_TYPE: 57580,
  THERMISTOR_3_OFFSET: 57581,
  HEATER_USES_WATER: 57582,
  HEATER_2_USES_WATER: 57583,
  SOLAR_RUN_FROM_HEATER_PUMP: 57584,

  HEATER_EXPANSION_PRESENT: 57585,
  HEATER_HYDROTHERAPY_MODE: 57586,
  HEATER_2_HYDROTHERAPY_MODE: 57587,
  ANTIFREEZE_ACTIVE: 57588,
  ANTIFREEZE_TEMPERATURE: 57589,

  POOL_NAME_START: 57590,
  POOL_NAME_END:    57597,

  SPA_NAME_START: 57598,
  SPA_NAME_END:   57605,

  SPA_TIMER_2_ENABLE: 57606,
  SPA_TIMER_2_START_TIME: 57607,
  SPA_TIMER_2_STOP_TIME: 57608,

  SOLAR_USES_WATER: 57609,

  LIGHT_1_REINIT: 57610,
  LIGHT_2_REINIT: 57611,
  LIGHT_3_REINIT: 57612,

  // 	/* new run once enabled*/
  // 	 SOCKET_1_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_2_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_3_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_4_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_5_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_6_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_7_RUN_ONCE_ENABLE: 1,
  // 	 SOCKET_8_RUN_ONCE_ENABLE: 1,
  // 	 VALVE_1_RUN_ONCE_ENABLE: 1,
  // 	 VALVE_2_RUN_ONCE_ENABLE: 1,
  // 	 VALVE_3_RUN_ONCE_ENABLE: 1,
  // 	 VALVE_4_RUN_ONCE_ENABLE: 1,
  // 	 VFCONTACT_1_RUN_ONCE_ENABLE: 1,
  // 	 VFCONTACT_2_RUN_ONCE_ENABLE: 1,
  // 	 EXP_2_RUN_ONCE_ENABLE: 1,
  // 	 EXP_3_RUN_ONCE_ENABLE: 1,
  // 	 EXP_4_RUN_ONCE_ENABLE: 1,
  // 	 EXP_5_RUN_ONCE_ENABLE: 1,
  // 	 SOLAR_RUN_ONCE_ENABLE: 1,
  // 	 SPA_RUN_ONCE_ENABLE: 1,

  // 	 SOCKET_1_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_2_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_3_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_4_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_5_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_6_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_7_RUN_ONCE_START_TIME: 1,
  // 	 SOCKET_8_RUN_ONCE_START_TIME: 1,
  // 	 VALVE_1_RUN_ONCE_START_TIME: 1,
  // 	 VALVE_2_RUN_ONCE_START_TIME: 1,
  // 	 VALVE_3_RUN_ONCE_START_TIME: 1,
  // 	 VALVE_4_RUN_ONCE_START_TIME: 1,
  // 	 VFCONTACT_1_RUN_ONCE_START_TIME: 1,
  // 	 VFCONTACT_2_RUN_ONCE_START_TIME: 1,
  // 	 EXP_2_RUN_ONCE_START_TIME: 1,
  // 	 EXP_3_RUN_ONCE_START_TIME: 1,
  // 	 EXP_4_RUN_ONCE_START_TIME: 1,
  // 	 EXP_5_RUN_ONCE_START_TIME: 1,
  // 	 SOLAR_RUN_ONCE_START_TIME: 1,
  // 	 SPA_RUN_ONCE_START_TIME: 1,

  // 	 SOCKET_1_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_2_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_3_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_4_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_5_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_6_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_7_RUN_ONCE_STOP_TIME: 1,
  // 	 SOCKET_8_RUN_ONCE_STOP_TIME: 1,
  // 	 VALVE_1_RUN_ONCE_STOP_TIME: 1,
  // 	 VALVE_2_RUN_ONCE_STOP_TIME: 1,
  // 	 VALVE_3_RUN_ONCE_STOP_TIME: 1,
  // 	 VALVE_4_RUN_ONCE_STOP_TIME: 1,
  // 	 VFCONTACT_1_RUN_ONCE_STOP_TIME: 1,
  // 	 VFCONTACT_2_RUN_ONCE_STOP_TIME: 1,
  // 	 EXP_2_RUN_ONCE_STOP_TIME: 1,
  // 	 EXP_3_RUN_ONCE_STOP_TIME: 1,
  // 	 EXP_4_RUN_ONCE_STOP_TIME: 1,
  // 	 EXP_5_RUN_ONCE_STOP_TIME: 1,
  // 	 SOLAR_RUN_ONCE_STOP_TIME: 1,
  // 	 SPA_RUN_ONCE_STOP_TIME: 1,

  PSW_REG_BLOWER_TIMEOUT_ENABLED: 57673,
  PSW_REG_SOLAR_FORCE_RUN_ON: 57674,
  SOLAR_TURN_ON_DIFFERENCE: 57675,
  SOLAR_TURN_OFF_DIFFERENCE: 57676,
  // 	 ENABLE2_Last						= 0xFEFF: 1,
  // 	/* End new write-enable register range */

  // 	 BASE								= 0xFF00: 1,
  // 	 FWUPDATE							=  BASE: 1,
  
  // 	 PRIVATE_First
  RESET_OPTIONS:          0xFF01,
  ENABLE_UUID:            0xFF02,
  OTA_AUTH:               0xFF03,
  OTA_HTTPS_ONLY:         0xFF04,
  LCD_CONTRAST:           0xFF05,
  TEST_OUTPUT:            0xFF06,
  TEST_420MA:             0xFF07,
  TEST_DISPLAY:           0xFF08,
  DEVICE_PASSCODE:        0xFF09,
  LOGGING_ENABLE:         0xFF0A,
  FILTER_TIMEn_ENABLE:    0xFF0B,
  FWUPDATE_EXPANSION_PLUS: 0xFF0C,
  SET_MODEL:              0xFF0D,
  BOOTUP_COUNT:           0xFF0E,
  RESTART_COUNT:          0xFF0F,
  // PRIVATE_Last				

  RTC_YEAR: 65296,
  RTC_MONTH: 65297,
  RTC_DAY: 65298,
  RTC_HOUR: 65299,
  RTC_MIN: 65300,

  TIMEZONE: 65301,
  AUTO_SET_TIME: 65302,
  LOCATION: 65303,
  ADJUST_DST: 65304,
  RTC_SEC: 65305,

  OPERATING_MODE: 65312,
  SPA_MODE: 65313,

  THERMISTOR_1_TYPE: 65314,
  THERMISTOR_2_TYPE: 65315,
  THERMISTOR_1_OFFSET: 65316,
  THERMISTOR_2_OFFSET: 65317,

  FILTER_TIME_ENABLE: 65318,
  FILTER_TIME_1_START_TIME: 65319,
  FILTER_TIME_1_STOP_TIME: 65320,
  FILTER_TIME_2_START_TIME: 65321,
  FILTER_TIME_2_STOP_TIME: 65322,

  SOCKET_1_TYPE: 65323,
  SOCKET_2_TYPE: 65324,
  SOCKET_3_TYPE: 65325,
  SOCKET_4_TYPE: 65326,
  SOCKET_5_TYPE: 65327,
  SOCKET_6_TYPE: 65328,
  SOCKET_7_TYPE: 65329,
  SOCKET_8_TYPE: 65330,
  VALVE_1_TYPE: 65331,
  VALVE_2_TYPE: 65332,
  VALVE_3_TYPE: 65333,
  VALVE_4_TYPE: 65334,
  VFCONTACT_1_TYPE: 65335,

  SOCKET_1_MODE:    65336,
  SOCKET_2_MODE:    65337,
  SOCKET_3_MODE:    65338,
  SOCKET_4_MODE: 65339,
  SOCKET_5_MODE: 65340,
  SOCKET_6_MODE: 65341,
  SOCKET_7_MODE: 65342,
  SOCKET_8_MODE: 65343,
  VALVE_1_MODE: 65344,
  VALVE_2_MODE: 65345,
  VALVE_3_MODE: 65346,
  VALVE_4_MODE: 65347,
  VFCONTACT_1_MODE: 65348,

  SOCKET_1_SETTING: 65349,
  SOCKET_2_SETTING: 65350,
  SOCKET_3_SETTING: 65351,
  SOCKET_4_SETTING: 65352,
  SOCKET_5_SETTING: 65353,
  SOCKET_6_SETTING: 65354,
  SOCKET_7_SETTING: 65355,
  SOCKET_8_SETTING: 65356,
  VALVE_1_SETTING:  65357,
  VALVE_2_SETTING:  65358,
  VALVE_3_SETTING:  65359,
  VALVE_4_SETTING:  65360,
  VFCONTACT_1_SETTING: 65361,

  SOCKET_1_TIME_ENABLE:       65362,
  SOCKET_2_TIME_ENABLE:       65363,
  SOCKET_3_TIME_ENABLE:       65364,
  SOCKET_4_TIME_ENABLE:       65365,
  SOCKET_5_TIME_ENABLE:       65366,
  SOCKET_6_TIME_ENABLE:       65367,
  SOCKET_7_TIME_ENABLE:       65368,
  SOCKET_8_TIME_ENABLE:       65369,
  VALVE_1_TIME_ENABLE:        65370,
  VALVE_2_TIME_ENABLE:        65371,
  VALVE_3_TIME_ENABLE:        65372,
  VALVE_4_TIME_ENABLE:        65373,
  VFCONTACT_1_TIME_ENABLE:    65374,

  SOCKET_1_TIME_1_START_TIME: 65375,
  SOCKET_2_TIME_1_START_TIME: 65376,
  SOCKET_3_TIME_1_START_TIME: 65377,
  SOCKET_4_TIME_1_START_TIME: 65378,
  SOCKET_5_TIME_1_START_TIME: 65379,
  SOCKET_6_TIME_1_START_TIME: 65380,
  SOCKET_7_TIME_1_START_TIME: 65381,
  SOCKET_8_TIME_1_START_TIME: 65382,
  VALVE_1_TIME_1_START_TIME:  65383,
  VALVE_2_TIME_1_START_TIME:  65384,
  VALVE_3_TIME_1_START_TIME:  65385,
  VALVE_4_TIME_1_START_TIME:  65386,
  VFCONTACT_1_TIME_1_START_TIME: 65387,

  SOCKET_1_TIME_1_STOP_TIME:  65388,
  SOCKET_2_TIME_1_STOP_TIME:  65389,
  SOCKET_3_TIME_1_STOP_TIME:  65390,
  SOCKET_4_TIME_1_STOP_TIME:  65391,
  SOCKET_5_TIME_1_STOP_TIME:  65392,
  SOCKET_6_TIME_1_STOP_TIME:  65393,
  SOCKET_7_TIME_1_STOP_TIME:  65394,
  SOCKET_8_TIME_1_STOP_TIME:  65395,
  VALVE_1_TIME_1_STOP_TIME:   65396,
  VALVE_2_TIME_1_STOP_TIME:   65397,
  VALVE_3_TIME_1_STOP_TIME:   65398,
  VALVE_4_TIME_1_STOP_TIME:   65399,
  VFCONTACT_1_TIME_1_STOP_TIME: 65400,

  SOCKET_1_TIME_2_START_TIME: 65401,
  SOCKET_2_TIME_2_START_TIME: 65402,
  SOCKET_3_TIME_2_START_TIME: 65403,
  SOCKET_4_TIME_2_START_TIME: 65404,
  SOCKET_5_TIME_2_START_TIME: 65405,
  SOCKET_6_TIME_2_START_TIME: 65406,
  SOCKET_7_TIME_2_START_TIME: 65407,
  SOCKET_8_TIME_2_START_TIME: 65408,
  VALVE_1_TIME_2_START_TIME:  65409,
  VALVE_2_TIME_2_START_TIME:  65410,
  VALVE_3_TIME_2_START_TIME:  65411,
  VALVE_4_TIME_2_START_TIME:  65412,
  VFCONTACT_1_TIME_2_START_TIME: 65413,

  SOCKET_1_TIME_2_STOP_TIME:  65414,
  SOCKET_2_TIME_2_STOP_TIME:  65415,
  SOCKET_3_TIME_2_STOP_TIME:  65416,
  SOCKET_4_TIME_2_STOP_TIME:  65417,
  SOCKET_5_TIME_2_STOP_TIME:  65418,
  SOCKET_6_TIME_2_STOP_TIME:  65419,
  SOCKET_7_TIME_2_STOP_TIME:  65420,
  SOCKET_8_TIME_2_STOP_TIME:  65421,
  VALVE_1_TIME_2_STOP_TIME:   65422,
  VALVE_2_TIME_2_STOP_TIME:   65423,
  VALVE_3_TIME_2_STOP_TIME:   65424,
  VALVE_4_TIME_2_STOP_TIME:   65425,
  VFCONTACT_1_TIME_2_STOP_TIME: 65426,

  LIGHTS_1_SYNC:              65427,
  LIGHTS_2_SYNC:              65428,

  PUMP_ENABLE:          65429,
  PUMP_SPEED_TYPE:      65430,
  PUMP_MODEL:           65431,
  PUMP_SPEED_1:         65432,
  PUMP_SPEED_2:         65433,
  PUMP_SPEED_3:         65434,
  PUMP_SPEED_4:         65435,

  SOLAR_MODE:           65436,
  SOLAR_TYPE:           65437,
  SOLAR_SET_TEMPERATURE: 65438,
  SOLAR_SPEED: 65439,
  SOLAR_START_TIME: 65440,

  SPA_SET_TEMPERATURE: 65441,
  SPA_BLOWER_ENABLE: 65442,
  SPA_LIMIT_OFFSET: 65443,
  SPA_CHLORINATOR_ENABLE: 65444,
  SPA_CHLORINATOR_DUTY_CYCLE: 65445,
  SPA_LIGHT: 65446,

  POOL_SET_TEMPERATURE: 65447,
  PUMP_CALL_ENABLE: 65448,
  HEAT_PUMP_ENABLE: 65449,
  GAS_HEATER_ENABLE: 65450,
  GAS_HEATER_COOL_DOWN_TIME: 65451,

  WATER_FEATURE_TYPE: 65452,

  SLAVE_1_ENABLE: 65453,
  SLAVE_2_ENABLE: 65454,
  SLAVE_1_ADDRESS: 65455,
  SLAVE_2_ADDRESS: 65456,
  SLAVE_1_CONFIGURE: 65457,
  SLAVE_2_CONFIGURE: 65458,
  SPA_SPEED: 65459,
  SPA_JETPUMP_ENABLE: 65460,

  WATER_FEATURE_SPEED: 65461,
  HEATING_SPEED: 65462,
  PUMP_DEFAULT_SPEED: 65463,
  PUMP_VSP_MODE: 65464,
  HEAT_DEMAND_ENABLE: 65465,
  HEAT_DEMAND_START_TIME: 65466,
  HEAT_DEMAND_STOP_TIME: 65467,

  LIGHTS_3_SYNC: 65468,
  FILTER_TIME_3_START_TIME: 65469,
  FILTER_TIME_3_STOP_TIME: 65470,
  FILTER_TIME_4_START_TIME: 65471,
  FILTER_TIME_4_STOP_TIME: 65472,

  FILTER_TIME_1_SPEED: 65473,
  FILTER_TIME_2_SPEED: 65474,
  FILTER_TIME_3_SPEED: 65475,
  FILTER_TIME_4_SPEED: 65476,

  HEATING_PUMP_CALL_SPEED: 65477,
  PUMP_SPEED_PERCENTAGE_1: 65478,
  PUMP_SPEED_PERCENTAGE_2: 65479,
  PUMP_SPEED_PERCENTAGE_3: 65480,
  PUMP_SPEED_PERCENTAGE_4: 65481,

  SPA_ENABLE: 65482,

  PUMP_MANUAL_ON_TIME: 65483,
  PUMP_MANUAL_OFF_TIME: 65484,
  PUMP_MANUAL_ON_OFF: 65485,

  SPA_LIMIT_OFFSET_ENABLE: 65486,

  INSTALLER_MENU_CODE: 65487,

  DEVICE_NAME_START: 65488,
  DEVICE_NAME_END:  	65495,

  SOLAR_END_TIME: 65496,
  SPA_SLEEP_TIMER: 65497,
  SOLAR_SENSOR_LOCATION: 65498,
  HEATING_PUMP_TYPE: 65499,
  RUN_TILL_HEATED_FUNC: 65500,
  HEAT_DEMAND_SANITISER_ENABLE: 65501,

  ORP_SET_POINT:              65502,
  PH_SET_POINT:               65503,
  SLAVE_3_ENABLE:             65504,
  SOLAR_WINTER_MODE:          65505,
  HEATER_ROOFTEMP_CUTOFF:     65506,
  HEATER_SOLAR_PRIORITY:      65507,
  PH_SENSOR_CONNECTED:        65508,
  ORP_SENSOR_CONNECTED:       65509,

  POOL_SIZE:                  65510,
  SPA_SIZE:                   65511,

  OVERRIDE_SENSOR_SETTLING:   65512,

  PH_SENSOR_OFFSET:           65513,
  ORP_SENSOR_OFFSET:          65514,

  DOSING_OPTION:              65515,
  PRIME_DOSING_PUMP:          65516,

  SPA_TIMER_ENABLE:           65517,
  SPA_TIMER_START_TIME:       65518,
  SPA_TIMER_STOP_TIME:        65519,

  PH_SET_POINT_INSTALLER:     65520,
  ORP_SET_POINT_INSTALLER:    65521,

  LIGHTS_LINKED:              65522,

  HEATER_CHILL_FUNC:          65523,
  PUMP_CALL_RUN_ON_TIME:      65524,
  PUMP_CALL_ENABLED_ITEMS:    65525,
 
}

export const regType = {
  temperature: 0,
  time: 1,
  duration: 2,
  other: 3,
  name: 4,
  off_on: 5,
  disable_enable:6 ,
  pump_speed: 7,
  lookup: 8,
};

export const registersInit = [
  { address: REGS.TOTAL_HOURS,          		type: regType.other,            title: "Uptime",              unit: "hours",          value: 0, 					display: "" },
  { address: REGS.ROOF_TEMPERATURE,     		type: regType.temperature,      title: "Roof Temperature",    unit: "\u00B0C",        value: 0, 					display: "" },
  { address: REGS.POOL_TEMPERATURE,     		type: regType.temperature,      title: "Pool Temperature",    unit: "\u00B0C",        value: 0, 					display: "" },
  { address: REGS.SPA_MODE,             		type: regType.other,            title: "POOL/SPA Mode",       unit: "",               value: 0, 					display: "" },
  { address: REGS.WATER_TEMPERATURE,    		type: regType.temperature,      title: "Water Temperature",   unit: "\u00B0C",        value: 0, 					display: "" },
  { address: REGS.AMBIENT_TEMPERATURE,  		type: regType.temperature,      title: "Ambient Temperature", unit: "\u00B0C",        value: 0, 					display: "" },
  { address: REGS.SOLAR_MODE,           		type: regType.other,            title: "Solar Mode",          unit: "",               value: 0, 					display: "" },
  { address: REGS.SOLAR_TYPE, 			    		type: regType.other, 			      title: "Solar Type",          unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_SPEED, 			    		type: regType.pump_speed, 			title: "Solar Speed",         unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_START_TIME, 		    type: regType.time, 			      title: "Solar Start Time", 		unit: "", 						  value: 0, 					display: "" },
  { address: REGS.SPA_BLOWER_ENABLE, 			  type: regType.disable_enable,   title: "SPA Blower Enable",   unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_ENABLE, 			        type: regType.disable_enable,   title: "SPA Enable",          unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_LIMIT_OFFSET,		      type: regType.temperature, 	    title: "SPA Limit",           unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.SPA_LIMIT_OFFSET_ENABLE, 	type: regType.disable_enable,   title: "At Temperature",      unit: "", 							value: 0, 					display: "" },
  { address: REGS.POOL_SIZE, 	              type: regType.other, 			      title: "Pool Size",           unit: "L", 							value: 0, 					display: "" },
  { address: REGS.SPA_SIZE, 	              type: regType.other, 			      title: "SPA Size",            unit: "L", 							value: 0, 					display: "" },

  { address: REGS.SPA_CHLORINATOR_ENABLE, 	type: regType.disable_enable,   title: "Sanitiser",           unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_CHLORINATOR_DUTY_CYCLE,type: regType.other, 			    title: "Duty Cycle",          unit: "%", 							value: 0, 					display: "" },
  { address: REGS.SPA_LIGHT, 	              type: regType.other, 			      title: "SPA Light",           unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_ON_TIMER, 	          type: regType.other, 			      title: "SPA Timer",           unit: "", 							value: 0, 					display: "" },
  { address: REGS.LOGGING_INTERVAL, 	      type: regType.other, 			      title: "Logging Interval",    unit: "", 							value: 0, 					display: "" },
  { address: REGS.LOGGING_REMOTE, 	        type: regType.disable_enable,   title: "Logging Enabled",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_ROOFTEMP_CUTOFF, 	type: regType.temperature, 			title: "Heater Roof Cut-Off", unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.HEATER_SOLAR_PRIORITY, 	  type: regType.off_on, 			      title: "Heater Solar Priority",unit: "", 							value: 0, 					display: "" },

  { address: REGS.SOLAR_SET_TEMPERATURE,		type: regType.temperature, 	    title: "Solar Set Point",     unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.SPA_SET_TEMPERATURE, 			type: regType.temperature, 	    title: "SPA Set Temperature", unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.POOL_SET_TEMPERATURE, 		type: regType.temperature, 			title: "Pool Set Temperature",unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.PH_SET_POINT, 			  		type: regType.other, 			  		title: "pH Set Point",        unit: "", 							value: 0, 					display: "" },
  { address: REGS.ORP_SET_POINT, 			  		type: regType.other, 			  		title: "ORP Set Point", 			unit: "mV", 						value: 0, 					display: "" },
  { address: REGS.ORP_CURR, 			      		type: regType.other, 			  		title: "ORP RealTime", 			  unit: "mV", 						value: 0, 					display: "" },
  { address: REGS.ORP_LEVEL, 			      		type: regType.other, 			  		title: "ORP Level", 			    unit: "mV", 						value: 0, 					display: "" },
  { address: REGS.ORP_MEAS, 			      		type: regType.other, 			  		title: "ORP MEas", 			      unit: "mV", 						value: 0, 					display: "" },
  { address: REGS.PUMP_LAST_RUN_TIME, 			type: regType.time, 			    	title: "Pump Last Run", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.ACID_PUMP_LAST_RUN_TIME, 	type: regType.time, 					  title: "ACID Last Run", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.CHLORINE_PUMP_LAST_RUN_TIME, 		type: regType.time, 			title: "ORP last Run", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PH_CURR, 			        		type: regType.other, 			  		title: "pH RealTime", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PH_LEVEL, 			      		type: regType.other, 			  		title: "pH Level", 			      unit: "", 							value: 0, 					display: "" },
  { address: REGS.PH_MEAS, 			        		type: regType.other, 			  		title: "pH Meas", 			      unit: "", 							value: 0, 					display: "" },
  { address: REGS.HARDWARE_VERSION, 				type: regType.other, 			  		title: "HW:", 			          unit: "", 							value: 0, 					display: "" },
  { address: REGS.FIRMWARE_VERSION, 				type: regType.other, 			  		title: "Firmware:", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.FIRMWARE_BUILDS, 					type: regType.other, 			  		title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.IOCTRL_VERSION, 					type: regType.other, 			  		title: "IO Ctrl:",            unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXP1_VERSION, 			  		type: regType.other, 			  		title: "Exp 1:", 			        unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXP1_BUILD, 			    		type: regType.other, 			  		title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXP2_VERSION, 			  		type: regType.other, 			      title: "Exp 2:", 			        unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXP2_BUILD, 			    		type: regType.other, 			      title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXPANSION_PLUS_VERSION, 	type: regType.other, 			      title: "AquaChem:", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.EXPANSION_PLUS_BUILD, 		type: regType.other, 			      title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.FAULT_FLAGS, 			    		type: regType.other, 			      title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.MODEL, 			          		type: regType.other, 						title: "", 			              unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_STATE, 			    		type: regType.other, 						title: "Pump State", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_ENABLE, 			    		type: regType.other, 						title: "Pump Enable", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_TYPE, 					type: regType.off_on, 					title: "Pump Multi-Speed", 		unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_MODEL, 			    		type: regType.lookup, 					title: "Pump model", 			    unit: "", 							value: 0, 					display: ""  , lookup_table:pumpModelOptionsSelect },
  { address: REGS.PUMP_SPEED_1, 			  		type: regType.other, 						title: "Pump Speed 1", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_2, 			  		type: regType.other, 						title: "Pump Speed 1", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_3, 			  		type: regType.other, 						title: "Pump Speed 1", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_4, 			  		type: regType.other, 						title: "Pump Speed 1", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_STATE, 			    		type: regType.other, 						title: "Solar State", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_INIT_TEMP, 					type: regType.temperature, 			title: "Solar init Temp", 		unit: "\u00B0C", 				value: 0,           display: ""},
  { address: REGS.TOTAL_HOURS, 			    		type: regType.other, 						title: "Uptime:", 			      unit: "hrs", 						value: 0, 					display: "" },
  { address: REGS.INPUTS_STATUS, 			  		type: regType.other, 						title: "Inputs:", 						unit: "", 							value: 0, 					display: "" },
  { address: REGS.OUTPUTS_STATUS, 					type: regType.other, 						title: "Outputs:", 						unit: "", 							value: 0, 					display: "" },
  { address: REGS.THERMISTOR_1, 			  		type: regType.temperature, 			title: "Ther 1:", 						unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.THERMISTOR_2, 			  		type: regType.temperature, 			title: "Therm 2:", 						unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.HEATER_STATE, 			  		type: regType.other, 						title: "Heater State:", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_2_STATE, 					type: regType.other, 						title: "Heater 2 State:", 		unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_WINTER_NEXT_MINS, 	type: regType.other, 					  title: "WINTER MODE NEXT:", 	unit: "mins", 					value: 0, 					display: "" },
  { address: REGS.SLAVE_3_ENABLE, 					type: regType.other, 						title: "EXP 3 Enable", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_WINTER_MODE, 				type: regType.disable_enable, 	title: "Winter Mode", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_TRIGGER, 			  		type: regType.other, 						title: "Pump Activated By", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.THERMISTOR_1_TYPE, 				type: regType.other, 						title: "Thermistor 1 Type", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.THERMISTOR_2_TYPE, 				type: regType.other, 						title: "Thermistor 2 Type", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.THERMISTOR_3_TYPE, 				type: regType.other, 						title: "Thermistor 3 Type", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.THERMISTOR_1_OFFSET, 			type: regType.temperature, 			title: "Thermistor 1 offset", unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.THERMISTOR_2_OFFSET, 			type: regType.temperature, 			title: "Thermistor 2 offset", unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.THERMISTOR_3_OFFSET, 			type: regType.temperature, 			title: "Thermistor 3 offset", unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.PH_SENSOR_CONNECTED, 			type: regType.off_on, 						title: "Ph Probe Attached", 	unit: "", 						value: 0, 					display: "" },
  { address: REGS.ORP_SENSOR_CONNECTED, 		type: regType.off_on, 						title: "ORP Probe Attached", 	unit: "", 						value: 0, 					display: "" },


  /****SOcket Statuses ******/
  { address: REGS.SOCKET_1_STATUS, 					type: regType.other, 						title: "S1 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_STATUS, 					type: regType.other, 						title: "S2 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_STATUS, 					type: regType.other, 						title: "S3 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_STATUS, 					type: regType.other, 						title: "S4 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_STATUS, 					type: regType.other, 						title: "S5 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_STATUS, 					type: regType.other, 						title: "S6 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_STATUS, 					type: regType.other, 						title: "S7 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_STATUS, 					type: regType.other, 						title: "S8 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_STATUS, 					type: regType.other, 						title: "V1 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_STATUS, 					type: regType.other, 						title: "V2 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_STATUS, 					type: regType.other, 						title: "V3 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_STATUS, 					type: regType.other, 						title: "V4 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VF_CONTACT_1_STATUS, 			type: regType.other, 						title: "VF1 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VF_CONTACT_2_STATUS, 			type: regType.other, 						title: "VF2 Status", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_TYPE, 			  		type: regType.other, 						title: "Skt 1 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_TYPE, 			  		type: regType.other, 						title: "Skt 2 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_TYPE, 			  		type: regType.other, 						title: "Skt 3 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_TYPE, 			  		type: regType.other, 						title: "Skt 4 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_TYPE, 			  		type: regType.other, 						title: "Skt 5 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_TYPE, 			  		type: regType.other, 						title: "Skt 6 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_TYPE, 			  		type: regType.other, 						title: "Skt 7 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_TYPE, 			  		type: regType.other, 						title: "Skt 8 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_TYPE, 			  		type: regType.other, 						title: "Vlv 1 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_TYPE, 			  		type: regType.other, 						title: "Vlv 2 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_TYPE, 			  		type: regType.other, 						title: "Vlv 3 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_TYPE, 			  		type: regType.other, 						title: "Vlv 4 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_TYPE, 				type: regType.other, 						title: "VF 1 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_TYPE, 				type: regType.other, 						title: "VF 2 Type", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_MODE, 			  		type: regType.other, 						title: "Skt 1 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_MODE, 			  		type: regType.other, 						title: "Skt 2 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_MODE, 			  		type: regType.other, 						title: "Skt 3 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_MODE, 			  		type: regType.other, 						title: "Skt 4 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_MODE, 			  		type: regType.other, 						title: "Skt 5 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_MODE, 			  		type: regType.other, 						title: "Skt 5 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_MODE, 			  		type: regType.other, 						title: "Skt 6 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_MODE, 			  		type: regType.other, 						title: "Skt 7 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_MODE, 			  		type: regType.other, 						title: "Vlv 1 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_MODE, 			  		type: regType.other, 						title: "Vlv 2 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_MODE, 			  		type: regType.other, 						title: "Vlv 3 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_MODE, 			  		type: regType.other, 						title: "Vlv 4 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_MODE, 				type: regType.other, 						title: "VF 1 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_MODE, 				type: regType.other, 						title: "VF 2 Mode", 					unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATING_PUMP_CALL_SPEED, 	type: regType.pump_speed, 		  title: "Heat Call Spd", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_PERCENTAGE_1, 	type: regType.other, 						title: "Pump Percentage 1", 	unit: "%", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_PERCENTAGE_2, 	type: regType.other, 						title: "Pump Percentage 2", 	unit: "%", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_PERCENTAGE_3, 	type: regType.other, 						title: "Pump Percentage 3",		unit: "%", 							value: 0, 					display: "" },
  { address: REGS.PUMP_SPEED_PERCENTAGE_4, 	type: regType.other, 						title: "Pump Percentage 4",	  unit: "%", 							value: 0, 					display: "" },
  { address: REGS.PUMP_MANUAL_ON_TIME, 	    type: regType.time, 						title: "Pump Manual On Time",	unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_MANUAL_OFF_TIME, 	  type: regType.time, 						title: "Pump Manual Off Time",unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_MANUAL_ON_OFF, 	    type: regType.other, 						title: "Pump Manual On/Off", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_ENABLE, 	    type: regType.other, 						title: "Filter Enable", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_1_START_TIME, type: regType.time, 						title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_1_STOP_TIME, 	type: regType.time, 						title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_2_START_TIME, type: regType.time, 						title: "Start Time 2", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_2_STOP_TIME, 	type: regType.time, 						title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_3_START_TIME, type: regType.time, 						title: "Start Time 3", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_3_STOP_TIME, 	type: regType.time, 						title: "Stop Time 3", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_4_START_TIME, type: regType.time, 						title: "Start Time 4", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_4_STOP_TIME, 	type: regType.time, 						title: "Stop Time 4", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_1_SPEED, 			type: regType.pump_speed, 			title: "Speed", 							unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_2_SPEED, 			type: regType.pump_speed, 			title: "Speed", 							unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_3_SPEED, 			type: regType.pump_speed, 			title: "Speed", 							unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIME_4_SPEED, 			type: regType.pump_speed, 			title: "Speed", 							unit: "", 							value: 0, 					display: "" },
  { address: REGS.RTC_YEAR, 			          type: regType.other, 						title: "Clock:", 							unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_CALL_ENABLE, 			  type: regType.other, 						title: "Pump Call Enabled:", 	unit: "", 							value: 0, 					display: "" },
  { address: REGS.GAS_HEATER_ENABLE, 			  type: regType.lookup, 		      title: "Gas Heater Enabled:", unit: "", 							value: 0, 					display: "",      lookup_table: heaterOffOnOptions },
  { address: REGS.HEAT_PUMP_ENABLE, 			  type: regType.lookup, 		      title: "Heat Pump Enabled:",  unit: "", 							value: 0, 					display: "",      lookup_table: heaterOffOnOptions  },
  
  { address: REGS.GAS_HEATER_COOL_DOWN_TIME,type: regType.time, 						title: "Gas Heater CoolDown:", unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_1_ENABLE,           type: regType.other, 						title: "Exp 1 Enable",        unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_2_ENABLE,           type: regType.other, 						title: "Exp 2 Enable",        unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_1_ADDRESS,          type: regType.other, 						title: "Exp 1 Address",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_2_ADDRESS,          type: regType.other, 						title: "Exp 2 Address",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_1_CONFIGURE,        type: regType.other, 						title: "Exp 1 Configure",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.SLAVE_2_CONFIGURE,        type: regType.other, 						title: "Exp 2 Configure",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_SPEED,                type: regType.pump_speed, 			title: "SPA Speed",           unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_JETPUMP_ENABLE,       type: regType.other, 						title: "SPA Jet Pump Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.RESET_OPTIONS, 			      type: regType.other, 						title: "Reset Options:", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.ENABLE_UUID, 			        type: regType.other, 						title: "Enable UUID", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.OTA_AUTH, 			          type: regType.other, 						title: "OTA Auth", 			      unit: "", 							value: 0, 					display: "" },
  { address: REGS.OTA_HTTPS_ONLY, 			    type: regType.other, 						title: "OTA HTTPS Only", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.LCD_CONTRAST, 			      type: regType.other, 						title: "LCD Contrast", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.TEST_OUTPUT, 			        type: regType.other, 						title: "Test Output", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.TEST_420MA, 			        type: regType.other, 						title: "Test 420mA",  			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.TEST_DISPLAY, 			      type: regType.other, 						title: "Test Display", 			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.DEVICE_PASSCODE, 			    type: regType.other, 						title: "Device Passcode",		  unit: "", 							value: 0, 					display: "" },
  { address: REGS.LOGGING_ENABLE, 			    type: regType.disable_enable, 	title: "Logging Enabled", 		unit: "", 							value: 0, 					display: "" },
  { address: REGS.FILTER_TIMEn_ENABLE, 			type: regType.other, 						title: "Filter Timen Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.FWUPDATE_EXPANSION_PLUS, 	type: regType.other, 						title: "FW Update Exp+", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SET_MODEL, 			          type: regType.other, 						title: "Set Model", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.BOOTUP_COUNT, 			      type: regType.other, 						title: "Boot Count",  			  unit: "", 							value: 0, 					display: "" },
  { address: REGS.RESTART_COUNT, 			      type: regType.other, 						title: "Restart Count",			  unit: "", 							value: 0, 					display: "" },
 
  { address: REGS.APPLIANCE1_NAME_START, 		type: regType.name, 						title: "Skt1 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE2_NAME_START, 		type: regType.name, 						title: "Skt2 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE3_NAME_START, 		type: regType.name, 						title: "Skt3 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE4_NAME_START, 		type: regType.name, 						title: "Skt4 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE5_NAME_START, 		type: regType.name, 						title: "Skt5 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE6_NAME_START, 		type: regType.name, 						title: "Skt6 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE7_NAME_START, 		type: regType.name, 						title: "Skt7 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE8_NAME_START, 		type: regType.name, 						title: "Skt8 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE9_NAME_START, 		type: regType.name, 						title: "Vlv1 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE10_NAME_START, 	type: regType.name, 						title: "Vlv2 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE11_NAME_START, 	type: regType.name, 						title: "Vlv3 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE12_NAME_START, 	type: regType.name, 						title: "Vlv4 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE13_NAME_START, 	type: regType.name, 						title: "VF 1 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE14_NAME_START, 	type: regType.name, 						title: "Vf 2 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE15_NAME_START, 	type: regType.name, 						title: "App15 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE16_NAME_START, 	type: regType.name, 						title: "App16 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE17_NAME_START, 	type: regType.name, 						title: "App17 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE18_NAME_START, 	type: regType.name, 						title: "App18 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.APPLIANCE19_NAME_START, 	type: regType.name, 						title: "App19 Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.DEVICE_NAME_START, 	      type: regType.name, 						title: "Dev Name", 			      unit: "", 							value: 0, 					display: "" },

  { address: REGS.POOL_NAME_START, 			    type: regType.name, 						title: "Pool Name", 			    unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_NAME_START, 			    type: regType.name, 						title: "SPA Name", 			      unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.HEAT_PUMP_2_ENABLE, 			type: regType.lookup, 		      title: "Heat Pump Enable", 		unit: "", 							value: 0, 					display: "", lookup_table: heaterOffOnOptions },
  { address: REGS.GAS_HEATER_2_ENABLE, 			type: regType.lookup, 		      title: "Gas Heater Enable", 	unit: "", 							value: 0, 					display: "", lookup_table: heaterOffOnOptions },
  
  { address: REGS.GAS_HEATER_2_COOL_DOWN_TIME, type: regType.time, 		      title: "GH2 Cool Down Time",  unit: "", 							value: 0, 					display: "" },
  { address: REGS.CHILL_2_FUNCTION, 			  type: regType.off_on, 		      title: "Heater 2 Chilling",   unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEAT_DEMAND_SANITISER_2_ENABLE, type: regType.disable_enable,title: "HD Sanitiser 2 Enable",unit: "", 					value: 0, 					display: "" },
  { address: REGS.HEAT_2_SPEED,             type: regType.pump_speed, 		  title: "Heating 2 Speed",     unit: "", 				      value: 0, 					display: "" },
  
  { address: REGS.HEATER_2_SOLAR_PRIORITY,  type: regType.disable_enable, 	title: "Heater 2 Solar Priority",unit: "", 						value: 0, 					display: "" },
  { address: REGS.HEATER_2_ROOFTEMP_CUTOFF, type: regType.temperature, 			title: "Heater 2 Roof Cut-Off", unit: "\u00B0C", 			value: 0, 					display: "" },
  { address: REGS.HEATER_2_PUMP_TYPE,       type: regType.lookup, 			      title: "Heater 2 Pump Type",  unit: "", 							value: 0, 					display: "", lookup_table: heatingPumpTypes },
  { address: REGS.POOL_2_SET_TEMPERATURE,   type: regType.temperature, 			title: "Heater 2 Pool Set Temp", unit: "\u00B0C", 		value: 0, 					display: "" },
  { address: REGS.SPA_2_SET_TEMPERATURE,    type: regType.temperature, 			title: "Heater 2 SPA Set Temp", unit: "\u00B0C", 			value: 0, 					display: "" },
  { address: REGS.SPA_2_SET_TEMPERATURE,    type: regType.temperature, 			title: "Heater 2 SPA Set Temp", unit: "\u00B0C", 			value: 0, 					display: "" },
  { address: REGS.RUN_TILL_HEATED_2_FUNC,   type: regType.other, 		        title: "Run Till Heated 2",   unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_2_TRACKING_ENABLE, type: regType.other, 		        title: "Heater 2 tracking",   unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_2_TRACKING_OFFSET, type: regType.other, 		        title: "Heater 2 Track offset",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_END_TIME,           type: regType.time, 		        title: "Solar End Time",        unit: "", 						value: 0, 					display: "" },
  { address: REGS.SOLAR_SENSOR_LOCATION,    type: regType.lookup, 		      title: "Solar Sensor Location",unit: "", 							value: 0, 					display: "", lookup_table: solarSensorLocations },
  { address: REGS.SPA_SLEEP_TIMER,          type: regType.other, 		        title: "SPA Sleep Timer",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATING_PUMP_TYPE,        type: regType.lookup, 		      title: "Heating Pump Type",   unit: "", 							value: 0, 					display: "", lookup_table: heatingPumpTypes },
  { address: REGS.RUN_TILL_HEATED_FUNC,     type: regType.other, 		        title: "Run Till Heated",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEAT_DEMAND_SANITISER_ENABLE, type: regType.lookup, 		  title: "HD Sanitiser Enable", unit: "", 							value: 0, 					display: "", lookup_table: offOnOptions },


  { address: REGS.HEATER_USES_WATER, 			  type: regType.lookup, 					title: "Heater Uses Water", 	unit: "", 							value: 0, 					display: "" , lookup_table: offOnOptions },
  { address: REGS.HEATER_2_USES_WATER, 			type: regType.lookup, 					title: "Heater 2 Uses Water", unit: "", 							value: 0, 					display: "" , lookup_table: offOnOptions },
  { address: REGS.SOLAR_RUN_FROM_HEATER_PUMP,type: regType.off_on, 					title: "Solar uses Heating Pump",unit: "", 						value: 0, 					display: "" },
  { address: REGS.HEATER_EXPANSION_PRESENT, type: regType.off_on,					  title: "HEater Exp Pres",     unit: "", 							value: 0, 					display: "" },
 
  { address: REGS.HEATER_HYDROTHERAPY_MODE, type: regType.off_on, 					  title: "Heater Hydro",        unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_2_HYDROTHERAPY_MODE, type: regType.off_on, 					title: "Heater 2 Hydro",      unit: "", 							value: 0, 					display: "" },
 
  { address: REGS.ANTIFREEZE_ACTIVE,        type: regType.disable_enable,	  title: "Antifreeze",          unit: "", 							value: 0, 					display: "" },
  { address: REGS.ANTIFREEZE_TEMPERATURE,   type: regType.temperature  , 		title: "Antifreeze Temp" ,    unit: "\u00B0C", 				value: 0, 					display: "" },

  { address: REGS.SPA_TIMER_2_ENABLE,       type: regType.disable_enable,	  title: "SPA 2 Timer Enable",  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_TIMER_ENABLE,         type: regType.disable_enable,   title: "SPA Timer Enable",    unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SPA_TIMER_2_START_TIME,   type: regType.time, 						title: "SPA Start Time 2", 	  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_TIMER_2_STOP_TIME, 	  type: regType.time, 						title: "SPA Stop Time 2", 		unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_TIMER_START_TIME,     type: regType.time, 						title: "SPA Start Time 1", 		unit: "", 							value: 0, 					display: "" },
  { address: REGS.SPA_TIMER_STOP_TIME, 	    type: regType.time, 						title: "SPA Stop Time 1", 		unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.OVERRIDE_SENSOR_SETTLING, type: regType.disable_enable,   title: "Override Settling",   unit: "", 							value: 0, 					display: "" },
  { address: REGS.PH_SENSOR_OFFSET,         type: regType.other, 		        title: "pH offset",           unit: "", 							value: 0, 					display: "" },
  { address: REGS.ORP_SENSOR_OFFSET,        type: regType.other, 		        title: "ORP offset",          unit: "", 							value: 0, 					display: "" },
  { address: REGS.DOSING_OPTION,            type: regType.other, 		        title: "Dosing Option",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.PRIME_DOSING_PUMP,        type: regType.other, 		        title: "Prime Dosing Pump",   unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.WATER_FEATURE_SPEED,      type: regType.pump_speed, 		  title: "Water Feature Speed", unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATING_SPEED,            type: regType.pump_speed, 		  title: "Heating Speed",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_DEFAULT_SPEED,       type: regType.pump_speed, 		  title: "Pump Default Speed",  unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_VSP_MODE,            type: regType.other, 		        title: "Pump VSP Mode",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEAT_DEMAND_ENABLE,       type: regType.disable_enable,	  title: "Heat Demand Enable",  unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEAT_DEMAND_START_TIME,   type: regType.time, 						title: "Heat Demand Start Time",unit: "", 						value: 0, 					display: "" },
  { address: REGS.HEAT_DEMAND_STOP_TIME, 	  type: regType.time, 						title: "Heat Demand Stop Time",unit: "", 							value: 0, 					display: "" },

  { address: REGS.LIGHTS_1_SYNC, 	          type: regType.other, 						title: "Lights 1 Sync",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHTS_2_SYNC, 	          type: regType.other, 						title: "Lights 2 Sync",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHTS_3_SYNC, 	          type: regType.other, 						title: "Lights 3 Sync",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHT_1_REINIT, 	        type: regType.other, 						title: "Lights 1 Reinit",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHT_2_REINIT, 	        type: regType.other, 						title: "Lights 2 Reinit",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHT_3_REINIT, 	        type: regType.other, 						title: "Lights 3 Reinit",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.LIGHTS_LINKED, 	          type: regType.other, 						title: "Lights Linked",       unit: "", 							value: 0, 					display: "" },
  { address: REGS.HEATER_CHILL_FUNC, 	      type: regType.off_on, 					title: "Heater Chilling",     unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_CALL_RUN_ON_TIME, 	  type: regType.other, 						title: "Run On Time",         unit: "", 							value: 0, 					display: "" },
  { address: REGS.PUMP_CALL_ENABLED_ITEMS, 	type: regType.other, 						title: "Pump Call Items",     unit: "", 							value: 0, 					display: "" },

  { address: REGS.PSW_REG_BLOWER_TIMEOUT_ENABLED,type: regType.disable_enable,title: "SPA Blower Timeout",  unit: "mins", 				value: 0, 					display: "" },
  { address: REGS.PSW_REG_SOLAR_FORCE_RUN_ON, type: regType.disable_enable, title: "Solar force RUN-ON",  unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOLAR_TURN_ON_DIFFERENCE, type: regType.temperature,      title: "Solar turn ON Diff",  unit: "\u00B0C", 				value: 0, 					display: "" },
  { address: REGS.SOLAR_TURN_OFF_DIFFERENCE, type: regType.temperature,     title: "Solar turn OFF Diff", unit: "\u00B0C", 				value: 0, 					display: "" },

  { address: REGS.SOCKET_1_TIME_ENABLE, 	  type: regType.disable_enable,		title: "Socket 1 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_1_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_2_TIME_ENABLE, 	  type: regType.disable_enable, 	title: "Socket 2 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_2_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_3_TIME_ENABLE, 	  type: regType.disable_enable, 	title: "Socket 3 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_3_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_4_TIME_ENABLE, 	  type: regType.disable_enable, 	title: "Socket 4 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_4_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_5_TIME_ENABLE, 	  type: regType.other, 						title: "Socket 5 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_5_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_6_TIME_ENABLE, 	  type: regType.other, 						title: "Socket 6 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_6_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_7_TIME_ENABLE, 	  type: regType.other, 						title: "Socket 7 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_7_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.SOCKET_8_TIME_ENABLE, 	  type: regType.other, 						title: "Socket 8 Time Enable",unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_TIME_1_START_TIME, type: regType.time, 					title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_TIME_1_STOP_TIME, 	type: regType.time, 					title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_TIME_2_START_TIME, type: regType.time, 					title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.SOCKET_8_TIME_2_STOP_TIME, 	type: regType.time, 					title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.VALVE_1_TIME_ENABLE, 	    type: regType.other, 						title: "Valve 1 Time Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_TIME_1_START_TIME,type: regType.time, 					  title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_TIME_1_STOP_TIME, type: regType.time, 					  title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_TIME_2_START_TIME,type: regType.time, 					  title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_1_TIME_2_STOP_TIME, type: regType.time, 					  title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  

  { address: REGS.VALVE_2_TIME_ENABLE, 	    type: regType.other, 						title: "Valve 2 Time Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_TIME_1_START_TIME,type: regType.time, 					  title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_TIME_1_STOP_TIME, type: regType.time, 					  title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_TIME_2_START_TIME,type: regType.time, 					  title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_2_TIME_2_STOP_TIME, type: regType.time, 					  title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.VALVE_3_TIME_ENABLE, 	    type: regType.other, 						title: "Valve 3 Time Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_TIME_1_START_TIME,type: regType.time, 					  title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_TIME_1_STOP_TIME, type: regType.time, 					  title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_TIME_2_START_TIME,type: regType.time, 					  title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_3_TIME_2_STOP_TIME, type: regType.time, 					  title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.VALVE_4_TIME_ENABLE, 	    type: regType.other, 						title: "Valve 4 Time Enable", unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_TIME_1_START_TIME,type: regType.time, 					  title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_TIME_1_STOP_TIME, type: regType.time, 					  title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_TIME_2_START_TIME,type: regType.time, 					  title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VALVE_4_TIME_2_STOP_TIME, type: regType.time, 					  title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  { address: REGS.VFCONTACT_1_TIME_ENABLE, 	type: regType.other, 						title: "VF 1 Time Enable",    unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_TIME_1_START_TIME,type: regType.time, 				title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_TIME_1_STOP_TIME, type: regType.time, 				title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_TIME_2_START_TIME,type: regType.time, 				title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_1_TIME_2_STOP_TIME, type: regType.time, 				title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  
  { address: REGS.VFCONTACT_2_TIME_ENABLE, 	type: regType.other, 						title: "VF 2 Time Enable",    unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_TIME_1_START_TIME,type: regType.time, 				title: "Start Time 1 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_TIME_1_STOP_TIME, type: regType.time, 				title: "Stop Time 1", 				unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_TIME_2_START_TIME,type: regType.time, 				title: "Start Time 2 ", 			unit: "", 							value: 0, 					display: "" },
  { address: REGS.VFCONTACT_2_TIME_2_STOP_TIME, type: regType.time, 				title: "Stop Time 2", 				unit: "", 							value: 0, 					display: "" },
  
  


];

