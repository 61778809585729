export const paramsColors =
[
    'blue',  'teal', 'stone', 'slate',
    'red', 'indigo', 'lime', 'fuchsia', 
    'rose', 'cyan', 'amber', 'green', 'gray',
    'pink', 'yellow',
    
];


export const pumpStatecolours =
[
    'gray',     //'STARTUP',
    'gray',     // 'IDLE',
    'neutral',     // 'POWER P',
    'neutral',     // 'POWERED',
    'neutral',     // 'WAIT',
    'cyan',     // 'PRIME', 
    'cyan',     // 'SET SPEED',
    'cyan',     // 'SPD POLL',
    'green',     // 'CHK SPD',
    'green',     // 'SET ON',
    'green',     // 'CHK ON P',
    'green',     // 'CHK ON',
    'green',     // 'RUNNING',
    'lime',     // 'RUN ON',
    'cyan',     // 'PWR DWN',
    'cyan',     // 'V OFF P', 
    'cyan',     // 'VER OFF',
    'amber',     // 'FAULT',
    'blue',     // 'PRIME OFF',
    'gray',     // 'RUN ON', 
    'gray',     // 'POWER OFF',
    'gray',     // 'POWER ON'

];

export const applianceStatecolours =
[
    'gray',     //'STARTUP',
    'green',     // 'IDLE',
    'yellow',     // 'POWER P',
];

export const pumpStateStrings =
[
  'STARTUP',
  'IDLE',
  'POWER P',
  'POWERED',
  'WAIT',
  'PRIME', 
  'SET SPEED',
  'SPD POLL',
  'CHK SPD',
  'SET ON',
  'CHK ON P',
  'CHK ON',
  'RUN',
  'RUN ON',
  'PWR DWN',
  'V OFF P', 
  'VER OFF',
  'FAULT',
  'PRIME OFF',
  'RUN ON', 
  'POWER OFF',
  'POWER ON'
];

export const paramsNames =
{
  ROOF: 'RoofTemp',
  WATER: "WaterTemp",
  POOL: "PoolTemp",
  ORP: "ORP",
  ORPRT: "ORP_RT",
  PH: "PH",
  PHRT: "PH_RT",
  PUMP: "pumpState",
  PUMPSTATE: "PumpStateString",
  PUMPSPEED: "PumpSpeed",
  APP1: "appState0",
  APP2: "appState1",
  APP3: "appState2",
  APP4: "appState3",
  APP5: "appState4",
  APP6: "appState5",
  APP7: "appState6",
  APP8: "appState7",
  APP9: "appState8",
  APP10: "appState9",
  APP11: "appState10",
  APP12: "appState11",
  APP13: "appState12",
  APP14: "appState13",
  
};

export const paramsConfig =
[
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.POOL, 
        'text': 'Pool (\u00B0C)',
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.ROOF,
        'text': 'Roof (\u00B0C)',
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.ORP,
        'text': 'ORP (mV)',
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.ORPRT,
        'text': 'ORP Curr (mV)',
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PH,
        'text': 'pH',
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PHRT,
        'text': 'pH Curr',
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.WATER,
        'text': 'Water (\u00B0C)',
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PUMP,
        'text': 'Pump State',
    },
    {
        'search': false,
        'selectable': false,
        'name': paramsNames.PUMPSPEED,
        'text': 'Pump Speed',
    },

    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP1,
        'text': 'Appliance 1',
        'id': 1
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP2,
        'text': 'Appliance 2',
        'id': 2
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP3,
        'text': 'Appliance 3',
        'id': 3
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP4,
        'text': 'Appliance 4',
        'id': 4
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP5,
        'text': 'Appliance 5',
        'id': 5
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP6,
        'text': 'Appliance 6',
        'id': 6
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP7,
        'text': 'Appliance 7',
        'id': 7
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP8,
        'text': 'Appliance 8',
        'id': 8
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP9,
        'text': 'Appliance 9',
        'id': 9
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP10,
        'text': 'Appliance 10',
        'id': 10
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP11,
        'text': 'Appliance 11',
        'id': 11
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP12,
        'text': 'Appliance 12',
        'id': 12
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.APP13,
        'text': 'Appliance 13',
        'id': 13
    },
   

    
];
