import {

  Text,
  Flex,
 
  Card,
  Grid,
  Bold,

} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPumpSpeed,
 
  DisplaySliderItem,

  DisplayToggleItem,
  DisplaySelectPickerItem,
  DisplayTimePickerItem,
 
} from "./DisplayItems";

import {
 
  MdOutlineThermostat as IconThermometer,
 
  MdRoofing as IconSolar,
 
  MdSpeed as IconSpeed,
 
  MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  GiWinterHat as IconWinter,
  GiThermometerCold as IconFrozen,
} from "react-icons/gi";

import {
  TbEngine as IconPump,
} from "react-icons/tb";


import {
  colours,
  
  offOnOptions,
  DisableEnableOptions,
 solarSensorLocations,
  solarTypeOptions,
} from "../constants";

import {

  REGS,

} from "../registers";

export const SolarView = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        
      <Card
      decoration="bottom"
      decorationColor={colours.cardDecoration}
      className="mt-3">
        <div className="max-w-none mt-2 space-y-4 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={colours.default}><Bold>Solar Status</Bold></Text>
          </Flex>

          <Grid
            numItems={2}
            numItemsSm={2}
            numItemsLg={4}
            className="space-y-4 px-4 gap-x-4"
          >
            <DisplayItem register={props.getRegisterData(REGS.SOLAR_STATE)} icon={IconSolar} />
            <DisplayItem register={props.getRegisterData(REGS.SOLAR_INIT_TEMP)} icon={IconSolar} />
            <DisplayItem register={props.getRegisterData(REGS.SOLAR_WINTER_NEXT_MINS)} icon={IconWinter} />
          </Grid>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2 " >
            <Text color={colours.default}><Bold>Solar Setup</Bold></Text>
          </Flex>
          <Grid
            numItems={2}
            numItemsSm={2}
            numItemsLg={3}
            className="space-y-4 px-4 gap-x-4"
          >
            
            <DisplaySelectPickerItem register={props.getRegisterData(REGS.SOLAR_TYPE)} icon={IconPump} options={solarTypeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.getRegisterData(REGS.SOLAR_WINTER_MODE)} icon={IconWinter} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.getRegisterData(REGS.SOLAR_RUN_FROM_HEATER_PUMP)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayPumpSpeed register={props.getRegisterData(REGS.SOLAR_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
            <DisplayTimePickerItem register={props.getRegisterData(REGS.SOLAR_START_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
            <DisplayTimePickerItem register={props.getRegisterData(REGS.SOLAR_END_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
            <DisplayToggleItem register={props.getRegisterData(REGS.SOLAR_SENSOR_LOCATION)} icon={IconThermometer} options={solarSensorLocations} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.getRegisterData(REGS.ANTIFREEZE_ACTIVE)} icon={IconFrozen} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.getRegisterData(REGS.PSW_REG_SOLAR_FORCE_RUN_ON)} icon={IconRun} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            
          </Grid>
          <Grid
                numItems={1}
                numItemsSm={1}
                numItemsLg={2}
                className="space-y-4 px-4 gap-x-4"
              >
            <DisplaySliderItem register={props.getRegisterData(REGS.SOLAR_SET_TEMPERATURE)} icon={IconSolar} min={20} max={80} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
            <DisplaySliderItem register={props.getRegisterData(REGS.ANTIFREEZE_TEMPERATURE)} icon={IconFrozen} min={0} max={10} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
            <DisplaySliderItem register={props.getRegisterData(REGS.SOLAR_TURN_ON_DIFFERENCE)} icon={IconThermometer} min={12} max={40} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
            <DisplaySliderItem register={props.getRegisterData(REGS.SOLAR_TURN_OFF_DIFFERENCE)} icon={IconThermometer} min={8} max={32} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
          </Grid>

          </div>
        </Card>
      </Grid>
    )
}