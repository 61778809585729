import {

  Text,
  Flex,
 
  Card,
  Grid,


} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPumpSpeed,
 
  DisplaySliderItem,

  DisplayToggleItem,
  DisplaySelectPickerItem,
  DisplayListAccordian,
 
} from "./DisplayItems";

import {
 
  MdSpeed as IconSpeed,

  MdOutlineSchedule as IconClock,
} from "react-icons/md";



import {
  TbEngine as IconPump,
} from "react-icons/tb";


import {
  colours,
  
  offOnOptions,

  pumpModelOptionsSelect,
  offOnAutoOptions
} from "../constants";

import {

  REGS,

} from "../registers";

export const PumpView = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-2 space-y-4 text-left">
              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <Text color={colours.default}>Pump</Text>
              </Flex>

              <Grid
                numItems={2}
                numItemsSm={2}
                numItemsLg={2}
                className="gap-4"
              >
                <DisplayItem register={props.getRegisterData(REGS.PUMP_STATE)} icon={IconPump} />
                <DisplayItem register={props.getRegisterData(REGS.PUMP_LAST_RUN_TIME)} icon={IconPump} />
                <DisplayToggleItem register={props.getRegisterData(REGS.PUMP_SPEED_TYPE)} icon={IconPump} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                {/* <DisplayItem register={props.getRegisterData(REGISTERS.PUMP_MODEL)} icon={IconPump} /> */}
                <DisplaySelectPickerItem register={props.getRegisterData(REGS.PUMP_MODEL)} icon={IconPump} options={pumpModelOptionsSelect} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_1)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_2)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_3)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_4)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} />

                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_PERCENTAGE_1)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_PERCENTAGE_2)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_PERCENTAGE_3)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.PUMP_SPEED_PERCENTAGE_4)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} />
                <DisplayToggleItem register={props.getRegisterData(REGS.PUMP_MANUAL_ON_OFF)} icon={IconPump} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayPumpSpeed register={props.getRegisterData(REGS.WATER_FEATURE_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
                <DisplayPumpSpeed register={props.getRegisterData(REGS.PUMP_DEFAULT_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
                <DisplayPumpSpeed register={props.getRegisterData(REGS.HEATING_PUMP_CALL_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
              
                <DisplayToggleItem register={props.getRegisterData(REGS.PUMP_VSP_MODE)} icon={IconPump} options={offOnAutoOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                
                <DisplayItem register={props.getRegisterData(REGS.PUMP_MANUAL_ON_TIME)} icon={IconClock} />
                <DisplayItem register={props.getRegisterData(REGS.PUMP_MANUAL_OFF_TIME)} icon={IconClock} />
                {/* <DisplayTimePickerItem register={props.getRegisterData(REGS.PUMP_MANUAL_ON_TIME)} icon={IconClock} onChange={handleTimesUpdate} />
                <DisplayTimePickerItem register={props.getRegisterData(REGS.PUMP_MANUAL_OFF_TIME)} icon={IconClock} onChange={handleTimesUpdate} /> */}
                




              </Grid>
              <DisplayListAccordian register={props.getRegisterData(REGS.PUMP_TRIGGER)} icon={IconPump} />
            </div>
          </Card>
          </Grid>
    )
}