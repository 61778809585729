/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($recordType: String!, $recordID: String!) {
    getDevice(recordType: $recordType, recordID: $recordID) {
      recordType
      recordID
      userName
      userID
      datetime
      connectionStatus
      controllerStatus
      faults
      macAddress
      customMac
      customUniqueDeviceId
      createdAt
      updatedAt
      modelType
      notes
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $recordType: String
    $recordID: ModelStringKeyConditionInput
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      recordType: $recordType
      recordID: $recordID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        recordType
        recordID
        userName
        userID
        datetime
        connectionStatus
        controllerStatus
        faults
        macAddress
        customMac
        customUniqueDeviceId
        createdAt
        updatedAt
        modelType
        notes
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogData = /* GraphQL */ `
  query GetLogData($uniqueDeviceId: String!, $datetime: String!) {
    getLogData(uniqueDeviceId: $uniqueDeviceId, datetime: $datetime) {
      uniqueDeviceId
      datetime
      RoofTemp
      WaterTemp
      caseTemp
      createdAt
      PoolTemp
      updatedAt
      ORP
      PH
      ORP_RT
      PH_RT
      faults
      pumpState
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      heaterState
      heater2State
      __typename
    }
  }
`;
export const listLogData = /* GraphQL */ `
  query ListLogData(
    $uniqueDeviceId: String
    $datetime: ModelStringKeyConditionInput
    $filter: ModelLogDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogData(
      uniqueDeviceId: $uniqueDeviceId
      datetime: $datetime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        uniqueDeviceId
        datetime
        RoofTemp
        WaterTemp
        caseTemp
        createdAt
        PoolTemp
        updatedAt
        ORP
        PH
        ORP_RT
        PH_RT
        faults
        pumpState
        appState0
        appState1
        appState2
        appState3
        appState4
        appState5
        appState6
        appState7
        appState8
        appState9
        appState10
        appState11
        appState12
        appState13
        heaterState
        heater2State
        __typename
      }
      nextToken
      __typename
    }
  }
`;
