import {

  Text,
  Flex,
 
  Card,
  Grid,


} from "@tremor/react";

import { 
  DisplayItem,
  DisplayPumpSpeed,
  DisplaySliderItem,
  DisplayToggleItem,
  DisplayTimePickerItem,
} from "./DisplayItems";

import {
  GiBoilingBubbles as IconBubbles2,
} from "react-icons/gi";

import {
  MdSpeed as IconSpeed,
  MdOutlineThermostat as IconThermometer,
  MdHotTub as IconSpa,
  MdWbTwighlight as IconLight,
  MdOutlineSchedule as IconClock,
} from "react-icons/md";

import {
  colours,
  offOnOptions,
  DisableEnableOptions,
  spaBlowerTimeOutOptions,
} from "../constants";

import {

  REGS,

} from "../registers";


import { 
  formatTemperature, 
} from "./RegistersHandler";

export const SPAView = props => {
    return (
      
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-0 space-y-4 text-left">
              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <Text color={colours.default}>SPA</Text>
              </Flex>
              <Grid
                    numItems={2}
                    numItemsSm={2}
                    numItemsLg={2}
                    className="gap-4"
                  >

                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_ENABLE)} icon={IconSpa} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_BLOWER_ENABLE)} icon={IconSpa} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_LIMIT_OFFSET_ENABLE)} icon={IconSpa} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
        
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_CHLORINATOR_ENABLE)} icon={IconSpa} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayItem register={props.onGetRegisterData(REGS.SPA_LIGHT)} icon={IconLight} />
                <DisplayItem register={props.onGetRegisterData(REGS.SPA_ON_TIMER)} icon={IconSpa} />
                <DisplayItem register={props.onGetRegisterData(REGS.SPA_SLEEP_TIMER)} icon={IconSpa} />
              
                <DisplaySliderItem register={props.onGetRegisterData(REGS.SPA_LIMIT_OFFSET)} icon={IconThermometer} min={0} max={40} step={2} onFormat={formatTemperature} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.onGetRegisterData(REGS.SPA_CHLORINATOR_DUTY_CYCLE)} icon={IconThermometer} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} />
            
                <DisplayPumpSpeed register={props.onGetRegisterData(REGS.SPA_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_JETPUMP_ENABLE)} icon={IconSpa} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.PSW_REG_BLOWER_TIMEOUT_ENABLED)} icon={IconBubbles2} options={spaBlowerTimeOutOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            </Grid>
            <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_TIMER_ENABLE)}  options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SPA_TIMER_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SPA_TIMER_STOP_TIME)} onChange={props.onTimesUpdate} />
                
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SPA_TIMER_2_ENABLE)}  options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SPA_TIMER_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SPA_TIMER_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                
              </Flex>
            

            

            </div>
          </Card>
          </Grid>
    )
}