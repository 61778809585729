  import React from "react";

  import {
    
    Button,
    Card,
    Grid,
    Flex,
    
    Text as TextTremor,
    Title,
    Tracker,
    // TrackerBlock,
   
  } from "@tremor/react";
  import {
    ProgressBar,
    SelectDateRange,
    SelectOneDevice
  } from "./Common";

  //import { paramsColors } from "../params";

  import { colours } from "../constants";
  import { Heading } from "@aws-amplify/ui-react";

  

  export const AppliancesView = props => {
  
    const get_app_data = (offset) => {
      var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

      console.log ( "app tracker items:", appliance_data);
  
  
      return appliance_data;
    }

    

    return (
      <div className="mt-3">
        <Grid
          numItemsSm={1}
          numItemsLg={3}
          className="gap-2"
        >
          <SelectOneDevice
            devices={props.devices}
            selected={props.selectedDevice}
            onSelectedChanged={props.onSelectedDeviceChanged}
          />
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-2"
          >
            <SelectDateRange
              range={props.selectedDateRange}
              maxDays={30}
              //disabledDate={combine(allowedMaxDays(7), afterToday())}
              onChange={props.onSelectedDateRangeChanged}
            />
            <Button
              color={colours.default}
              size="sm"
              onClick={props.onRefresh}
              disabled={(props.selectedDevice.length === 0) || props.loading}
            >Refresh</Button>
          </Flex>


        </Grid>
        {
          (props.loading ? (
            <Card
              decoration="bottom"
              decorationColor={colours.cardDecoration}
              className="mt-3">
              <ProgressBar percentage={props.progress} />
            </Card>
          ) : (props.selectedDevice.length === 0 ? (
            <Card
              decoration="bottom"
              decorationColor={colours.cardDecoration}
              className="mt-3">
              <TextTremor color={colours.warning}>Select a controller</TextTremor>
            </Card>
          ) : (
            <div></div>
          )
          )
          )
        }

        {
          props.isLoadingOrNoDevice ? (<div></div>) : (

            <>
                {(props.pumpData.length === 0) ? (<div></div>) : (
                  <Card 
                  decoration="bottom"
                  decorationColor={colours.cardDecoration}
                  className="mt-3  max-w-none">

                    <Flex justifyContent="center" className="mt-4">
                    <Heading>Appliances</Heading>
                    </Flex> 
                   
                    <Title>Pump</Title>
                    {/* <Flex justifyContent="end" className="mt-4">
                <Text>Uptime 99.4%</Text>
            </Flex> */}
                    <Tracker className="mt-2">
                      {/* {props.pumpData.map((item) => (
                        <TrackerBlock
                          color={item.PumpCol}
                          tooltip={item.DisplayString} />
                      ))} */}

                    </Tracker>
                    {(props.applianceDataArray.length === 0) ? (<div></div>) : (
                      <div>
                        {(props.isApplianceConfigured(1) === false) ? (<div></div>) : (
                         <div>
                         <Title> Appliance 1 </Title> 
                         <Tracker className="mt-2" data={get_app_data(1)}/>
                         </div>
                        )}
                        {(props.isApplianceConfigured(2) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 2 </Title>
                            <Tracker className="mt-2" data={get_app_data(2)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(3) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 3 </Title>
                            <Tracker className="mt-2" data={get_app_data(3)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(4) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 4 </Title>
                            <Tracker className="mt-2" data={get_app_data(4)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(5) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 5 </Title>
                            <Tracker className="mt-2" data={get_app_data(5)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(6) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 6 </Title>
                            <Tracker className="mt-2" data={get_app_data(6)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(7) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 7 </Title>
                            <Tracker className="mt-2" data={get_app_data(7)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(8) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 8 </Title>
                            <Tracker className="mt-2" data={get_app_data(8)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(9) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 1 </Title>
                            <Tracker className="mt-2" data={get_app_data(9)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(10) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 2 </Title>
                            <Tracker className="mt-2" data={get_app_data(10)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(11) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 3 </Title>
                            <Tracker className="mt-2" data={get_app_data(11)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(12) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 4</Title>
                            <Tracker className="mt-2" data={get_app_data(12)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(13) === false) ? (<div></div>) : (
                          <div>
                            <Title> VF Contact  </Title>
                            <Tracker className="mt-2" data={get_app_data(13)}/>
                          </div>
                        )}
                      </div>
                    )}
                  </Card>
                )}
              </>
        
          )
          
        }
      </div>
    )
  };