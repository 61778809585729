import {
  Text,
  Flex,
 
  Card,
  Grid,
  Bold,

} from "@tremor/react";

import { 
 
  
  DisplayButtonItem,
  DisplaySocketStatusItem,
} from "./DisplayItems";

import {
 
  
  MdPool as IconPool,
  MdHotTub as IconSpa,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  // GiWinterHat as IconWinter,
  // GiThermometerCold as IconFrozen,
  GiValve as IconValve,
} from "react-icons/gi";

import {
 
  TbPlug as IconSocket,
} from "react-icons/tb";



import {
  colours,
  
//   offOnOptions,
//   DisableEnableOptions,
//  solarSensorLocations,
//   solarTypeOptions,
} from "../constants";

import {

  REGS,

} from "../registers";

export const ControlView = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        
        <Card
              decoration="bottom"
              decorationColor={colours.cardDecoration}
              className="mt-3">
             <div className="max-w-none mt-4 space-y-2 text-center" >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className="space-x-2" >
                  <Text color={colours.default}><Bold>Control</Bold></Text>
                </Flex>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className="space-x-2" >
                  <DisplayButtonItem register={props.getRegisterData(REGS.SPA_MODE)} iconOff={IconPool} iconOn={IconSpa} onChange={props.handleRegisterUpdate} />
                </Flex>
              </div>

              <Grid
                  numItems={1}
                  numItemsSm={1}
                  numItemsLg={3}
                  className="space-y-4 px-4 gap-x-4"
                >

                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_1_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_1_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_2_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_2_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_3_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_3_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
          
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_4_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_4_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_5_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_5_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_6_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_6_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
  
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_7_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_7_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.SOCKET_8_STATUS)} mode_register={props.getRegisterData(REGS.SOCKET_8_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VF_CONTACT_1_STATUS)} mode_register={props.getRegisterData(REGS.VFCONTACT_1_MODE)} icon={IconSocket} onChange={props.handleRegisterUpdate}/>
   
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VALVE_1_STATUS)} mode_register={props.getRegisterData(REGS.VALVE_1_MODE)} icon={IconValve} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VALVE_2_STATUS)} mode_register={props.getRegisterData(REGS.VALVE_2_MODE)} icon={IconValve} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VF_CONTACT_2_STATUS)} mode_register={props.getRegisterData(REGS.VFCONTACT_2_MODE)} icon={IconSettings} onChange={props.handleRegisterUpdate}/>
     
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VALVE_3_STATUS)} mode_register={props.getRegisterData(REGS.VALVE_3_MODE)} icon={IconValve} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VALVE_4_STATUS)} mode_register={props.getRegisterData(REGS.VALVE_4_MODE)} icon={IconValve} onChange={props.handleRegisterUpdate}/>
                  <DisplaySocketStatusItem status_register={props.getRegisterData(REGS.VF_CONTACT_2_STATUS)} mode_register={props.getRegisterData(REGS.VFCONTACT_2_MODE)} icon={IconSettings} onChange={props.handleRegisterUpdate}/>
              

              </Grid>
            </Card>
          </Grid>
    )
}