export const datetimeFormats = {
  "long": "dd/MM/yyyy HH:mm:ss",
  "short": "d/M H:mm",
  "powerDaily": "d/M",
  "picker": "dd/MM/yyyy",
  "filename": "yyMMdd",
  "mid": "dd/MMM/yyyy HH:mm",
};


export const statusItems = {
  all: 0,
  offline: 1,
  fault: 2,
  good: 3
};

export const displayViews = 
{
  list:0,
  grid:1,
};

export const colours = {
  "good": "green",
  "error": "red",
  "warning": "amber",
  "cardDecoration": "neutral",
  "title": "stone",
  "default": "sky",
};


export const coloursHex = {
  "good": '#66BB6A',
  "error": 0xef4444,
  "warning": 0xf59e0b,
  "cardDecoration": 0x737373,
  "title": 0x78716c,
  "default": "#0ea5e9",
};

export const coloursPrime = {
  "good": "lawngreen",
  "error": "red",
  "warning": "yellow",
  "cardDecoration": "darkgrey",
  "title": "SlateGray",
  "default": "royalblue",

}

export const tabItems = {
  home:0,
  trends: 1,
  appliances: 2,
  faults: 3,
  dashboard: 4,
  device: 5
};


export const dashTabItems = {
  control: 0,
  heating: 1,
  solar: 2,
  chemical: 3,
  pump: 4,
  spa:5,
  system:6,
  filter:7,
  systemInfo:8,
};


export const faultView = {
  current: 0,
  history: 1
};

export const spaModeStates = ["Pool Mode", "SPA Mode"];

// export const offOn = ["Off", "On"];
export const offOnOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" }
];


export const heaterOffOnOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" },
  { value: 2, label: "Pool & SPA" },
  { value: 3, label: "Pool" },
  { value: 4, label: "SPA" },
];

export const DisableEnableOptions = [
  { value: 0, label: "Disabled" },
  { value: 1, label: "Enabled" }
];

export const offOnAutoOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" },
  { value: 2, label: "Auto" }
];

export const PumpSpeedOptions = [
  { value: 0, label: "1" },
  { value: 1, label: "2" },
  { value: 2, label: "3" },
  { value: 3, label: "4" }

];
export const offAutoOptions = [
  { value: 0, label: "Off" },
  { value: 2, label: "Auto" }
];


export const spaBlowerTimeOutOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "30 Mins" },
  { value: 2, label: "1 Hr" },
  { value: 3, label: "2 Hrs" }

]

export const thermistorTypeOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "Pool" },
  { value: 2, label: "Roof" },
  { value: 3, label: "Water" }
];

export const deviceOptionsSelect = [
  { value: 0, label: "None" },
  { value: 1, label: "Aquatek" },
  { value: 2, label: "Theralink" },
  { value: 6, label: "Oasis" },
  { value: 10, label: "WaterCo" },
  { value: 11, label: "Henden" }
];
export const solarSensorLocations = [
  { value: 0, label: "Filter Line" },
  { value: 1, label: "Heater Line" },
]

export const pump_states_t =
{
  "STARTUP": 0,
  "IDLE": 1,
  "CHECK POWERED POLL": 2,
  "CHECK POWERED": 3,
  "WAIT BEFORE STARTING": 4,
  "PRIME": 5,
  "SET SPEED": 6,
  "CHECK SPEED POLL": 7,
  "CHECK SPEED": 8,
  "SET ON": 9,
  "CHECK ON POLL": 10,
  "CHECK ON": 11,
  "RUNNING": 12,
  "RUN ON": 13,
  "POWER DOWN": 14,
  "VERIFY OFF POLL": 15,
  "VERIFY OFF": 16,
  "FAULT": 17,
  "SET PRIME OFF": 18,
  "RUN ON HEARTBEAT": 19,


  0: "STARTUP",
  1: "IDLE",
  2: "CHECK POWERED POLL",
  3: "CHECK POWERED",
  4: "WAIT BEFORE STARTING",
  5: "PRIME",
  6: "SET SPEED",
  7: "CHECK SPEED POLL",
  8: "CHECK SPEED",
  9: "SET ON",
  10: "CHECK ON POLL",
  11: "CHECK ON",
  12: "RUNNING",
  13: "RUN ON",
  14: "POWER DOWN",
  15: "VERIFY OFF POLL",
  16: "VERIFY OFF",
  17: "FAULT",
  18: "SET PRIME OFF",
  19: "RUN ON HEARTBEAT"
};
export const solar_states_t =
{
  "OFF": 0,
  "INACTIVE": 1,
  "WAITING": 2,
  "SAMPLING": 3,
  "SAMPLING 1": 4,
  "CHECKING": 5,
  "STARTING UP": 6,
  "ACTIVE": 7,
  "ACTIVE 1": 8,
  "ACTIVE 2": 9,
  "LIMIT": 10,
  "WAITING 1": 11,
  "SAMPLING 2": 12,
  "ACTIVE 3": 13,
  "ACTIVE 4": 14,
  "ANTIFREEZE": 15,
  "ANTIFREEZE 1": 16,
  "ANTIFREEZE 2": 17,
  "_NONE": 18,


  0: "OFF",
  1: "INACTIVE",
  2: "WAITING",
  3: "SAMPLING",
  4: "SAMPLING",
  5: "CHECKING",
  6: "STARTING UP",
  7: "ACTIVE",
  8: "ACTIVE",
  9: "ACTIVE",
  10: "LIMIT",
  11: "WAITING",
  12: "SAMPLING",
  13: "ACTIVE",
  14: "ACTIVE",
  15: "ANTIFREEZE",
  16: "ANTIFREEZE",
  17: "ANTIFREEZE",
  18: "_NONE",


};



export const pump_triggers_t =
{
  0x0001: "RUNNING",
  0x0002: "CHLORINATOR",
  0x0004: "SOLAR",
  0x0008: "INSNRG_HEATER",
  0x0010: "HEATER",
  0x0020: "SOLAR_TEST",
  0x0040: "BACKWASH",
  0x0080: "MANUAL_ON",
  0x0100: "SPA",
  0x0200: "ANTIFREEZE",
  0x0400: "FILTER",
  0x0800: "PUMP_CALL",
  0x1000: "HEATER_2",
  0x2000: "WATER_FEATURE",
  0x4000: "INFLOOR",
  0x8000: "RUN_ONCE",

  "RUNNING": 0x0001,
  "CHLORINATOR": 0x0002,
  "SOLAR": 0x0004,
  "INSNRG_HEATER": 0x0008,
  "HEATER": 0x0010,
  "SOLAR_TEST": 0x0020,
  "BACKWASH": 0x0040,
  "MANUAL_ON": 0x0080,
  "SPA": 0x0100,
  "ANTIFREEZE": 0x0200,
  "FILTER": 0x0400,
  "PUMP_CALL": 0x0800,
  "HEATER_2": 0x1000,
  "WATER_FEATURE": 0x2000,
  "INFLOOR": 0x4000,
  "RUN_ONCE": 0x8000,
};

export const appliance_status_t =
{
  0: "OFF",
  1: "ON",
  2: "NO SETUP",

  "OFF": 0,
  "ON": 1,
  "NOT SETUP": 2,

};


export const heater_state_t =
{
  0x0000: "INIT",
  0x0001: "CHECK TEMPERATURE",
  0x0002: "HEATING",
  0x0003: "HEATER RUN ON",
  0x0004: "LIMIT",
  0x0005: "CHECK ON",
  0x0006: "CHECK POOL TEMPERATURE",
  0x0007: "CHECK SPA TEMPERATURE",
  0x0008: "STOP",
  0x0009: "CONFIRM STOPPED",
  0x000A: "FAULT",
  0x000B: "RUN ON",
  0x000C: "WAIT NEXT",

  "INIT": 0x0000,
  "CHECK TEMPERATURE": 0x0001,
  "HEATING": 0x0002,
  "HEATER RUN ON": 0x0003,
  "LIMIT": 0x0004,
  "CHECK ON": 0x0005,
  "CHECK POOL TEMPERATURE": 0x0006,
  "CHECK SPA TEMPERATURE": 0x0007,
  "STOP": 0x0008,
  "CONFIRM STOPPED": 0x0009,
  "FAULT": 0x000A,
  "RUN ON": 0x000B,
  "WAIT NEXT": 0x000C,
};


export const heater_display_state_t =
{
  0x0000: "OFF",
  0x0001: "WAITING",
  0x0002: "SAMPLING",
  0x0003: "SAMPLING-CHECK",
  0x0004: "ACTIVE",
  0x0005: "RUN-ON",
  0x0006: "LIMIT",
  0x0007: "STOPPING",
  0x0008: "FAULT",
  0x0009: "WAITING",
  0x000A: "CHILLING",
  0x000B: "OFF IN POOL",
  0x000C: "OFF IN SPA",

  "OFF": 0x0000,
  "WAITING": 0x0001,
  "SAMPLING": 0x0002,
  "SAMPLING-CHECK": 0x0003,
  "ACTIVE": 0x0004,
  "RUN-ON": 0x0005,
  "LIMIT": 0x0006,
  "STOPPING": 0x0007,
  "FAULT": 0x0008,
  "WAITING 2": 0x0009,
  "CHILLING": 0x000A,
  "OFF IN POOL": 0x000B,
  "OFF IN SPA": 0x000C,
};

export const solarTypeOptions = [
  { value: 0, label: "NONE" },
  { value: 1, label: "Separate" },
  { value: 2, label: "Valve" },
  { value: 3, label: "Boosted" }
];

export const heatingPumpTypes = [
  { value: 0, label: "NONE" },
  { value: 1, label: "Separate" },
  { value: 2, label: "Valve" },
  { value: 3, label: "Boosted" }
]

export const solar_type_t =
{
  "NONE": 0,
  "Separate": 1,
  "Valve": 2,
  "Boosted": 3,
  "OUTRange": 4,

  0: "NONE",
  1: "Separate",
  2: "Valve",
  3: "Boosted",
  4: "OUTRange",

};

export const thermistor_t =
{
  "NONE": 0,
  "POOL": 1,
  "ROOF": 2,
  "WATER": 3,
  "OUTRange": 4,

  0: "NONE",
  1: "POOL",
  2: "ROOF",
  3: "WATER",
  4: "OUTRange",

};

export const spaSizeOptions = [
{ value: 0, label: "None"},
{ value: 1, label: "1000"},
{ value: 2, label: "1500"},
{ value: 3, label: "2000"},
{ value: 4, label: "2500"},
{ value: 5, label: "3000"},
{ value: 6, label: "4000"},
{ value: 7, label: "5000"},
{ value: 8, label: "6000"},
{ value: 9, label: "7000"},
{ value: 10, label: "8000"},
{ value: 11, label: "9000"},
];


export const poolSizeOptions = [
  { value: 0, label: "None"},
  { value: 1, label: "10000"},
  { value: 2, label: "15000"},
  { value: 3, label: "20000"},
  { value: 4, label: "25000"},
  { value: 5, label: "30000"},
  { value: 6, label: "35000"},
  { value: 7, label: "40000"},
  { value: 8, label: "45000"},
  { value: 9, label: "50000"},
  { value: 10, label: "55000"},
  { value: 11, label: "60000"},
  { value: 12, label: "65000"},
  { value: 13, label: "70000"},
  { value: 14, label: "75000"},
  { value: 15, label: "80000"},
  { value: 16, label: "85000"},
  { value: 17, label: "90000"},
  { value: 18, label: "95000"},
  { value: 19, label: "100000"},
  ];
	

export const logIntervalOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "1 Min" },
  { value: 5, label: "5 Mins" },
  { value: 10, label: "10 Mins" },
  { value: 15, label: "15 Mins" },
  { value: 30, label: "30 Mins" },
  { value: 60, label: "1 Hour" }
];

export const startTimeOptions = [
  { value: 0x0000, label: "00:00" },
  { value: 0x0100, label: "01:00" },
  { value: 0x0200, label: "02:00" },
  { value: 0x0300, label: "03:00" },
  { value: 0x0400, label: "04:00" },
  { value: 0x0500, label: "05:00" },
  { value: 0x0600, label: "06:00" },
  { value: 0x0700, label: "07:00" },
  { value: 0x0800, label: "08:00" },
  { value: 0x0900, label: "09:00" },
  { value: 0x0A00, label: "10:00" },
  { value: 0x0B00, label: "11:00" },
  { value: 0x0C00, label: "12:00" },
  { value: 0x0D00, label: "13:00" },
  { value: 0x0E00, label: "14:00" },
  { value: 0x0F00, label: "15:00" },
  { value: 0x1000, label: "16:00" },
  { value: 0x1100, label: "17:00" },
  { value: 0x1200, label: "18:00" },
  { value: 0x1300, label: "19:00" },
  { value: 0x1400, label: "20:00" },
  { value: 0x1500, label: "21:00" },
  { value: 0x1600, label: "22:00" },
  { value: 0x1700, label: "23:00" }
];

export const VFTypesSelect = [
  { value: 0, label: "None" },
  { value: 1, label: "Gas Heater" },
  { value: 2, label: "Heat Pump" },
];

export const ValveTypesSelect = [
  { value: 0, label: "None" },
  { value: 1, label: "Pool/SPA" },
  { value: 2, label: "Solar" },
  { value: 3, label: "Water Feature" },
  { value: 4, label: "InFloor" },
  { value: 5, label: "Feature From Solar" },
  { value: 6, label: "Other" },
  { value: 7, label: "Heater" },
];

export const SocketTypesSelect = [
  { value: 0, label: "None" },
  { value: 1, label: "Sanitiser" },
  { value: 2, label: "Filter Pump" },
  { value: 3, label: "Cleaning Pump" },
  { value: 4, label: "Blower" },
  { value: 5, label: "Pool Light" },
  { value: 6, label: "Spa Light" },
  { value: 7, label: "Garden Light" },
  { value: 8, label: "Water Feature" },
  { value: 9, label: "Solar" },
  { value: 10, label: "Other" },
  { value: 11, label: "Always On" },
  { value: 12, label: "Jet Pump" },
  { value: 13, label: "Ind Heat Pump"},

  // { value: 13, Label: "Pool/Spa"},
  // { value: 14, label: "Solar"},
  // { value: 15, label: "Water Feature"},
  // { value: 16, label: "Infloor"},
  // { value: 17, label: "Gas Heater"},
  // { value: 18, label: "Heat Pump"},
  
  // { value: 30, label: "Feature Solar"},
  // { value: 32, label: "Heating"


];

export const ResetOptionsSelect = [
  { value: 0, label: "None" },
  { value: 0xF101, label: "Restart" },
  { value: 0xF102, label: "Erase Wifi Data" },
  { value: 0xF103, label: "Reset Factory" },

];
export const ControllerStringDesc = [
  { value: 1, label: "Aquatek" },
  { value: 2, label: "Theralux" },
  { value: 6, label: "Oasis" },
  { value: 8, label: "Aqualight" },
  { value: 10, label: "WaterCo" },
  { value: 10, label: "Hendon" },

];
export const controller_t =
{
  NONE:       0x0000,
  AQUATEK:    0x0001,
  THERALUX:   0x0002,
  OASIS:      0x0006,
  AQUALIGHT:  0x0008,
  RECLAIM:    0x0009,
  WATERCO:    0x000A,
  HENDEN:     0x000B,
};



export const controller_crc_value = 
[ 
  { value: controller_t.THERALUX, crc_value:0x39 },
  { value: controller_t.OASIS, crc_value: 0xD5 },
  { value: controller_t.AQUATEK, crc_value: 7 },
  // { value: controller_t.SRSMITH, crc_value: 0x31},
  { value: controller_t.AQUALIGHT, crc_value: 0x9B},
  { value: controller_t.RECLAIM, crc_value: 0x2F},
  { value: controller_t.WATERCO, crc_value: 0x13},
  { value: controller_t.HENDEN, crc_value: 0x11},
];



export const pumpModelOptionsSelect = [
  { value: 0,   label: "None" },
  { value: 6,   label: "DAB" },
  { value: 16,  label: "Davey VSD" },
  { value: 5,   label: "Insnrg Qi" },
  { value: 17,  label: "Insnrg Zi" },
  { value: 3,   label: "Hayward (485)" },
  { value: 15,  label: "Hayward MaxFlo VS/TriStar VS" },
  { value: 9,   label: "Henden 3 Speed" },
  { value: 20,  label: "Henden VSD" },
  { value: 19,  label: "Legend Ultra VS 1.0 & 1.5hp" },
  { value: 12,  label: "Oasis V Series" },
  { value: 4,   label: "Pentair VS/F" },
  { value: 22,  label: "Pentair EM800" },
  { value: 13,  label: "Pentair EM1500" },
  { value: 8,   label: "Reltech" },
  { value: 2,   label: "Reltech (485)" },
  { value: 11,  label: "Reltech P (485)" },
  { value: 10,  label: "Speck" },
  { value: 7,   label: "Theraflo TVS 1.0 & 1.5hp V1" },
  { value: 1,   label: "Theralux TVS Pro 1.0 & 1.5hp V2" },
  { value: 14,  label: "Theralux TVS Pro 1.25 & 1.75hp V3" },
  { value: 18,  label: "Theralux TVS Pro II 1.0 & 1.5hp V4" },
  { value: 21,  label: "Waterco Hydrostorm"
  }
];


export const accessLevels = {
  guest: 0,
  admin: 1,
  super: 2
};

export const groups = 
{
  Admin: 1,
  Dontek: 2,
  Oasis: 3,
  Theralux: 4,
  Henden: 5,
  Waterco: 6,
};

export const groups_table = 
{
  "admin": groups.Admin,
  "dontek": groups.Dontek,
  "oasis": groups.Oasis,
  "theralux": groups.Theralux,
  "henden": groups.Henden,
  "waterco": groups.Waterco,

  1: "admin" ,
  2: "dontek", 
  3: "oasis", 
  4: "theralux", 
  5: "henden", 
  6: "waterco"

};




