import React, { useState, useEffect } from "react";

import { API, Hub } from "aws-amplify";
import {
  // getControllerIconText ,
  getControllerIconText_FROM_STRING,
  getControllerIconText
} from "./DisplayItems";
import {
  Badge,

  Button,
  Card,
  Col,
  Grid,
  Flex,
  Text,
  Metric,
  // Divider,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  TabGroup,
  TabList,
  Tab
} from "@tremor/react";

import { AddDevice, 
  EditDevice,
  //  SelectManyDevices, 
   ShowDeviceQR, 
   isDeviceInFault,
   isDeviceGood,
   isDeviceOffline,
   getDeviceFaults,
   get_recordID_from_UID} from "./Common";

import {
  MdOutlineEdit as IconEdit,
  MdOutlineInsertChart as IconTrends,
  MdOutlineErrorOutline as IconError,
  MdOutlineSpaceDashboard as IconDashboard,
  // MdRadioButtonUnchecked as IconCheck,
  MdQrCode2 as IconQRCode,
  MdRefresh as IconRefresh
} from "react-icons/md";

import { colours } from "../constants";

import { createDevice, updateDevice } from "../graphql/mutations";

export const HomeView = (props) => {
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState({});

  const isDeviceSelected = (device) => (
    (props.selectedDevices.length === 0) || (props.selectedDevices.includes(device.uniqueDeviceId))
  );
  const isStatusSelected = (device) => (
    (props.selectedStatus === 0) ||
    (props.selectedStatus === 1 && isDeviceOffline(device)) ||
    (props.selectedStatus === 2 && isDeviceInFault(device)) ||
    (props.selectedStatus === 3 && isDeviceGood(device))
  );
  const handleAddDevice = (result) => {
    setShowAddDevice(false);
    if(result !== null) {
      const { id, name, devType } = result;
      addDevice(id, name, devType);
    }
  }

  const handleEditDeviceShow = (device) => {
    setDeviceToEdit(device);
    setShowEditDevice(true);
  }


  const handleQRCodeShow = (device) => {
    setDeviceToEdit(device);
    setShowQRCode(true);
  }

  const handleEditDeviceUpdate = (result) => {
    setShowEditDevice(false);
    if (result !== null) {
      const { device, name , modeltype } = result;
      console.log("Result",device, name, modeltype);
      if ( (device.userName !== name) || ( device.modelType !== modeltype )){
        updateDeviceName(device.uniqueDeviceId, name, modeltype);
        console.log("edit: name changed", name, modeltype);
      } else {
        console.log("edit: name unchanged", name);
      }
      
    }
  }

  const handleQRDeviceUpdate = () => {
    setShowQRCode(false);
  }

  async function addDevice(deviceId, name,devType) {
    // if (props.userGroups.length !== 0) {

      let record_ID = get_recordID_from_UID( deviceId)
      
      var deviceText = "Unknown"
      if ( devType !== undefined)
      {
         deviceText = getControllerIconText(devType).Text
      }
      console.log("add:", record_ID, "type", deviceText);
      const result = await API.graphql({
        query: createDevice,
        variables: {
          input: {
            recordType: "info",
            recordID: record_ID,
            userName: name,
            modelType: deviceText
            // userGroup: props.userGroups[0]
          }
        }
      });
      console.log("add:", result);
      props.onRefresh();
    // }
  }

  async function updateDeviceName(deviceId, name , type) {
    let record_ID = get_recordID_from_UID( deviceId);
    const result = await API.graphql({
      
      query: updateDevice,
      variables: {
        input: {
          recordType: "info",
          recordID: record_ID, 
          
          userName: name,
          modelType: type
        }
      }
    });
    console.log("edit:", result);
    props.onRefresh();
  }

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        setDeviceToEdit({});
      }
    });
  }, []);

  return (
    <div className="mt-3">
      <Grid
        numItemsSm={3}
        numItemsMd={3}
        numItemsLg={3}
        className="gap-2"
      >
        <Col>
          <Flex justifyContent="start" className="gap-2">
            <Button color={colours.default} size="sm" icon={IconRefresh} onClick={props.onRefresh}>Refresh</Button>
            <Button color={colours.default} size="sm" onClick={() => setShowAddDevice(true)}>Add Device </Button>
          </Flex>
        </Col>
        <Col>
          <Flex justifyContent="center" className="gap-2">
            Filter:
            <TabGroup
              defaultIndex ={props.selectedStatus}
              onIndexChange={(value) => props.onSelectedStatusChanged(value)}
              color={colours.default} >
              <TabList variant="solid">
                <Tab value={0}>All</Tab> 
                <Tab value={1}>Offline </Tab>
                <Tab value={2}>Fault</Tab> 
                <Tab value={3}>Good</Tab>  
              </TabList>
            </TabGroup>
          </Flex>
        </Col>
        <Col>
          <Flex justifyContent="end" className="gap-2">
            Display:
            <TabGroup
              defaultIndex={props.selectedView}
              onIndexChange={(value) => props.onSelectedViewChanged(value)} >
              <TabList variant="solid" color={colours.default}>
                <Tab>Grid</Tab>
                <Tab>List</Tab> 
              </TabList>
            </TabGroup>
          </Flex>
        </Col>
      </Grid>

      <Card
        decoration="bottom"
        decorationColor={colours.cardDecoration}
        className="mt-3"
      >
      {props.selectedView === 0 ? (
        <>
          <Grid
            numItemsSm={1}
            numItemsMd={4}
            numItemsLg={4}
            className="gap-4"
          >
            {props.devices
              .filter((device) => isDeviceSelected(device))
              .filter((device) => isStatusSelected(device))
              .map((device) => (
                <Card key={device.uniqueDeviceId} decoration="left" decorationColor="indigo">
                  <Flex justifyContent="end" className="gap-2">
                    {
                      isDeviceInFault(device) &&
                      <Badge 
                        icon={device.ctrlStatIcon} 
                        color={device.ctrlStatColor}
                        tooltip={getDeviceFaults(device)}>
                        {device.ctrlStatDesc}</Badge>
                    }
                    {
                      !isDeviceInFault(device) && isDeviceOffline(device) &&
                      <Badge 
                        icon={device.connStatIcon} 
                        color={device.connStatColor}
                        tooltip={device.connStatDate}>
                          {device.connStatDesc}</Badge>
                    }
                    {
                      !isDeviceInFault(device) && !isDeviceOffline(device) &&
                      <Badge
                        icon={device.connStatIcon} 
                        color={device.connStatColor}
                        tooltip={device.connStatDate > device.ctrlStatDate ? device.connStatDate : device.ctrlStatDate}>
                        GOOD
                      </Badge>
                    }
                  </Flex>
                  <Flex justifyContent="center">
                    <div className="text-center">
                      <Flex justifyContent="start"
                        className="space-x-4">
                        <img src={getControllerIconText_FROM_STRING(device.modelType).icon} alt="Controller Icon" width="40" height="40" />
                        <Metric>{device.userName}</Metric>
                      </Flex>
                    </div>
                  </Flex>
                    <div className="text-center">
                      <Text>{"UID:" + device.uniqueDeviceId}</Text>
                    </div>
                    {/* <Text>{isDeviceInFault(device) ? device.ctrlStatDate : device.connStatDate}</Text> */}
                  
                  
                  {/* <Divider /> */}

                  {/* <Button
                      size="sm"
                      icon={IconError}
                      iconPosition="right"
                      variant="light">View Details</Button> */}

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-3"
                  >
                    <Button
                      icon={IconEdit}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="light"
                      onClick={() => handleEditDeviceShow(device)}
                    />
                    {/* <Button
                      icon={IconTrends}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="light"
                      onClick={() => props.onViewTrends(device.uniqueDeviceId)}
                    />
                    <Button
                      icon={IconError}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="light"
                      onClick={() => props.onViewFaults(device.uniqueDeviceId)}
                    />
                    <Button
                      icon={IconDashboard}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="light"
                      onClick={() => props.onViewDashboard(device.uniqueDeviceId)}
                    /> */}
                    <Button
                      // icon={IconDashboard}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="primary"
                      onClick={() => props.onViewDevice(device.uniqueDeviceId)}>
                      Select
                    </Button>
                    <Button
                      icon={IconQRCode}
                      iconPosition="right"
                      color={colours.default}
                      size="sm"
                      variant="light"
                      onClick={() => handleQRCodeShow(device)}
                    />
                  </Flex>

                </Card>
              ))
            }
          </Grid>
        </>
        ):(
          <>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableHeaderCell>Select</TableHeaderCell> */}
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Name</TableHeaderCell>
                  {/* <TableHeaderCell>Date/Time</TableHeaderCell> */}
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>...</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.devices
                  .filter((device) => isDeviceSelected(device))
                  .filter((device) => isStatusSelected(device))
                  .map((device) => (
                    <TableRow key={device.uniqueDeviceId}>
                      <TableCell> 
                        <Flex
                          justifyContent="start"
                          alignItems="center"
                          className="space-x-3"
                        >
                          <div className="tooltip">
                            <img 
                              src={getControllerIconText_FROM_STRING(device.modelType).icon} 
                              alt="Controller Icon" 
                              width="40" 
                              height="40"/>
                            <span className="tooltiptext">{device.modelType}</span> 
                          </div>
                        </Flex>
                        </TableCell>
                      <TableCell>{device.uniqueDeviceId}</TableCell>
                      <TableCell>{device.userName}</TableCell>
                      {/* <TableCell>{isDeviceInFault(device) ? device.ctrlStatDate : device.connStatDate}</TableCell> */}
                      <TableCell>
                        {
                          isDeviceInFault(device) &&
                          <Badge 
                            icon={device.ctrlStatIcon} 
                            color={device.ctrlStatColor}
                            tooltip={getDeviceFaults(device)}>
                            {device.ctrlStatDesc}
                          </Badge>
                        }
                        {
                          !isDeviceInFault(device) && isDeviceOffline(device) &&
                          <Badge 
                            icon={device.connStatIcon} 
                            color={device.connStatColor}
                            tooltip={device.connStatDate}>
                              {device.connStatDesc}</Badge>
                        }
                        {
                          !isDeviceInFault(device) && !isDeviceOffline(device) &&
                          <Badge
                            icon={device.connStatIcon} 
                            color={device.connStatColor}
                            tooltip={device.connStatDate > device.ctrlStatDate ? device.connStatDate : device.ctrlStatDate}>
                            GOOD
                          </Badge>
                        }
                      </TableCell>
                      <TableCell>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          className="space-x-3"
                        >
                        <Button
                          icon={IconEdit}
                          iconPosition="right"
                          color={colours.default}
                          size="sm"
                          variant="light"
                          onClick={() => handleEditDeviceShow(device)}
                        />
                        <Button
                          iconPosition="right"
                          color={colours.default}
                          size="sm"
                          variant="primary"
                          onClick={() => props.onViewDevice(device.uniqueDeviceId)}>
                          Select
                        </Button>
                        <Button
                          icon={IconQRCode}
                          iconPosition="right"
                          color={colours.default}
                          size="sm"
                          variant="light"
                          onClick={() => handleQRCodeShow(device)}
                        />
                      </Flex>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        )}
        <AddDevice
          open={showAddDevice}
          handleClose={handleAddDevice}
        />
        <EditDevice
          open={showEditDevice}
          device={deviceToEdit}
          handleClose={handleEditDeviceUpdate}
        />
        <ShowDeviceQR
          open={showQRCode}
          device={deviceToEdit}
          handleClose={handleQRDeviceUpdate}
        />
      </Card>
    </div>
  );
}

