
import {
  Flex,

  Card,
  Grid,

  Title,

} from "@tremor/react";



import { 

  DisplayPumpSpeed,
  
  DisplayToggleItem,
  DisplayTimePickerItem,

} from "./DisplayItems";

import {


  MdSpeed as IconSpeed,

  MdOutlineSchedule as IconClock,
} from "react-icons/md";

import {
  colours,
  offOnOptions,
} from "../constants";

import {
  REGS,

} from "../registers";

export const FilterView = (props) => {
  return (
    <Grid
    numItems={1}
    numItemsSm={1}
    numItemsLg={1}
    className="gap-4"
    >
    <Card
        decoration="bottom"
        decorationColor={colours.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-2 space-y-2 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Title color={colours.default}>Filter</Title>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayToggleItem register={props.onGetRegisterData(REGS.FILTER_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
            <DisplayPumpSpeed register={props.onGetRegisterData(REGS.FILTER_TIME_1_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
            
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_2_START_TIME)} icon={IconClock}  onChange={props.onTimesUpdate} />
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
            {/* <DisplayItem register={props.onGetRegisterData(REGS.FILTER_TIME_2_SPEED)} icon={IconPump} /> */}
            <DisplayPumpSpeed register={props.onGetRegisterData(REGS.FILTER_TIME_2_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
          
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_3_START_TIME)} icon={IconClock}  onChange={props.onTimesUpdate} />
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_3_STOP_TIME)} onChange={props.onTimesUpdate} />
            {/* <DisplayItem register={props.onGetRegisterData(REGS.FILTER_TIME_3_SPEED)} icon={IconPump} /> */}
            <DisplayPumpSpeed register={props.onGetRegisterData(REGS.FILTER_TIME_3_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
          
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_4_START_TIME)} icon={IconClock}  onChange={props.onTimesUpdate} />
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.FILTER_TIME_4_STOP_TIME)} onChange={props.onTimesUpdate} />
            <DisplayPumpSpeed register={props.onGetRegisterData(REGS.FILTER_TIME_4_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
          
          {/* <DisplayItem register={props.onGetRegisterData(REGS.FILTER_TIME_4_SPEED)} icon={IconPump} /> */}
          </Flex>

        </div>
      </Card>
    </Grid>
  );
}