import {
 
 Card,
  Text,
  Flex,
  Grid,
} from "@tremor/react";

import {
  // MdOutlineArrowBack as IconBack,
  // MdOutlineSync as IconSync,
  // MdOutlineSensors as IconConnected,
  // MdOutlineSensorsOff as IconDisconnected,
  MdOutlineThermostat as IconThermometer,
  // MdPool as IconPool,
  // MdHotTub as IconSpa,
  // MdRoofing as IconSolar,
  // MdWbTwighlight as IconLight,
  // MdOutlineTimer as IconTimer,
  MdFormatListNumbered as IconList,
  // MdOutlineInfo as IconInfo,
  // MdSpeed as IconSpeed,
  // MdOutlineWifi as IconWifi,
  // MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  // MdOutlineSettings as IconSettings,
} from "react-icons/md";

import { 
  
  DisplayController,
  DisplayItem,

  DisplayText, 
 
  DisplayToggleItem,
 
  DisplaySelectPickerItem,
 
} from "./DisplayItems";

import {
  colours,
  // offOnOptions,
  ResetOptionsSelect,
  logIntervalOptions,
  DisableEnableOptions,
} from "../constants";

import {
  TbPlug as IconSocket,
} from "react-icons/tb";


import {

  REGS,
} from "../registers";

export const SystemInfo = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      
       <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div
              className="max-w-none mt-2 space-y-2 text-left"
              >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <Text color={colours.default}>System Info</Text>
              </Flex>

              <Grid
                  numItems={2}
                  numItemsSm={1}
                  numItemsLg={2}
                  className="gap-4"
                >

            
                <DisplayController register={props.getRegisterData(REGS.MODEL)} />
                <DisplayItem register={props.getRegisterData(REGS.RTC_YEAR)} icon={IconClock} />
                <DisplayItem register={props.getRegisterData(REGS.AMBIENT_TEMPERATURE)} icon={IconThermometer} />
                <DisplaySelectPickerItem register={props.getRegisterData(REGS.RESET_OPTIONS)} icon={IconSocket} options={ResetOptionsSelect} onChange={props.handlePrivateRegisterUpdate} colour={colours.default} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.FIRMWARE_VERSION)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.EXP1_VERSION)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.EXP2_VERSION)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.EXPANSION_PLUS_VERSION)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.IOCTRL_VERSION)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.OUTPUTS_STATUS)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.INPUTS_STATUS)} />
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.TOTAL_HOURS)} />
              
                <DisplayText tc={colours.default} text="" register={props.getRegisterData(REGS.HARDWARE_VERSION)} />
                <DisplayText tc={colours.default} text="Faults:" register={props.getRegisterData(REGS.FAULT_FLAGS)} />
                <DisplayItem register={props.getRegisterData(REGS.RESTART_COUNT)}/>
                <DisplayItem register={props.getRegisterData(REGS.BOOTUP_COUNT)}/>
              
                <DisplayToggleItem register={props.getRegisterData(REGS.LOGGING_REMOTE)} icon={IconList} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplaySelectPickerItem register={props.getRegisterData(REGS.LOGGING_INTERVAL)} icon={IconClock} options={logIntervalOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />

              </Grid>


            </div>
        </Card>
    </Grid>
    )
}