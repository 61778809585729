

import React, { useState, useEffect } from "react";

import {
//   AccordionList,
  Accordion,
  AccordionHeader,
  AccordionBody,
 
  Bold,
  Button,
  Text,
  Flex,
  Icon,
//   Card,
  TabGroup,
  TabList,
  Tab,
//   Title,


} from "@tremor/react";

import { InputNumber,
   MaskedInput,
   InputGroup,
   Slider,
   InputPicker,
   DatePicker
 } from "rsuite";

  
 import {

  MdOutlineCheck as IconConfirm,
  MdOutlineEdit as IconEdit,
  
} from "react-icons/md";


import IconAquatek from '../images/aquatek.png'
import IconWaterCo from '../images/waterco.png'
import IconOasis from '../images/oasis.png'
import IconTheralux from '../images/theralux.png'
import IconAqualight from '../images/aqualight.png'

import IconUnknown from '../images/unknown.png'


import IconBrandAquatek from '../images/logo_aquatek.png'
import IconBrandWaterCo from '../images/logo_waterco.png'
import IconBrandHenden from '../images/logo_henden.png'
import IconBrandOasis from '../images/logo_oasis.png'
import IconBrandTheralux from '../images/logo_theralink.png'
import IconBrandAqualight from '../images/logo_aqualight.png'
import IconBrandUnknown from '../images/unknown.png'


import {
  colours,
  PumpSpeedOptions,
  offOnAutoOptions,
  ControllerStringDesc,
  controller_t,
} from "../constants";

export const DisplayItem = ({ register, icon , onClick}) => {
  return (
    <Flex
      justifyContent="start"
      className="space-x-4"
    >
      {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
      
      {/* {icon!==undefined?(<Button icon={icon} toolTip={register.address} variant="secondary" color={colours.default} size="sm" />):' '} */}
      <div className="truncate space-y-0.5">
        <Text>{register.title}</Text>
        <Text><Bold>{register.display}</Bold></Text>
      </div>
    </Flex>
  );
}

export const DisplayText = ({ tc, register, text }) => {
  return (
    <div className="truncate">
      <Text color={tc}><Bold>{text} {register.title} </Bold>{register.display}</Text>
    </div>
  );
}

export const convertDeviceToIconNumber = (controllerString) =>
{
  var the_icon = 254;
  if ( controllerString!== undefined)
  {
    for ( let itr = 0; itr < ControllerStringDesc.length; ++itr)
    {
      if ( ControllerStringDesc[itr].label.toUpperCase() === controllerString.toUpperCase())
      { 
        the_icon = ControllerStringDesc[itr].value;
      }
    }
  }
  return the_icon;
}

export const getControllerIconText_FROM_STRING = (controllerString) =>
{
  var the_icon = convertDeviceToIconNumber(controllerString);
 
 return getControllerIconText(the_icon);
}

export const getControllerIconText = (controllerValue) =>
{ 
    var theIcon = IconUnknown
    var theText = "Unknown";
    switch (controllerValue) {
        case controller_t.AQUATEK: theIcon = IconAquatek; theText = "Aquatek"; break;
        case controller_t.THERALUX: theIcon = IconTheralux; theText = "Theralink"; break;
        case controller_t.OASIS: theIcon = IconOasis; theText = "Oasis Smart"; break;
        case controller_t.AQUALIGHT: theIcon = IconAqualight; theText = "Aqualight"; break;
        case controller_t.WATERCO: theIcon = IconWaterCo; theText = "Pooltek"; break;
        case controller_t.HENDEN: theIcon = IconWaterCo; theText = "Henden"; break;
        default: break;
    }
    return { icon: theIcon, Text: theText }
};

export const getControllerBrandIcon_FROM_STRING = ( controllerString) =>
{
  var the_icon = convertDeviceToIconNumber(controllerString);
  
  return getControllerBrandIcon(the_icon);

}

export const getControllerBrandIcon = (controllerValue) =>
{ 
    var brandIcon = IconBrandUnknown
   
    switch (controllerValue) {
        case controller_t.AQUATEK: brandIcon = IconBrandAquatek; break;
        case controller_t.THERALUX: brandIcon = IconBrandTheralux; break;
        case controller_t.OASIS: brandIcon = IconBrandOasis; break;
        case controller_t.AQUALIGHT: brandIcon = IconBrandAqualight;  break;
        case controller_t.WATERCO: brandIcon = IconBrandWaterCo; break;
        case controller_t.HENDEN: brandIcon = IconBrandHenden; break;
        default: break;
    }
    // console.log("the ionc", brandIcon)
    return brandIcon;
  };


export const DisplayController = ({ register }) => {
  return (
    <Flex
      justifyContent="start"
      className="space-x-2"
    >
      <div>
        <img src={getControllerIconText(register.value).icon} alt="Controller Icon" width="40" height="40" />
      </div>
      <Text><Bold>{getControllerIconText(register.value).Text}</Bold></Text>
    </Flex>
  );
}

export const DisplayListAccordian = ({ register, icon }) => {

  const getNewLInes = (value) => {

    var theString = "";
    if (value) {
      theString = value.replaceAll("|", "\n");
    }
    return (theString);
  };

  const getTriggers = (value) => {
    var theString2 = ""
    if (value) {
      theString2 = value.replaceAll("|", ",");
    }
    return (theString2);
  };

  return (
    <Flex
      justifyContent="start"
      className="space-x-2"
    >
      <Icon
        icon={icon}
        color={colours.default}
        size="md"
        variant="shadow"
      />
      <Accordion>
        <AccordionHeader>{register.title} {getTriggers(register.display)}</AccordionHeader>
        <AccordionBody>
          {getNewLInes(register.display)}
        </AccordionBody>
      </Accordion>

    </Flex>
  );
}

export const DisplaySliderItem = ({ register, icon, min, max, step, onFormat, onChange }) => {
  const [value, setValue] = useState(min);
  const formatValue = (value) => {
    if (onFormat !== undefined) {
      return onFormat(value, "");
    } else {
      return value;
    }
  };

  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <Icon
          icon={icon}
          color={colours.default}
          size="md"
          variant="outlined"
        />
        <div className="truncate space-y-1 w-1/2" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={formatValue}
          />
        </div>
      </Flex>
    </>
  );
}

export const DisplayStepperItem = ({ register, icon, min, max, step, onChange }) => {
  const [value, setValue] = useState(min);
 
  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value / step);
  };

  useEffect(() => {

      setValue(register.value * step);
  }, [register.value, step]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >
        <Icon
          icon={icon}
          color={colours.default}
          size="md"
          variant="outlined"
        />
        <div className="truncate" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputNumber
            min={min}
            max={max}
            step={step}
            scrollable={false}
            postfix={register.unit}
            value={value}
            onChange={updateValue}
          />
        </div>
      </Flex>
    </>
  );
}


export const DisplayPumpSpeed = ({ register, icon, onFormat, onChange }) => {
  const [value, setValue] = useState(1);
 
  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <Icon
          icon={icon}
          color={colours.default}
          size="md"
          variant="outlined"
        />
       
        <div className="truncate space-y-1 w-1/2" >
          <Text className="">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div className="truncate space-y-2 w-1/2 h-full" >
           <Flex
            justifyContent="end"
            className="space-x-4"
          >
          <TabGroup onIndexChange={updateValue} defaultIndex={value} color={colours.default}>
            <TabList variant="solid">
              {PumpSpeedOptions.map((item) => ( 
                <Tab key={item.value}>{item.label} </Tab>
                //   {/* key={item.value}
                //   value={item.value}
                //   text=
                // /> */}
              ))}
            </TabList>
          </TabGroup>
          </Flex>
        </div>
      </Flex>
    </>
  );
}



export const DisplayTimePickerItem = ({ register, icon, onChange }) => {

  const [value, setValue] = useState(null);

  const updateValue = (value, event) => {
    console.log("Date time picker Changed", event, value);
    setValue(value);
    onChange(register, value);
  };


  var makeDateFromTime = (data)=>
  {
    var DAte = new Date( 2023, 0, 1, get_high_low(data).high, get_high_low(data).low, 21 , 22)
    // console.log("New Date", DAte, data);
    return DAte;
  }


  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
       {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
       
        <div className="truncate space-y-0.5 w-1/2" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
      
        <div className="truncate space-y-0.5 w-1/2 ">
        <Flex
        justifyContent="end"
        className="space-x-4"
      >
        <DatePicker
          format="HH:mm"
          ranges={[]}
          size="sm"
          value={makeDateFromTime( value) }
          defaultValue={makeDateFromTime( value) }
          onChange={updateValue}
          // hideSeconds={true}
        />

        </Flex>
        </div>
      </Flex>
      
    </>
  );
}


export const DisplaySocketStatusItem = ({ mode_register, status_register, icon, options, onChange }) => {
  
  const [Modevalue, setModeValue] = useState(null);
  
  const updateModeValue = (Modevalue, event) => {
    console.log(event, Modevalue);
    setModeValue(Modevalue);
    onChange(mode_register, Modevalue);
  };

  useEffect(() => {
    setModeValue(mode_register.value);
  }, [mode_register.value]);
  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
       
        <Flex
        justifyContent="start"
        className="space-x-2"
        >
        <div>
          <Text >{status_register.title}</Text>
        </div>
        {(status_register.display==='ON'?(<Text color="green"><Bold>{status_register.display}</Bold> </Text>):(status_register.display==='OFF'?(<Text color="red"><Bold>{status_register.display}</Bold> </Text>):<Text color="orange" ><Bold>{status_register.display}</Bold> </Text>))}
        </Flex>      
      
        <Text>Mode: </Text>
        <TabGroup color={colours.default} index={Modevalue} onIndexChange={updateModeValue} className="mt-0">
          <TabList variant="solid">
              {offOnAutoOptions.map((item) => ( 
                <Tab key={item.value} value={item.value}>{item.label}</Tab>
                //   
                //   
                //   text=
                // />
              ))}
            </TabList>
          </TabGroup>
      </Flex>
    </>
  );
}

/*!
displays the Sockt type
*/
export const DisplaySocketItem = ({ mode_register, name_register, type_register, icon, options, onChange, onChangeName, name }) => {
  const [value, setValue] = useState(null);
  // const [Modevalue, setModeValue] = useState(null);
  const updatePickerValue = (value, event) => {
    console.log(event, value);
    setValue(value);
    onChange(type_register, value);
  };

  // const updateModeValue = (Modevalue, event) => {
  //   console.log(event, Modevalue);
  //   setModeValue(Modevalue);
  //   onChange(mode_register, Modevalue);
  // };

  useEffect(() => {
    setValue(type_register.value);
  }, [type_register.value]);

  // useEffect(() => {
  //   setModeValue(mode_register.value);
  // }, [mode_register.value]);
  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
        {/* <div className="truncate" >  */}
        
        
        {/* <Text> <Bold>{name}</Bold></Text> */}
          <Text >{type_register.title}</Text>
        {/* </div> */}
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputPicker
            block
            cleanable={false}
            searchable={false}
            placement="autoVerticalStart"
            data={options}
            value={value}
            onChange={updatePickerValue}
          />
    
        </div>
        {name_register!==undefined?(<DisplayNameInputItem register={name_register} onChange={onChangeName} />):' '}
      </Flex>
    </>
  );
}


export const DisplaySelectPickerItem = ({ register, icon, options, onChange }) => {
  const [value, setValue] = useState(null);

  const updateValue = (value, event) => {
    console.log(event, value);
    setValue(value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
      
        <div className="truncate" style={{
          display: "block",
          width: "50%" }}>
          <Text className="w-2,truncate">{register.title}</Text>
          <Text className="w-2, truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "50%"
        }}>
          <InputPicker
            block
            cleanable={false}
            searchable={false}
            placement="autoVerticalStart"
            data={options}
            value={value}
            onChange={updateValue}
          />
        </div>
      </Flex>
    </>
  );
}

export const DisplayNameInputItem = ({ register, icon, onChange }) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  const mask = [/\w/, /\w/, /\w/, /\w/,/\w/, /\w/, /\w/, /\w/,/\w/, /\w/, /\w/, /\w/,/\w/, /\w/, /\w/, /\w/];

  const updateValue = () => {
    if(disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
      onChange(register, value);
      console.log("name change", value);
    }
  };

  useEffect(() => {

    if ( register.display !== undefined)
    {
      setValue(register.display);
    }
  }, [register.display]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
        <div className="truncate" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate" color={colours.default} ><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputGroup inside>
            <MaskedInput
              value={value}
              mask={mask}
              disabled={disabled}
              placeholderChar = " "
              onChange={setValue}
            />
            <InputGroup.Button onClick={updateValue}>
              <Icon
                icon={disabled ? IconEdit : IconConfirm}
                color={colours.default}
                size="sm"
                variant="simple"
              />
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Flex>
    </>
  );
}

export const DisplayNumberInputItem = ({ register, icon, onChange }) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  const mask = [/\d/, /\d/, /\d/, /\d/];

  const updateValue = () => {
    if(disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
      const registerValue = parseInt(value, 10);
      if(!isNaN(registerValue)) {
        onChange(register, registerValue);
      }
    }
  };

  useEffect(() => {
    setValue(register.display);
  }, [register.display]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <Icon
          icon={icon}
          color={colours.default}
          size="md"
          variant="outlined"
        />
        <div className="truncate" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputGroup inside>
            <MaskedInput
              value={value}
              mask={mask}
              disabled={disabled}
              onChange={setValue}
            />
            <InputGroup.Button onClick={updateValue}>
              <Icon
                icon={disabled ? IconEdit : IconConfirm}
                color={colours.default}
                size="sm"
                variant="simple"
              />
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Flex>
    </>
  );
}


export const DisplayToggleItem = ({ register, icon, onChange, colour, options, inhibitText }) => {
  const [value, setValue] = useState(register.value);

  const updateValue = (value) => {
    setValue(value);
    console.log("toggled", value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);
  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        {icon!==undefined?(<Icon icon={icon} color={colours.default} size="md" variant="outlined"/>):' '}
        {inhibitText===undefined?(<div className="truncate space-y-1 w-1/2" ><Text className="truncate">{register.title}</Text><Text className="truncate"><Bold>{register.display}</Bold></Text> </div>):' '}
        <div className="w-1/2 space-y-1" style={{
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Flex
            justifyContent="end"
            className="space-x-4 "
          >
            <TabGroup color={colour} onIndexChange={updateValue} defaultIndex={value } index={value}  >
              <TabList variant = "solid" key={value} >
                {options.map((item) => (
                  <Tab key={item.value}>{item.label}</Tab>
                    // 
                    // value={item.value}
                    // text={item.label}
                  // />
                ))}
              </TabList>
            </TabGroup>
          </Flex>
        </div>
      </Flex>
    </>
  );
}


export const DisplayButtonItem = ({ register, iconOn, iconOff, textOn, textOff, onChange }) => {
  const [value, setValue] = useState(register.value);
  const updateValue = () => {
  let tempValue = value;
    if (register.value !== 1) {
      tempValue = 1;
    }
    else {
      tempValue = 0;
    }
    setValue(tempValue);
    onChange(register, tempValue);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);



  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >

        <Button icon={register.value !== 0 ? iconOn : iconOff} onClick={updateValue}> {register.display} </Button>

        <div className="truncate" >
          <Text className="truncate">{register.title}</Text>
          <Text className="truncate"><Bold>{register.display}</Bold></Text>
        </div>

      </Flex>
    </>
  );
}

export const get_high_low = (value) => {
  const hh = (value & 0xFF00) >> 8;
  const ll = (value & 0x00FF);

  return {
    high: hh,
    low: ll
  };
}


// const DialItem = ({ register, icon, min, max, step, onFormat, onChange }) => {
//   const [value, setValue] = useState(min);

//   const formatValue = (value) => {
//     if(onFormat !== undefined) {
//       return onFormat(value, "");
//     } else {
//       return value;
//     }
//   };

//   const updateValue = (value) => {
//     console.log( value);
//     onChange(register, value);
//   };

//   useEffect(() => {
//     setValue(register.value);
//   }, [register.value]);

//   return (
//     <>
//       <Flex
//         justifyContent="start"
//         className="space-x-2"
//       >
//         <Icon
//           icon={icon}
//           color={colours.default}
//           size="md"
//           variant="outlined"
//         />
//         <div className="truncate" >
//           <Text className="truncate">{register.title}</Text>
//           <Text className="truncate"><Bold>{register.display}</Bold></Text>
//         </div>
//         <div style={{
//           display: "block",
//           width: "100%",
//           paddingLeft: 10,
//           paddingRight: 5
//         }}>
//         <div >

//         <Knob value={value} 
//               min={ min} 
//               max={max} 
//               size={80}
//               valueColor={coloursPrime.error }  
//               rangeColor={coloursPrime.default}
//               textColor = {coloursPrime.good}
//               onChange={(e) => setValue(e.value)}/>
//                 </div>

//         </div>
//       </Flex>
//     </>
//   );
// }

// const [value, setName] = useState(1);

// const [deviceName, setDeviceName] = useState<string[]>([]);

// const setName = (name) => {
  
//   setDeviceName(prevState => {


//   // check if it is already added
//   if(prevState.includes(id)) { 
//     // clone the prevState arr to prevent side effects  
//      const clone = [...prevState];
//    // Remove the existing id 
//      clone.splice(prevState.indexOf(id), 1)
//      return clone;
//   } else {
//      return [...prevState, id]
//   }});
//   onClickAvatar();
// };