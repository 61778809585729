import {

  Text,
  Flex,
 
  Card,
  Grid,


} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPumpSpeed,
 
  DisplaySliderItem,

  DisplayToggleItem,
 
  DisplayTimePickerItem,
 
} from "./DisplayItems";

import {
 
  MdOutlineThermostat as IconThermometer,
  MdPool as IconPool,
  MdHotTub as IconSpa,
  MdRoofing as IconSolar,
 
  MdOutlineInfo as IconInfo,
  MdSpeed as IconSpeed,
 
  MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  GiBubbles as IconBubbles,
 
  GiWinterHat as IconWinter,
  GiThermometerCold as IconFrozen,
  GiHeatHaze as IconHeat,
} from "react-icons/gi";

import {
  TbEngine as IconPump,
} from "react-icons/tb";


import {
  colours,
  
  offOnOptions,
  DisableEnableOptions,
  heaterOffOnOptions,
  heatingPumpTypes,
} from "../constants";

import {

  REGS,

} from "../registers";

export const HeatingView = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div
              className="max-w-none mt-2 space-y-4 text-left"
               >

              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2 " >
                <Text color={colours.default}>Heating Status</Text>
              </Flex>
              <Grid
                numItems={2}
                numItemsSm={2}
                numItemsLg={4}
                className="space-y-4 px-4 gap-x-4"
              >
                <DisplayItem register={props.getRegisterData(REGS.HEATER_STATE)} icon={IconInfo} />
                <DisplayItem register={props.getRegisterData(REGS.HEATER_2_STATE)} icon={IconInfo} />
                <DisplayItem register={props.getRegisterData(REGS.POOL_TEMPERATURE)} icon={IconThermometer} />
                <DisplayItem register={props.getRegisterData(REGS.ROOF_TEMPERATURE)} icon={IconThermometer} />
                <DisplayItem register={props.getRegisterData(REGS.WATER_TEMPERATURE)} icon={IconThermometer} />
                <DisplayItem register={props.getRegisterData(REGS.THERMISTOR_1)} icon={IconThermometer} />
                <DisplayItem register={props.getRegisterData(REGS.THERMISTOR_2)} icon={IconThermometer} />
              </Grid>

              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-4" >
                <Text color={colours.default}>Heating Setup</Text>
              </Flex>

              <Grid
                numItems={2}
                numItemsSm={1}
                numItemsLg={3}
                className="space-y-4 px-4 gap-x-4"
              >
              <DisplayToggleItem register={props.getRegisterData(REGS.GAS_HEATER_ENABLE)} icon={IconSettings} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayToggleItem register={props.getRegisterData(REGS.HEAT_PUMP_ENABLE)} icon={IconSettings} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayToggleItem register={props.getRegisterData(REGS.HEAT_DEMAND_ENABLE)} icon={IconHeat} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            
              <DisplayTimePickerItem register={props.getRegisterData(REGS.HEAT_DEMAND_START_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
              <DisplayTimePickerItem register={props.getRegisterData(REGS.HEAT_DEMAND_STOP_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
  
              
              <DisplayToggleItem register={props.getRegisterData(REGS.HEATING_PUMP_TYPE)} icon={IconPump} options={heatingPumpTypes}  onChange={props.handleRegisterUpdate} colour={colours.default}/>
              <DisplayToggleItem register={props.getRegisterData(REGS.HEAT_DEMAND_SANITISER_ENABLE)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayPumpSpeed register={props.getRegisterData(REGS.HEATING_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
                
              <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_SOLAR_PRIORITY)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplaySliderItem register={props.getRegisterData(REGS.HEATER_ROOFTEMP_CUTOFF)} icon={IconThermometer} min={40} max={140} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
            
              <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_USES_WATER)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_HYDROTHERAPY_MODE)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_CHILL_FUNC)} icon={IconWinter} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />

              <DisplayTimePickerItem register={props.getRegisterData(REGS.GAS_HEATER_COOL_DOWN_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
              <DisplayToggleItem register={props.getRegisterData(REGS.RUN_TILL_HEATED_FUNC)} icon={IconRun} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              </Grid>
              <Grid
                numItems={1}
                numItemsSm={1}
                numItemsLg={2}
                className="space-y-4 px-4 gap-x-4"
              >
                <DisplaySliderItem register={props.getRegisterData(REGS.POOL_SET_TEMPERATURE)} icon={IconPool} min={0} max={80} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.SPA_SET_TEMPERATURE)} icon={IconSpa} min={0} max={80} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
               
              </Grid>
            </div>
          </Card>

          <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-2 space-y-4">
              
              <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-4" >
              <Text color={colours.default}>Heating 2 Setup</Text>
              </Flex>
              <Grid
                numItems={2}
                numItemsSm={1}
                numItemsLg={3}
                className="space-y-4 px-4 gap-x-4"
              >
                <DisplayToggleItem register={props.getRegisterData(REGS.HEAT_PUMP_2_ENABLE)} icon={IconSolar} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.getRegisterData(REGS.GAS_HEATER_2_ENABLE)} icon={IconSolar} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_2_USES_WATER)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_2_PUMP_TYPE)} icon={IconPump} options={heatingPumpTypes}  onChange={props.handleRegisterUpdate} colour={colours.default}/>
                <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_2_HYDROTHERAPY_MODE)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayTimePickerItem register={props.getRegisterData(REGS.GAS_HEATER_2_COOL_DOWN_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} />
                <DisplayToggleItem register={props.getRegisterData(REGS.CHILL_2_FUNCTION)} icon={IconFrozen} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayToggleItem register={props.getRegisterData(REGS.HEAT_DEMAND_SANITISER_2_ENABLE)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplayPumpSpeed register={props.getRegisterData(REGS.HEAT_2_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} />
                <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_2_SOLAR_PRIORITY)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplaySliderItem register={props.getRegisterData(REGS.HEATER_2_ROOFTEMP_CUTOFF)} icon={IconThermometer} min={40} max={140} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
              </Grid>
              <Grid
                numItems={1}
                numItemsSm={1}
                numItemsLg={2}
                className="space-y-4 px-4 gap-x-4"
              >
                <DisplaySliderItem register={props.getRegisterData(REGS.POOL_2_SET_TEMPERATURE)} icon={IconPool} min={0} max={80} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
                <DisplaySliderItem register={props.getRegisterData(REGS.SPA_2_SET_TEMPERATURE)} icon={IconSpa} min={0} max={80} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
                <DisplayToggleItem register={props.getRegisterData(REGS.HEATER_2_TRACKING_ENABLE)} icon={IconInfo} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <DisplaySliderItem register={props.getRegisterData(REGS.HEATER_2_TRACKING_OFFSET)} icon={IconSpa} min={0} max={20} step={2} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} />
                <DisplayToggleItem register={props.getRegisterData(REGS.RUN_TILL_HEATED_2_FUNC)} icon={IconRun} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
              </Grid>
             
            </div>
          </Card>
          </Grid>
    )
}