import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
//import config from './aws-exports';


import { AWSIoTProvider } from "@aws-amplify/pubsub";

import awsExports from './aws-exports';
Amplify.configure(awsExports);
//Amplify.configure(config);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'ap-southeast-2',
    aws_pubsub_endpoint: 'wss://a219g53ny7vwvd-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
    
    // a254daig9zo2wn-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
  })
);

if(process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
