import {
 
  Text,
  Flex,

  Card,
  Grid,
  Title,
} from "@tremor/react";

import { 

  DisplayItem,

  DisplaySliderItem,
  DisplayStepperItem,

  DisplayNameInputItem,
  DisplayToggleItem,
  DisplayNumberInputItem,
  DisplayTimePickerItem,
  DisplaySelectPickerItem,
  DisplaySocketItem,

} from "./DisplayItems";

import {

  MdOutlineThermostat as IconThermometer,
  MdPool as IconPool,
  MdHotTub as IconSpa,

  MdWbTwighlight as IconLight,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";

import {
  GiValve as IconValve,
} from "react-icons/gi";

import {
  TbEngine as IconPump,
  TbPlug as IconSocket,
} from "react-icons/tb";

import {
  colours,
  offOnOptions,
  DisableEnableOptions,
  thermistorTypeOptions,
  SocketTypesSelect,
  ValveTypesSelect,
  VFTypesSelect,
  deviceOptionsSelect,

} from "../constants";

import {
  REGS,
} from "../registers";

import { 

  formatSensorOffset,
} from "./RegistersHandler";

export const SystemSettingsView = (props) => {
  
  return (
    <Grid
    numItems={1}
    numItemsSm={1}
    numItemsLg={1}
    className="gap-4"
    >
      <Card
        decoration="bottom"
        decorationColor={colours.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-0 space-y-4 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={colours.default}>System Setup</Text>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.DEVICE_NAME_START)} icon={IconSocket} onChange= {props.onNameRegisterUpdate} />
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.POOL_NAME_START)} icon={IconPool} onChange= {props.onNameRegisterUpdate} />
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.SPA_NAME_START)} icon={IconSpa} onChange= {props.onNameRegisterUpdate} />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_1_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_2_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_3_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplaySliderItem register={props.onGetRegisterData(REGS.THERMISTOR_1_OFFSET)} icon={IconThermometer} min={-5} max={5} step={1} onFormat={formatSensorOffset} onChange={props.handleRegisterUpdate} />
            <DisplaySliderItem register={props.onGetRegisterData(REGS.THERMISTOR_2_OFFSET)} icon={IconThermometer} min={-5} max={5} step={1} onFormat={formatSensorOffset} onChange={props.handleRegisterUpdate} />
            <DisplaySliderItem register={props.onGetRegisterData(REGS.THERMISTOR_3_OFFSET)} icon={IconThermometer} min={-5} max={5} step={1} onFormat={formatSensorOffset} onChange={props.handleRegisterUpdate} />
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_CALL_ENABLE)} icon={IconThermometer} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayStepperItem register={props.onGetRegisterData(REGS.PUMP_CALL_RUN_ON_TIME)} icon={IconClock} step={5} min={0} max={30} onChange={props.handleRegisterUpdate}/>
            <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_CALL_ENABLED_ITEMS)} icon={IconThermometer} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          </Flex>
          
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >


            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE1_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_1_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_1_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_1_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_1_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_1_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE2_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_2_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_2_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_2_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_2_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_2_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_3_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE3_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_3_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_3_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_3_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_3_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_3_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_4_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE4_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_4_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_4_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_4_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_4_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_4_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            
            
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_5_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE5_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_5_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_5_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_5_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_5_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_5_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_6_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE6_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_6_TIME_ENABLE)}  options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_6_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_6_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_6_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_6_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
            </div>
            </Card>
          </Flex>


          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_7_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE7_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_7_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_7_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_7_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_7_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_7_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_8_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE8_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.SOCKET_8_TIME_ENABLE)}  options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_8_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_8_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_8_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOCKET_8_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE9_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VALVE_1_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_1_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_1_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_1_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_1_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE10_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VALVE_2_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_2_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_2_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_2_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_2_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_3_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE11_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VALVE_3_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_3_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_3_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_3_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_3_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_4_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE12_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VALVE_4_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_4_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_4_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_4_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VALVE_4_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE13_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={colours.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE14_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-2" >
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_START_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                    <DisplayTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_STOP_TIME)} onChange={props.onTimesUpdate} />
                </Flex>

              </div>
            </Card>
          </Flex>
          {/* <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE13_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
            <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE14_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} />
          
          </Flex> */}

          <Grid
                numItems={2}
                numItemsSm={2}
                numItemsLg={3}
                className="gap-4"
              >

        
            <DisplayToggleItem register={props.onGetRegisterData(REGS.SLAVE_1_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.SLAVE_2_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.SLAVE_3_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayNumberInputItem register={props.onGetRegisterData(REGS.SLAVE_1_ADDRESS)} icon={IconSettings} onChange={props.handleRegisterUpdate} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_1_CONFIGURE)} icon={IconSettings} />
            <DisplayNumberInputItem register={props.onGetRegisterData(REGS.SLAVE_2_ADDRESS)} icon={IconSettings} onChange={props.handleRegisterUpdate} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_2_CONFIGURE)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.HEATER_EXPANSION_PRESENT)} icon={IconSettings} />
          </Grid>
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={colours.default}>Lights</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHT_1_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHT_2_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHT_3_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHTS_1_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHTS_2_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHTS_3_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.LIGHTS_LINKED)} icon={IconLight} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          </Grid>
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={colours.default}>Other</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
          <DisplayToggleItem register={props.onGetRegisterData(REGS.ENABLE_UUID)}     icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.OTA_AUTH)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.OTA_HTTPS_ONLY)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.TEST_OUTPUT)}     icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.TEST_420MA)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />

          <DisplayToggleItem register={props.onGetRegisterData(REGS.FILTER_TIMEn_ENABLE)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.FWUPDATE_EXPANSION_PLUS)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.TEST_DISPLAY)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />
          <DisplayNumberInputItem register={props.onGetRegisterData(REGS.DEVICE_PASSCODE)} icon={IconSettings} onChange={props.onPrivateRegisterUpdate} />
          <DisplayToggleItem register={props.onGetRegisterData(REGS.LOGGING_ENABLE)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={colours.default} />

          <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.SET_MODEL)} icon={IconPump} options={deviceOptionsSelect} onChange={props.onPrivateRegisterUpdate} colour={colours.default} />
          <DisplaySliderItem register={props.onGetRegisterData(REGS.LCD_CONTRAST)} icon={IconLight} min={0} max={60} step={5} onChange={props.handleRegisterUpdate} />
          </Grid>

          </div>
      </Card>
        
    </Grid>
  );
}