/* global BigInt */
import React, { useState } from "react";
import {QRCodeSVG} from 'qrcode.react';
import { Text, TextField } from "@aws-amplify/ui-react";
import { CRC8 , 
  CRC8_POLY} from "../other/crc8";
import {
  
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  Subtitle,
  Title,
} from "@tremor/react";

import { CSVLink } from "react-csv";
import DateRangePicker from "rsuite/DateRangePicker";
import Modal from "rsuite/Modal";
import Progress from "rsuite/Progress";

import {
  MdOutlineAddCircleOutline as IconAdd,
  MdOutlineEdit as IconEdit,
  MdQrCode2 as IconQRCode

} from "react-icons/md";

import { isSameDay, lightFormat, parse } from "date-fns";

import { colours,
  //  coloursHex, 
  datetimeFormats, 
  controller_t,
  controller_crc_value
} from "../constants";

import { 
  getControllerBrandIcon_FROM_STRING,
  convertDeviceToIconNumber,
  getControllerIconText_FROM_STRING
} from "./DisplayItems"

const { combine, allowedMaxDays, afterToday } = DateRangePicker;

const checkNameValid = (name) => {
  const regex = new RegExp("^([-A-Za-z0-9_() ]){1,20}$");
  return regex.test(name);
}

const checkIdValid = (id) => {
  const regex = new RegExp("^([0-9]){17,}$");
  return regex.test(id);
}

const dateField = "Date/Time";

const csvLinkStyle = {
  backgroundColor: "#0ea5e9",
  borderRadius: 6,
  color: "#fff",
  fontSize: 12,
  fontWeight: 500,
  padding: "6px"
};


export const get_UID_from_db = (value) => {
  var built_UID = value;
  if ( undefined !== built_UID)
  {
    built_UID = built_UID.substring(4,21);
  }
  // console.log ( "Built_UID:; ",built_UID);
  //  464142203461
  //  46414220346172531

  return built_UID
}


export const get_short_UID_from_db = (value) => {
  var long_UID = get_UID_from_db(value);
  var built_UID = undefined;
  if ( undefined !== long_UID)
  {
    if ( long_UID.startsWith("UID"))
    {
      built_UID = long_UID.substring(16,3);
      console.log ( "Built short UID:; ",built_UID, " from ", long_UID);
    }
    else
    {
      built_UID = long_UID.substring(16,0);
    }
  }
  
  //  464142203461
  //  46414220346172531

  return built_UID
}

export const get_recordID_from_UID = (value) => {
  var built_recordID = "UID_";
  if ( undefined !== value)
  {
    built_recordID+= value+"_DT_XXXX"
  }
  console.log ( "built_recordID:; ",built_recordID);
  //  464142203461
  //  46414220346172531

  return built_recordID
}

export const AddDevice = ({ open, handleClose }) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [idValid, setIdValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [devType, setType] = useState(controller_t.NONE)
  const validateId = (e) => {

    var value = e.currentTarget.value
   
    if ( value.length === 17) //UID
    {
        //Need to determine the type from the UID
        setType( getType_from_UID(value) );
    }
    else if ( value.length === 12 ) // MAC address
    {
      //Convert value to MAC address.
      value = encode_mac_address(value,controller_t.AQUATEK );
    }

    console.log ( "validateId:", e.currentTarget.value, " length:", value.length,"value encoded: ", value )
    setId(value);
    setIdValid(checkIdValid(value))
  };

  const validateName = (e) => {
    setName(e.currentTarget.value);
    setNameValid(checkNameValid(e.currentTarget.value));
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconAdd} color={colours.default} />
            <Subtitle color={colours.default}>Add new controller</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-2">
          <TextField
            label="ID"
            placeholder="Enter a valid ID"
            maxLength={17}
            size="small"
            required
            onChange={validateId}
          />
          <TextField
            label="Name"
            placeholder="Enter a valid Name"
            maxLength={20}
            size="small"
            required
            onSelect={validateName}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
          <Button color={colours.default} size="sm" onClick={() => handleClose({ id, name, devType })} disabled={!idValid || !nameValid}>Confirm</Button>
          <Button color={colours.default} size="sm" variant="secondary" onClick={() => handleClose(null)}>Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}


export const getMacAddress = (deviceId) => {
  var mac = 0;
  if ( deviceId !== undefined)
  {
    const val = BigInt(deviceId);
    const hex = val.toString(16).padStart(14, '0');
    mac = hex.substring(0, 12);
    const crc = hex.substring(12);
    console.log("deviceid:", deviceId, val, hex, mac, crc);
  }
  return mac;
}

function convertToHexString(integer) {
  var str = Number(integer).toString(16);
  return str.length === 1 ? "0" + str : str;
};


export const encode_mac_address = (topicString, controllerType ) => 
{
    var encoded;
    var sting = String(topicString)
    var byte_array = sting.split('').map(function(x){return x.charCodeAt(0)})
    var crc_poly = CRC8_POLY.AQUATEK;
    
    switch ( controllerType)
    {
        case controller_t.AQUATEK:    crc_poly = CRC8_POLY.AQUATEK;break;
        case controller_t.THERALUX:   crc_poly = CRC8_POLY.THERALUX;break;
        case controller_t.OASIS:      crc_poly = CRC8_POLY.OASIS;break;
        case controller_t.WATERCO:    crc_poly = CRC8_POLY.WATERCO;break;
        case controller_t.AQUALIGHT:  crc_poly = CRC8_POLY.WATERCO;break;
        case controller_t.HENDEN:     crc_poly = CRC8_POLY.HENDEN;break;
        case controller_t.RECLAIM:     crc_poly = CRC8_POLY.RECLAIM;break;
        default:                      crc_poly = CRC8_POLY.CRC8_CCITT;break;
    }
    
    var crc8 = new CRC8(crc_poly,0)
    var checksum = crc8.checksum(byte_array);
    let crc_hex_string = convertToHexString(checksum);
    let combined_string = "0x"+topicString+crc_hex_string;
    var int2_value = BigInt(combined_string) //, radix: 16) ?? 0
    encoded = String(int2_value).padStart( 17, '0');
    //  console.log ( "encode mac address:", controllerType, topicString, checksum, combined_string, encoded, "int", int2_value);
    return encoded;
    
}

export const getType_from_UID = ( UID  ) => 
{
    var controllerType = controller_t.NONE;
    var macAddrString = getMacAddress(UID);// UID.substring(0,15)
    
    // controller_t.array.forEach(item => {
    controller_crc_value.map( item  => {
      // console.log( " controller type", item.value, item.crc_value );
      var encoded = encode_mac_address(macAddrString,item.value);
      if ( encoded === UID)
      {
        controllerType = item.value;
        console.log( "Found controller:", controllerType);
       
      }
      return controllerType;
      // var int_value = BigInt(crc_hex_string)  
      // encoded_crc = String(int_value)
     
})
    // console.log ( "encode mac address:", topicString, checksum, combined_string, encoded, "int", int2_value);
    
    return controllerType;
    
}
export const getDeviceFaults = (device) => {
  let str = device.ctrlStatDateTooltip;
  const idx = device.faults[0].indexOf(" ");
  if(idx > 0) {
    str = str + device.faults[0].substring(idx);
  } else {
    str = str + " " + device.faults[0];
  }
  if(device.faults.length > 1) {
    str = str + " ..";
  }
  return str;
}


export const isDeviceInFault = (device) => {
  var result = false;
  if ( device !== undefined)
  {
    if ( device.connStatDesc === "ONLINE") 
    {
      if (device.ctrlStatDesc === "FAULT")
      { result = true}
    }
  }
   return result;
};

export const isDeviceGood = (device) => {
  var result = false;
  if ( device !== undefined)
  {
  if ( device.connStatDesc === "ONLINE") 
   {
    if (device.ctrlStatDesc === "OK")
    { result = true}
   }
  }
   return result;

  };

  export const isDeviceOffline = (device) => {
    var result = false;
    if ( device !== undefined)
  {
    if ( device.connStatDesc === "OFFLINE") 
   {
    result = true
   }
  }
   return result;
  };




export const ShowDeviceQR = ({ open, device, handleClose }) => {
  const generateQR = (e) => {

   var mac_address = encode_mac_address( getMacAddress(e.uniqueDeviceId) , convertDeviceToIconNumber(e.modelType))
  
   return mac_address;
  };

 

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
         
           <div className="space-y-2" >
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-1"
            > 
              <Icon icon={IconQRCode} color={colours.default} />
              <Title color={colours.default}>{device.userName}  </Title>
            </Flex>
            <Subtitle color={colours.default}>QR Code</Subtitle>
            </div>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-1"
          >
          <QRCodeSVG value={generateQR(device)}
                     size={256}
                     fgColor={"#0056d6"}
                      level={"L"}
                      includeMargin={false}
                      imageSettings={{
                        src:  getControllerBrandIcon_FROM_STRING( device.modelType ) ,
                        x: undefined,
                        y: undefined,
                        height: 48,
                        width: 48,
                        excavate: true,
                      }}
                      />

        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2"
        >
          <Button color={colours.default}
            size="sm"
            onClick={() => handleClose({ device })}
            disabled={false}
          >Ok</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export const EditDevice = ({ open, device, handleClose }) => {
  const [name, setName] = useState(device.userName);
  const [valid, setValid] = useState(true);
  const [modeltype, setType] = useState(device.modelType);

  const validate = (e) => {
    setName(e.currentTarget.value);
    setValid(checkNameValid(e.currentTarget.value));
  };

  const validateType = (e) => {
    setType(e.currentTarget.value);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconEdit} color={colours.default} />
            <Subtitle color={colours.default}>Edit controller</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-3">
          <TextField
            label="ID"
            defaultValue={device.uniqueDeviceId}
            isDisabled={true}
            size="small"
          />
          <TextField
            label="Name"
            defaultValue={device.userName}
            placeholder="Enter a valid name"
            maxLength={20}
            size="small"
            required
            onChange={validate}
          />
          <Text size="small" >Controller Type</Text>
          <Card  decoration="bottom"
                decorationColor={colours.cardDecoration}
                className="mt-4">
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={3}
            className="gap-4"
          >
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Aquatek").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Aquatek" checked={modeltype === "Aquatek"} onChange={validateType}/><Text size="small">Aquatek</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Theralux").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Theralux" checked={modeltype === "Theralux"} onChange={validateType}/><Text size="small">Theralux</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-2"
            >
              <img src={getControllerIconText_FROM_STRING("Oasis").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Oasis" checked={modeltype === "Oasis"} onChange={validateType}/>
              <Text>Oasis</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Aqualight").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Aqualight" checked={modeltype === "Aqualight"} onChange={validateType} /><Text size="small">Aqualight</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("WaterCo").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="WaterCo" checked={modeltype === "WaterCo"} onChange={validateType}/><Text size="small">WaterCo</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Hendon").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Hendon" checked={modeltype === "Hendon"} onChange={validateType}/><Text size="small">Hendon</Text>
            </Flex>
          </Grid>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
          <Button
            
            color={colours.default}
            size="sm"
            onClick={() => handleClose({ device, name, modeltype })}
            disabled={!valid}
          >Confirm</Button>
          <Button
            color={colours.default}
            size="sm"
            variant="secondary"
            onClick={() => handleClose(null)}
          >Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export const CsvLink = props => {
  let filename = props.device + '_' + props.itemType;
  try {
    const len = props.data.length;
    if(len > 0) {
      const dt0 = parse(props.data[0][dateField], props.format, new Date());
      filename = filename + '_' + lightFormat(dt0, datetimeFormats.filename);

      const dt1 = parse(props.data[len - 1][dateField], props.format, new Date());
      if(!isSameDay(dt0, dt1)) {
        filename = filename + "_to_" + lightFormat(dt1, datetimeFormats.filename);
      }
    }
  } catch(error) {
    console.log(error);
  }
  filename = filename + ".csv";
  return (
    <CSVLink
      data={props.data}
      headers={props.headers}
      filename={filename}
      style={csvLinkStyle}
      target="_blank"
    >
      {props.text}
    </CSVLink>
  );
}

export const ProgressBar = props => {
  return (
    <Progress.Line
      percent={props.percentage}
      showInfo={false}
      strokeColor={colours.default} //"#ec4899"
    />
  );
}

export const SelectDateRange = props => {
  return (
    <DateRangePicker
      value={props.range}
      onChange={(value) => props.onChange(value)}
      onOk={(value) => props.onChange(value)}
      character=" &#8211; "
      cleanable={false}
      shouldDisableDate={combine(allowedMaxDays(props.maxDays), afterToday())}
      // disabledDate={}
      editable={false}
      format={datetimeFormats.picker}
      isoWeek
      showOneCalendar
    />
  );
}

export const SelectManyDevices = props => {
  return (
    <MultiSelect
      defaultValue={props.selected}
      onValueChange={(value) => props.onSelectedChanged(value)}
      placeholder="Select controller(s)"
      className="max-w-none" >
      {props.devices.map((device) => (
        <MultiSelectItem
          key={device.uniqueDeviceId}
          value={device.uniqueDeviceId}
          text={device.userName} />
      ))}
    </MultiSelect>
  );
}

export const SelectOneDevice = props => {
  return (
    <Select
      defaultValue={props.selected}
      onValueChange={(value) => props.onSelectedChanged(value)}
      placeholder="Select controller"
      className="max-w-none" >
      {props.devices.map((device) => (
        <SelectItem
          key={device.uniqueDeviceId}
          value={device.uniqueDeviceId}
          text={device.userName} />
      ))}
    </Select>
  );
}
 