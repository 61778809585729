import React from "react";
import { CSVLink } from "react-csv";
import { isSameDay, lightFormat, parse } from "date-fns";

const DateField = "Date/Time";
const FilenameFormat = "yyMMdd";

const Style = {
    backgroundColor: "#0ea5e9",
    borderRadius: 6,
    color: "#fff",
    fontSize: 12,
    fontWeight: 500,
    padding: "6px"
};

const CsvLink = props => {
    let filename = props.device + '_' + props.itemType;
    try {
        const len = props.data.length;
        if(len > 0) {
            const dt0 = parse(props.data[0][DateField], props.format, new Date());
            filename = filename + '_' + lightFormat(dt0, FilenameFormat);

            const dt1 = parse(props.data[len - 1][DateField], props.format, new Date());
            if(!isSameDay(dt0, dt1)) {
                filename = filename + "_to_" + lightFormat(dt1, FilenameFormat);
            }
        }
    } catch(error) {
        console.log(error);
    }
    filename = filename + ".csv";
    return (
        <CSVLink data={props.data} headers={props.headers} filename={filename} style={Style} target="_blank">
            {props.text}
        </CSVLink>
    );
}

export default CsvLink;