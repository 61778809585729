import React, { useState,
   useEffect 
  } from "react";

import { createDevice, updateDevice } from "../graphql/mutations";

import {
  AreaChart,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Grid,
  Flex,
  Icon,
  Metric,
  Tab,
  TabGroup,

  TabList,
  TabPanel,
  TabPanels,
  Text as TextTremor,
  Title,
  // Toggle,
  // ToggleItem,
} from "@tremor/react";

import {
  MdOutlineArrowBack as IconBack,
  MdHotTub as IconSpa,
  MdRoofing as IconSolar,
  MdOutlineEdit as IconEdit,
  MdOutlineInsertChart as IconTrends,
  MdOutlineErrorOutline as IconError,
  // MdOutlineSpaceDashboard as IconDashboard,
  MdOutlineHome as IconHome,
  // MdRadioButtonUnchecked as IconCheck,
  MdQrCode2 as IconQRCode,
  MdOutlineSensors as IconConnected,
  MdOutlineSensorsOff as IconDisconnected,
  MdRefresh as IconRefresh,
  MdOutlineSync as IconSync,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";

import {
  TbEngine as IconPump,
  TbPlug as IconSocket,
} from "react-icons/tb";

import { TrendsView } from "./TrendsView";
import { SPAView } from "./SPAView";
import { Notes } from "./NoteComponents/Notes";
import { AppliancesView } from "./AppliancesView";
import { SystemInfo } from "./SystemInfo";
import { SystemSettingsView } from "./SystemSettingsView";
import { FilterView } from "./FilterView";
import {
  // CsvLink,
  EditDevice,
  ShowDeviceQR,
  getDeviceFaults,
  isDeviceInFault,
  isDeviceOffline,
  // ProgressBar,
  // SelectDateRange,
  // SelectOneDevice,
  get_recordID_from_UID
} from "./Common";
import {
  // getControllerIconText ,
  getControllerIconText_FROM_STRING,
  // getControllerIconText
} from "./DisplayItems";
import { paramsColors } from "../params";

import {
  colours,
  faultView,
  tabItems,
} from "../constants";

import {
  GiBubbles as IconBubbles,
  GiChemicalDrop as IconChemical,

  // GiWinterHat as IconWinter,
  GiValve as IconValve,
  GiThermometerCold as IconFrozen,
  GiDrippingTube as IconAcidTube,
  GiHeatHaze as IconHeat,
  GiBoilingBubbles as IconBubbles2,

} from "react-icons/gi";

import 
{
  RxMixerVertical as IconControl,
}from "react-icons/rx";

import { HistoryView } from "./HistoryView";
import { HeatingView } from "./HeatingView";
// import { SystemInfo } from "./SystemInfo";
import { SolarView } from "./SolarView";
import { ControlView } from "./ControlView";
import { ChemicalView } from "./ChemicalView";
import { PumpView } from "./PumpView";

import {

  getHours,
  getMinutes,
  // format as DTformat
} from "date-fns";
import {
  getMacAddress
} from "./Common";
import { Hub, PubSub, API } from "aws-amplify";
import {
  readAllMessage,
  registersInit

} from "../registers";

import {
  format, 
  formatTemperature, 


} from "./RegistersHandler";


export const DeviceView = props => {

  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabItems.home);
  const [macAddress, setMacAddress] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [registerData, setRegisterData] = useState(registersInit);
  const [connectionState, setConnectionState] = useState({
    text: "Disconnected",
    ready: false
  });

  const handleEditDeviceShow = (device) => {
    setDeviceToEdit(device);
    setShowEditDevice(true);
  }



  const handleQRCodeShow = (device) => {
    setDeviceToEdit(device);
    setShowQRCode(true);
  }

  const handleEditDeviceUpdate = (result) => {
    setShowEditDevice(false);
    if (result !== null) {
      const { device, name , modeltype } = result;
      console.log("Result",device, name, modeltype);
      if ( (props.device.userName !== name) || ( props.device.modelType !== modeltype )){
        updateDeviceName(props.device.uniqueDeviceId, name, modeltype);
        console.log("edit: name changed", name, modeltype);
      } else {
        console.log("edit: name unchanged", name);
      }
      
    }
  }

async function updateDeviceName(deviceId, name , type) {
    let record_ID = get_recordID_from_UID( deviceId);
    const result = await API.graphql({
      
      query: updateDevice,
      variables: {
        input: {
          recordType: "info",
          recordID: record_ID, 
          
          userName: name,
          modelType: type
        }
      }
    });
    console.log("edit:", result);
    props.onRefresh();
  }


  const handleQRDeviceUpdate = () => {
    setShowQRCode(false);
  }

  const [refresh, setRefresh] = useState(false);
  const handleViewDashboard = (device) => {
    console.log ( "View Dashboard", device)
    props.setSelectedDevice(device);
    setShowDashboard(true);
    setSelectedTab(tabItems.dashboard);
  }


  const handleBackFromDashboard = () => {
    // setShowDashboard(false);
    // setSelectedTab(tabItems.home)
  }

  const handleViewTrends = (device) => {
    props.setSelectedDevice(device);
    console.log ( "HT", device)
    setSelectedTab(tabItems.trends);
    if (props.logDevice !== device) {
      props.OnfetchLogData(device, props.selectedDateRange);
    }
  }

  const handleViewFaults = (device) => {
    props.setSelectedDevice(device);
    setSelectedTab(tabItems.faults);
    props.onSetSelectedFaultView(faultView.history);
    if (props.logDevice !== device) {
      props.OnfetchLogData(device, props.selectedDateRange);
    }
  }

  const updateDeviceViewSelectedTab = (value) => {
    setSelectedTab(value);
    
    switch (value) {
      case tabItems.home:
        console.log("select tab: HOME");
        props.onFetchDevices();
        break;
      case tabItems.trends:
        console.log("select tab: trends");
        if (props.selectedDevice !== props.logDevice) {
          props.onRefreshData();
        }
        break;
      case tabItems.appliances:
        console.log("select tab: appliances");
        if (props.selectedDevice !== props.logDevice) {
          props.onRefreshData();
        }
        break;
      case tabItems.faults:
        console.log("select tab: faults");
        if (props.selectedDevice !== props.logDevice) {
          props.onRefreshData();
        }
        break;
      case tabItems.dashboard:
        console.log("select tab: dashboard");
        props.onRefreshData();
        handleViewDashboard(props.selectedDevice);
        break;

      default:
        break;
    }
    console.log("selcec tab:", selectedTab, value);
  }

  function ChooseGraph() {
    const [setlectedValue, setSelectedValue] = useState('relative');
    return (

      props.isLoadingOrNoDevice ? (<div></div>) : (
        <div className="space-y-2 " >
          <Card>
            <Flex>
              <div>
                <Title> {setlectedValue === 'ph' ? "pH Readings" : "ORP Readings"} </Title>
                
              </div>
              <TabGroup defaultIndex="orp"
                onIndexChange={(value) => setSelectedValue(value)} color="zinc">
              <TabList variant="solid">
                <Tab icon={IconBubbles}>ORP Readings</Tab>
                <Tab icon={IconChemical}>pH readings</Tab>
              </TabList>
              </TabGroup>
            </Flex>

            {
              (props.phSelection === false) ? (
                <Flex alignItems="center" className="space-x-2">
                  <TextTremor color={colours.warning}>No pH to Display</TextTremor>
                </Flex>
              ) : (
                (props.logData.length === 0) ? (
                  <TextTremor color={colours.warning}>No data found</TextTremor>
                ) : (
                
                  
                    <AreaChart
                      data={props.logData}
                      index="dateDisplay"
                      categories={setlectedValue === 'ph' ? ["pH", "pH Curr"] : ["ORP (mV)", "ORP Curr (mV)"]}
                      colors={paramsColors}
                      showAnimation={true}
                      showLegend={true}
                      startEndOnly={true}
                      className="mt-6 h-60"
                     
                    />
                )
              )
            }
          </Card>
        </div>
      )
    );
  }


  // This is for counter state variable
  // useEffect(() => {
  //   console.log("selected tab changed:",selectedTab);
  //   updateDeviceViewSelectedTab();
  // }, [selectedTab]);

  let time = new Date().getTime();
  const setActivityTime = (e) => {
    time = new Date().getTime();
  }
  document.body.addEventListener("mousemove", setActivityTime);
  document.body.addEventListener("keypress", setActivityTime);

  const doRefresh = () => {
    if (new Date().getTime() - time >= 10*60000) {
      (props.onRefresh())
   //   window.location.reload(true);
    } else {
      setTimeout(refresh, 10000);
    }
  }

  setTimeout(doRefresh, 10000);

  const handleRegisterUpdate = (register, value) => {
    const registerValue = parseInt(value, 10);
    if (registerValue !== register.value) {
      console.log(register, "=>", registerValue);
      writeRegister(register.address, registerValue);
    }
  }

  async function writeReadAll() {
    const topic = "dontek" + macAddress + "/cmd/psw"
    await PubSub.publish(topic, readAllMessage);
    console.log("readAll =>", topic);
  }

  async function writeRegister(address, value) {
    const topic = "dontek" + macAddress + "/cmd/psw"
    const message = { messageId: "write", modbusReg: address, modbusVal: [value] };
    await PubSub.publish(topic, message);
    console.log(topic, "=>", message);
  }

  async function writePasswordRegister(address, value) {
    const topic = "dontek" + macAddress + "/cmd/psw"
    let password = "_d0nt3k2K19"
    const message = { messageId: "write", modbusReg: address, modbusVal: [value], valueString: password };
    await PubSub.publish(topic, message);
    console.log(topic, "=>", message);
  }

  const handleNameRegisterUpdate = (register, value) =>
  {
    console.log( "handle name =>",  value);

    var name_idx = 0;
    var address_to_send = register.address;

    for (let itr = 0; itr < 8; itr++) 
    {
      let registerValue = ( value.charCodeAt(name_idx) << 8 ) | ( value.charCodeAt(name_idx+1));

      props.onWriteRegister(address_to_send, registerValue);
      console.log(address_to_send, "=>", registerValue, name_idx);
      name_idx = name_idx + 2;
      address_to_send ++;
    }
    writeReadAll();
  }

  const handlePrivateRegisterUpdate = (register, value) => {
    const registerValue = parseInt(value, 10);
    if (registerValue !== register.value) {
      console.log(register, "=>", registerValue);
      writePasswordRegister(register.address, registerValue);
    }
  }


  const handleTimesUpdate = (register, value) => {
    let hours = getHours(value);
    let minutes = getMinutes(value);
    let timeValue = ((hours << 8) | minutes);
    console.log("Date_range_times", value, timeValue, hours, minutes);

    if (timeValue !== register.value) {
      console.log("times registerupdate", register, "=>", timeValue);
      props.onWriteRegister(register.address, timeValue);
    }
  }

  const handleReceivedData = (topic, data) => {
    const { value } = data;


    const topicFromData =  data.value[Object.getOwnPropertySymbols(data.value)[0]] //data.value.topic;
    const topfromData2 = data.value.topicSymbol
    console.log("data Rec", data, value, topicFromData, topfromData2 );
    
  
    if ( (topicFromData === topic) || (topfromData2 === topic) ){
      const { messageId, modbusReg, modbusVal } = value;
      console.log("received message", messageId, modbusReg, modbusVal);

      const rawData = {};
      if ((messageId === "read") && (modbusReg === 1) && (modbusVal.length % 2 === 0)) {
        for (let i = 0; i < modbusVal.length; i += 2) {
          const address = modbusVal[i];
          const value = modbusVal[i + 1];
          rawData[address] = value;
        }
      } else {
        for (let i = 0; i < modbusVal.length; i++) {
          const address = modbusReg + i;
          const value = modbusVal[i];
          rawData[address] = value;
        }
      }

      for (let i = 0; i < registerData.length; i++) {
        const address = registerData[i].address;
        if (address in rawData) {
          const fmtVal = format(registerData[i], rawData);
          setRegisterData(prevState => {
            const newState = prevState.map(obj => {
              if (obj.address === address) {
                return { ...obj, value: rawData[address], display: fmtVal };
              }
              return obj;
            });
            return newState;
          });
        }
      }
    } else {
      console.log("topic mismatch, expected", topic, "received", topicFromData);
    }
  }

  const getRegisterData = (address) => (
    registerData.find(item => item.address === address)
  )

  const handleBack = () => {
    console.log("HandleBack");
    if (connectionState.ready) {
      console.log("HandleBack CSR");
      if (subscription != null) {
        console.log("HandleBack sub not null");
        subscription.unsubscribe();
      }
      setSubscription(null);
    }
    props.onBackPressed();
  }
  function displaybadge() {
    return (
      // <Flex justifyContent="end" className="gap-2">
      <div>
        {
          isDeviceInFault(props.device) &&
          <Badge 
            icon={props.device.ctrlStatIcon} 
            color={props.device.ctrlStatColor}
            tooltip={getDeviceFaults(props.device)}>
            {props.device.ctrlStatDesc}</Badge>
        }
        {
          !isDeviceInFault(props.device) && isDeviceOffline(props.device) &&
          <Badge 
            icon={props.device.connStatIcon} 
            color={props.device.connStatColor}
            tooltip={props.device.connStatDate}>
              {props.device.connStatDesc}</Badge>
        }
        {
          !isDeviceInFault(props.device) && !isDeviceOffline(props.device) &&
          <Badge
            icon={props.device.connStatIcon} 
            color={props.device.connStatColor}
            tooltip={props.device.connStatDate > props.device.ctrlStatDate ? props.device.connStatDate : props.device.ctrlStatDate}>
            GOOD
          </Badge>
        }
      </div>
      // </Flex>
    );
  }

  useEffect(() => {

    if ( props.selectedDevice !== undefined)
    {
      const macAddr = getMacAddress(props.device.uniqueDeviceId);
      setMacAddress(macAddr);
      console.log("Got Mac Address", macAddr, " from ", props.selectedDevice, " device ", props.device)
    }
    else
    {
      console.log("NO MAc Address")
      setMacAddress("246f28db53ac");
    }
  }, [props.selectedDevice]);

  
  useEffect(() => {
    if (macAddress.length !== 0) {
      const topic = "dontek" + macAddress + "/status/psw";
      const sub = PubSub.subscribe(topic).subscribe({
        next: data => handleReceivedData(topic, data),
        error: error => console.log("subscribe error", error),
        close: () => console.log("subscribe close")
      });

      setSubscription(sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [macAddress]);

  useEffect(() => {
    Hub.listen('pubsub', (data) => {
      const { payload } = data;
      console.log("pubSub:", payload);
      if (payload.event === "ConnectionStateChange") {
        const text = payload.data.connectionState;
        const ready = (text === "Connected");
        setConnectionState({ text, ready });
      }
    })
  }, []);
  

  useEffect(() => {
    async function doReadAll() {
      const topic = "dontek" + macAddress + "/cmd/psw"
      await PubSub.publish(topic, readAllMessage);
      console.log("readAll =>", topic);
    }
    if (connectionState.ready || refresh) {
      doReadAll();
    }
  }, [connectionState.ready, refresh, macAddress]);


  return (
    <div className="mt-3">
      {
      (showDashboard === false) &&
        <Grid numItems={1} className="gap-2">
        <Col numColSpan={1}>
        
        <Grid numItems={3} className="gap-2">
        <Col>
          <Flex
          justifyContent="start"
          alignItems="center"
          className="space-x-2">
            <Button icon={IconBack}
              color={colours.default}
              size="xs"
              variant="secondary"
              onClick={handleBack}
            >Back</Button>
            
            <Icon  
              icon={connectionState.ready ? IconConnected : IconDisconnected}
              color={connectionState.ready ? colours.good : colours.error}
              size="lg"
            />
            <Metric
              color={connectionState.ready ? colours.good : colours.error}
              className="truncate"
            >
              {  (props.selectedDevice !== undefined)? ( connectionState.ready ? (connectionState.text ) : connectionState.text):
              (connectionState.text)
              }
            </Metric>
          </Flex>
          </Col>
          <Col>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2">
            <img src={getControllerIconText_FROM_STRING(props.device.modelType).icon} alt="Controller Icon" width="40" height="40" />
              <Metric>{props.device.userName}</Metric>
            { displaybadge()}
          </Flex>
          <div className="text-center">
            <TextTremor>{"UID:" + props.device.uniqueDeviceId}</TextTremor>
          </div>
          </Col>
          <Col>
            <Flex justifyContent="end"
              className="space-x-4">
          
              {/* </Flex> */}
              {/* <TextTremor
                color={connectionState.ready ? colours.good : colours.error}
                className="truncate"
              >
                {  (props.selectedDevice !== undefined)? ( connectionState.ready ? (connectionState.text + " to '" + props.device.userName + "' (MAC: " + macAddress + ")") : connectionState.text):
                (connectionState.text)
                }
              </TextTremor> */}
              <Button
                icon={IconEdit}
                iconPosition="right"
                color={colours.default}
                size="sm"
                variant="light"
                onClick={() => handleEditDeviceShow(props.device)}
              />
              <Button
                  icon={IconQRCode}
                  iconPosition="right"
                  color={colours.default}
                  size="lg"
                  variant="light"
                  onClick={() => handleQRCodeShow(props.device)}
                />
              <Button icon={IconSync}
                color={colours.default}
                size="xs"
                disabled={!connectionState.ready}
                onClick={() => setRefresh(true)}
              >Sync</Button>
            </Flex>
          </Col>
          </Grid>
            <TabGroup  color="sky"
              defaultIndex={selectedTab}
              onIndexChange={setSelectedTab} className="mt-3">
              <TabList >
                <Tab id="id_tabHome" icon={IconHome}>Home</Tab> 
                <Tab id="id_tabTrends" icon={IconTrends}>Trends</Tab> 
                <Tab id="id_tabAppliances" icon={IconTrends}>Appliances</Tab> 
                <Tab id="id_tabFaults" icon={IconError}>Faults</Tab> 
                <Tab icon={IconControl}>Control</Tab>
                <Tab icon={IconHeat}>Heating</Tab>
                <Tab icon={IconSolar}>Solar</Tab> 
                <Tab icon={IconAcidTube}>Chemical</Tab>
                <Tab icon={IconSpa}>SPA</Tab> 
                <Tab icon={IconPump} >Pump</Tab> 
                <Tab icon={IconSettings} >System</Tab> 
                <Tab icon={IconFrozen}>Filter</Tab> 
              </TabList>
              <TabPanels> 
                <TabPanel>
                  {/* <Card key={props.device.uniqueDeviceId} > */}
                    <SystemInfo 
                      getRegisterData={getRegisterData}
                      handleRegisterUpdate={handleRegisterUpdate}
                      onTimesUpdate={handleTimesUpdate}
                      formatTemperature={formatTemperature}
                      onPrivateRegisterUpdate = {handlePrivateRegisterUpdate}
                      /> 
                    <Card>
                    <Notes />
                    </Card>
                  {/* </Card> */}
                </TabPanel>
                <TabPanel>
                  <Card  >
                    <TrendsView
                      devices={props.devices}
                      logData={props.logData}
                      params={props.params}
                      pumpData={props.pumpData}
                      pool={props.pool}
                      water={props.water}
                      roof={props.roof}
                      poolLabels={props.poolLabels}
                      applianceDataArray={props.applianceDataArray}
                      ORPPHLabels={props.ORPPHLabels}
                      ORP={props.ORP}
                      PH={props.PH}
                      ORPRT={props.ORPRT}
                      PHRT={props.PHRT}
                      selectedDevice={props.selectedDevice}
                      selectedDateRange={props.selectedDateRange}
                      selectedParams={props.selectedParams}
                      phSelection={props.phSelection}
                      ORPSelection={props.ORPSelection}
                      loading={props.loading}
                      progress={props.progress}
                      onSelectedDeviceChanged={props.onSelectedDeviceChanged}
                      onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                      onSelectedParamsChanged={props.onSelectedParamsChanged}
                      onRefresh={props.onRefreshData}
                      isLoadingOrNoDevice={props.isLoadingOrNoDevice}
                      // onViewFaults={handleViewFaults}
                      // onViewDashboard={handleViewDashboard}
                    />
                  </Card>
                </TabPanel>

                <TabPanel>  
                  <Card key={props.device.uniqueDeviceId} >
                    <AppliancesView
                      devices={props.devices}
                      logData={props.logData}
                      params={props.params}
                      pumpData={props.pumpData}
                      applianceDataArray={props.applianceDataArray}
                      selectedDevice={props.selectedDevice}
                      selectedDateRange={props.selectedDateRange}
                      selectedParams={props.selectedParams}
                      phSelection={props.phSelection}
                      ORPSelection={props.ORPSelection}
                      loading={props.loading}
                      progress={props.progress}
                      AppTrackerData={props.applianceTrackerDataArray}
                      onSelectedDeviceChanged={props.onSelectedDeviceChanged}
                      onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                      onSelectedParamsChanged={props.onSelectedParamsChanged}
                      onRefresh={props.onRefresh}
                      isLoadingOrNoDevice={props.isLoadingOrNoDevice}
                      isApplianceConfigured={props.isApplianceConfigured}
                      getAppliances_with_id={props.getAppliances_with_id}
                      
                    />
                  </Card>
                </TabPanel>

                <TabPanel>
                  <Card key={props.device.uniqueDeviceId} >
                  <HistoryView
                    selectedDevice={props.devices.find((e) => e.uniqueDeviceId === props.selectedDevice)}
                    selectedDevices={props.selectedDevices}
                    device={props.device}
                    devices={props.devices}
                    historyFaults={props.historyFaults}
                    logFaults={props.logFaults}
                    progress={props.progress}
                    loading={props.loading}
                    onBackPressed={handleBackFromDashboard}
                    latestFaults={props.latestFaults}
                    onSelectedDeviceChanged={props.onSelectedDeviceChanged}
                    onSetSelectedFaultView={props.onSetSelectedFaultView}
                    onsetSelectedLatestFaults={props.onsetSelectedLatestFaults}
                    onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                    selectedDateRange={props.selectedDateRange}
                    selectedFaultView={props.selectedFaultView}
                    selectedLatestFaults ={props.selectedLatestFaults}
                    selectedHistoryFaults= {props.selectedHistoryFaults}
                    onSetSelectedHistoryFaults={props.onSetSelectedHistoryFaults}
                    onFetchDevices={props.onFetchDevices}
                    onRefreshData={props.onRefreshData}
                  />
                  </Card>
                </TabPanel>
                <TabPanel>
                  <ControlView 
                    getRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}
                    formatTemperature={formatTemperature}
                  /> 
                </TabPanel>
                <TabPanel>
                  <HeatingView 
                    getRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}
                    formatTemperature={formatTemperature}
                  /> 
                </TabPanel>
                <TabPanel>
                  <SolarView 
                    getRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}
                    formatTemperature={formatTemperature}
                  /> 
                </TabPanel>

                <TabPanel>
                  <ChemicalView
                    onGetRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    />
                </TabPanel>
                <TabPanel>
                  <SPAView 
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  />
              
                </TabPanel>
                <TabPanel>
                  <PumpView 
                    getRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}
                    formatTemperature={formatTemperature}
                    /> 
                </TabPanel>
                <TabPanel>
                  <SystemSettingsView 
                    onGetRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}

                    formatTemperature={formatTemperature}
                    /> 
                </TabPanel>
                <TabPanel>
                  <FilterView 
                    onGetRegisterData={getRegisterData}
                    handleRegisterUpdate={handleRegisterUpdate}
                    onTimesUpdate={handleTimesUpdate}

                    formatTemperature={formatTemperature}
                    /> 
                </TabPanel>

                {/* <TabPanel>
                  <DashboardView
                    connectionState={connectionState}
                    selectedDevice={props.devices.find((e) => e.uniqueDeviceId === props.selectedDevice)}
                    onBackPressed={handleBackFromDashboard}
                    handleRegisterUpdate={handleRegisterUpdate}
                    macAddress={macAddress}
                    subscription={subscription}
                    
                    onGetRegisterData={getRegisterData}
                    onNameRegisterUpdate={handleNameRegisterUpdate}
                    onPrivateRegisterUpdate={handlePrivateRegisterUpdate}
                    onSetMacAddress={setMacAddress}
                    onSetSubscription={setSubscription}
                    onSetRefresh={setRefresh}
                    onTimesUpdate= {handleTimesUpdate}
                    onWriteRegister={writeRegister}


                  />
                </TabPanel> */}
              </TabPanels>
            </TabGroup>
        </Col>
         <ShowDeviceQR
          open={showQRCode}
          device={deviceToEdit}
          handleClose={handleQRDeviceUpdate}
        />
        <EditDevice
          open={showEditDevice}
          device={deviceToEdit}
          handleClose={handleEditDeviceUpdate}
        />
      </Grid>
    }
    </div>
    
  )
};